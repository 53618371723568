import { RouteConfig } from 'vue-router'
import { WebsitePaths } from './path.constants'

const routes: RouteConfig[] = [
  { // Route as above but doesn't require authentication
    path: '/',
    meta: {
      requiresAuth: false
    },
    component: () => import('src/areas/notLoggedIn/layouts/Default.vue'),
    children: [
      {
        path: WebsitePaths.home,
        meta: { metaInstructions: { module: 'websiteAuthors' } },
        component: () => import('src/areas/notLoggedIn/pages/Index.vue')
      },
      {
        path: WebsitePaths.readers,
        meta: { metaInstructions: { module: 'websiteReaders' } },
        component: () => import('src/areas/notLoggedIn/pages/Readers.vue')
      },
      {
        path: WebsitePaths.about,
        meta: { metaInstructions: { module: 'websiteAbout' } },
        component: () => import('src/areas/notLoggedIn/pages/About.vue')
      },
      {
        path: WebsitePaths.pricing,
        meta: { metaInstructions: { module: 'websitePricing' } },
        component: () => import('src/areas/notLoggedIn/pages/Plans.vue')
      },
      {
        path: WebsitePaths.privacyPolicy,
        meta: { metaInstructions: { module: 'websitePrivacyPolicy' } },
        component: () => import('src/areas/notLoggedIn/pages/PrivacyPolicy.vue')
      },
      {
        path: WebsitePaths.terms,
        meta: { metaInstructions: { module: 'websiteTerms' } },
        component: () => import('src/areas/notLoggedIn/pages/Terms.vue')
      },
      {
        path: WebsitePaths.affiliateDisclosure,
        meta: { metaInstructions: { module: 'websiteAffiliateDisclosure' } },
        component: () => import('src/areas/notLoggedIn/pages/AffiliateDisclosure.vue')
      },
      {
        path: WebsitePaths.cookiePolicy,
        meta: { metaInstructions: { module: 'websiteCookiePolicy' } },
        component: () => import('src/areas/notLoggedIn/pages/CookiePolicy.vue')
      },
      {
        path: WebsitePaths.privacyPolicyAllProducts,
        meta: { metaInstructions: { module: 'websitePrivacyPolicyAllProducts' } },
        component: () => import('src/areas/notLoggedIn/pages/PrivacyPolicyAllProducts.vue')
      },
      {
        path: WebsitePaths.affiliateProgram,
        meta: { metaInstructions: { module: 'websiteAffiliateProgram' } },
        component: () => import('src/areas/notLoggedIn/pages/AffiliateProgram.vue')
      },
      {
        path: WebsitePaths.affiliateTermsAndConditions,
        meta: { metaInstructions: { module: 'websiteAffiliateTermsAndConditions' } },
        component: () => import('src/areas/notLoggedIn/pages/AffiliateTermsAndConditions.vue')
      }
    ]
  }
]

routes.push({
  path: '*',
  component: () => import('src/areas/notLoggedIn/pages/httpError/404.vue')
})

export default routes
