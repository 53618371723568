import { QSsrContext } from '@quasar/app'
import { Store } from 'vuex'
import { RootState } from '../../store/types'
import { container, BookService, BookServiceInterface } from 'booksprout-app'
import { BookDto } from 'booksprout'

export default (ssrContext: QSsrContext, store: Store<RootState>) => {
  container.addTransient<BookServiceInterface<BookDto>>(BookService, 'BookService')
}

