import { CreateElement } from 'vue'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class BsSpinner extends Vue {
  render (h: CreateElement) {
    return h('div', {
      staticClass: 'bs-spinner'
    }, [
      h('svg', {
        attrs: {
          width: '30',
          height: '22',
          viewBox: '0 0 30 22',
          fill: 'none',
          xmlns: 'http://www.w3.org/2000/svg'
        }
      }, [
        h('path', {
          attrs: {
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            d: 'M28.5034 0.0329433L28.743 0.0487847C28.9332 0.0626821 29.1185 0.11897 29.2867 0.213943C29.4548 0.308916 29.602 0.440419 29.7185 0.599793C29.8351 0.759167 29.9183 0.942797 29.9628 1.13859C30.0072 1.33439 30.0119 1.53791 29.9765 1.73575C29.1964 6.09704 27.5199 9.40805 24.947 11.6688C22.5074 13.8125 19.6557 14.6661 16.3915 14.2305V20.812C16.3915 21.1271 16.2729 21.4292 16.0619 21.652C15.8509 21.8748 15.5647 22 15.2663 22H15.2592C14.9608 22 14.6746 21.8748 14.4636 21.652C14.2526 21.4292 14.134 21.1271 14.134 20.812V14.1499C10.6493 14.7536 7.62259 13.9264 5.05302 11.6683C2.48012 9.40805 0.803604 6.09704 0.0234815 1.73575C-0.0107286 1.54461 -0.00752848 1.3481 0.0328862 1.15831C0.073301 0.968525 0.150074 0.789479 0.258481 0.632194C0.366888 0.47491 0.504636 0.342713 0.663245 0.243742C0.821853 0.144771 0.997967 0.0811185 1.18075 0.0567037L1.25701 0.0487847L1.49663 0.0329433C5.31598 -0.198057 8.47689 0.785785 10.9789 2.98447C13.0547 4.80872 14.3924 7.21904 14.9912 10.215L15.01 10.2106L15.0004 10.2599C15.5943 7.2428 16.9349 4.81752 19.021 2.98447C21.5231 0.785785 24.684 -0.198057 28.5034 0.0329433Z',
            fill: 'currentColor'
          }
        })
      ])
    ])
  }
}
