import { AxiosResponse } from 'axios'
import { inject } from 'inversify-props'
import { AuthServiceInterface } from './auth.service.interface'
import { AxiosService } from '../axios/axios.service'
import { AuthenticatedUserDto, LoginResultDto, UserDto } from 'booksprout'
import { UserServiceInterface } from '../user/user.service.interface'
import { LocalStorage } from 'quasar'

export class AuthService implements AuthServiceInterface {
  public constructor (
    @inject('AxiosService') private readonly axiosService: AxiosService,
    @inject('UserService') private readonly userService: UserServiceInterface<UserDto>
  ) {
  }

  public async login (emailAddress: string, password: string, extendLogin: boolean, reinstateConsent: boolean): Promise<LoginResultDto | null> {
    return this.axiosService.axios.post('auth/login', {
      emailAddress,
      password,
      extendLogin,
      reinstateConsent
    }).then((r: { status: number, data: LoginResultDto }) => {
      if (r !== void 0 && r.status === 401) {
        return null
      }

      return r.data
    })
  }

  /**
   * To be called once logging in is complete.
   * This will ensure all services which required auth info are updated
   * This wouldn't happen otherwise unless the page was refreshed.
   */
  public onLoggedIn () {
    this.axiosService.setupAxiosInstance()
  }

  public async logout (): Promise<any> {
    return this.axiosService.axios.post('auth/logout')
  }

  public async setMimicUser (mimicUser: UserDto): Promise<LoginResultDto> {
    return this.axiosService.axios.post('auth/setMimicUser', {
      id: mimicUser ? mimicUser.id : null
    }).then((r: AxiosResponse) => {
      return r.data
    })
  }

  public signUp (emailAddress: string, password: string, agreeToMarketing: boolean, forward?: string): Promise<any> {
    return this.axiosService.axios.post('auth/sign-up', {
      emailAddress: emailAddress,
      password,
      agreeToMarketing,
      forward
    })
  }

  public resetPassword (emailAddress: string): Promise<any> {
    return this.axiosService.axios.post('auth/request-password-reset', {
      emailAddress: emailAddress
    })
  }

  public userResetPassword (oldPassword: string, newPassword: string): Promise<any> {
    return this.axiosService.axios.post('auth/user-password-reset', {
      oldPassword,
      newPassword
    })
  }

  public completeResetPassword (token: string, newPassword: string): Promise<any> {
    return this.axiosService.axios.post('auth/complete-password-reset', {
      token,
      newPassword
    })
  }

  public resendEmailVerificationEmail (emailAddress: string): Promise<any> {
    return this.axiosService.axios.post('auth/resend-verification-email', {
      emailAddress: emailAddress
    })
  }

  public updateUserEmailAddress (oldEmailAddress: string, newEmailAddress: string): Promise<UserDto> {
    return this.axiosService.axios.post('auth/update-user-email', {
      oldEmailAddress,
      newEmailAddress
    }).then(response => {
      return response.data
    })
  }

  public completeVerificationEmail (token: string): Promise<UserDto> {
    return this.axiosService.axios.post('auth/complete-verification-email', {
      token
    }).then((r: { data: UserDto }) => {
      return r.data
    })
  }

  completeOAuthSignUpWithEmail (emailAddress: string): Promise<LoginResultDto> {
    return this.axiosService.axios.post('auth/complete-oauth-login-with-email', {
      emailAddress,
      source: void 0,

    }).then((r: { data: LoginResultDto }) => {
      return r.data
    })
  }

  async updateUserSelectedRole (role: number): Promise<LoginResultDto> {
    const agreedToAllMarketingUpdates = LocalStorage.getItem<boolean>('agreeToMarketing') || false

    return this.axiosService.axios.post('auth/update-user-role', {
      role,
      agreedToAllMarketingUpdates
    }).then((r: { status: number, data: LoginResultDto }) => {
      return r.data
    })
  }

  public getCsrfToken (): Promise<string> {
    return this.axiosService.axios.get('auth/getCsrfToken',{
      withCredentials: true
    }).then((r: { data: string }) => {
      console.log('TOKEN: ', r.data)
      return r.data
    })
  }

  validateToken (token: string, loginType: number, source: string, nonce?: string) {
    return this.axiosService.axios.post('auth/validateToken', {
      token,
      loginType,
      source,
      nonce
    }).then((r: { status: number, data: LoginResultDto }) => {
      if (r !== void 0 && r.status === 401) {
        return null
      }

      return r.data
    }).catch((e: any) => {
      console.error('validateToken error: ', e)
      return null
    })
  }

  // Get the mimic user based on the user token
  getAuthenticatedUser (): Promise<AuthenticatedUserDto | undefined> {
    return this.axiosService.axios.post('auth/authenticated-user').then((r: { status: number, data: AuthenticatedUserDto }) => {
      if (!r.data.user) {
        return void 0
      }

      let mimicUser
      if (r.data.mimicUser) {
        mimicUser = this.userService.makeUserResult(r.data.mimicUser)
      }

      return {
        user: this.userService.makeUserResult(r.data.user),
        mimicUser
      }
    })
  }

  checkForUpdate (currentVersion: string, platform: string, env: string): Promise<{ hasUpdate: boolean; downloadUrl: string | undefined }> {
    return this.axiosService.axios.post('auth/check-for-update', {
      currentVersion,
      platform,
      env
    }).then(r => {
      return r.data
    })
  }
}

