import { Component, Prop } from 'vue-property-decorator'
import { CreateElement } from 'vue'

import { date, QDate, QIcon, QPopupProxy } from 'quasar'
import BsInput from '../input/BsInput'
// Mixins
import ValidationMixin from '../mixins/validation'

import { DATE_FORMAT } from '../../../constants'

const { formatDate, extractDate } = date

@Component
export default class BsDate extends ValidationMixin {
  @Prop({ type: [Date, String] }) readonly value!: Date | string

  get dateValue (): string | undefined {
    if (!this.value) return void 0

    if (typeof this.value === 'string') {
      return this.value
    }

    return formatDate(this.value, DATE_FORMAT)
  }

  __renderScopedSlots (h: CreateElement) {
    return {
      append: () => {
        return (this.$attrs.disable as unknown as boolean) ? void 0 : h(QIcon, {
          props: {
            name: 'event' // @AG, can't use "app:date-picker" here :(
          }
        }, [
          h(QPopupProxy, {
            ref: 'BsDateProxy'
          }, [
            h(QDate, {
              props: {
                value: this.dateValue,
                color: 'bs-g',
                minimal: true,
                options: this.$attrs.options
              },
              on: {
                input: (date: string) => {
                  (this.$refs.BsDateProxy as QPopupProxy).hide()
                  this.$emit('input', extractDate(date, DATE_FORMAT))
                  this.doAutoSave()
                }
              }
            })
          ])
        ])
      }
    }
  }

  render (h: CreateElement) {
    // The date picker will show 01/01/1970 if we have a null value so make sure it's undefined instead.
    if (this.value === null) this.$emit('input', void 0)

    const props = {
      required: this.required,
      autoSave: this.autoSave,
      disable: this.$attrs.disable,
      mask: 'date',
      rules: ['date']
    }

    const scopedSlots = {
      ...this.__renderScopedSlots(h)
    }

    return h(BsInput, {
      staticClass: 'bs-date',
      class: this.getClasses(),
      props: this.getRenderProps(props),
      on: this.getListeners(),
      attrs: this.getAttributes({
        value: this.dateValue,
      }),
      scopedSlots: this.getScopedSlots(h, scopedSlots)
    }, this.slot(this, 'default'))
  }
}
