import { MutationTree } from 'vuex'
import {
  LoginRedirectData,
  ShowMessageDialogData,
  StoreRedirectData,
  UINotification,
  UINotificationType,
  UIState
} from './types'
import {
  CLEAR_NOTIFICATIONS,
  NOTIFY_ERROR,
  REDIRECT_TO,
  SET_UNREAD_MESSAGE_COUNT,
  SET_USER_STATS,
  SHOW_LOGIN_NOTIFICATION,
  SHOW_MESSAGE_DIALOG,
  SET_UNREAD_NOTIFICATION_COUNT,
  SET_READY_TO_RENDER,
  SET_LAST_ERROR
} from '../../actions/ui'
import { resetNotificationState } from './state'
import { UnreadCountDto, UserStatsDto } from 'booksprout'

export const mutations: MutationTree<UIState> = {
  [NOTIFY_ERROR] (state: UIState, message: string) {
    if (state.notifications.find(f => f.message === message) === void 0) {
      const notification: UINotification = {
        type: UINotificationType.Error,
        message
      }
      state.notifications.push(notification)
    }
  },
  [CLEAR_NOTIFICATIONS] (state) {
    state.notifications = resetNotificationState()
  },
  [SET_UNREAD_MESSAGE_COUNT] (state: UIState, count: number) {
    state.unreadMessageCount = count
  },
  [SET_UNREAD_NOTIFICATION_COUNT] (state: UIState, unreadCountDto: UnreadCountDto) {
    state.unreadNotificationCount = unreadCountDto
  },
  [REDIRECT_TO] (state: UIState, data: StoreRedirectData) {
    state.redirect = data
  },
  [SHOW_LOGIN_NOTIFICATION] (state: UIState, data: LoginRedirectData) {
    state.triggerLoginNotification = data.show
    state.loginForwardUrl = data.show ? data.forwardUrl : ''
  },
  [SHOW_MESSAGE_DIALOG] (state: UIState, data: ShowMessageDialogData) {
    state.showMessageDialog = data
    if (!data.show) {
      state.showMessageDialog.message = ''
      state.showMessageDialog.optionalParams = []
    }
  },
  [SET_USER_STATS] (state: UIState, stats: UserStatsDto) {
    state.userStats = stats
  },
  [SET_READY_TO_RENDER] (state: UIState, isReadyToRender: boolean) {
    state.isReadyToRender = isReadyToRender
  },
  [SET_LAST_ERROR] (state: UIState, error: string) {
    state.lastError = error
  }
}
