export const NOTIFY_ERROR = 'NOTIFY_ERROR'
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS'
export const SET_UNREAD_MESSAGE_COUNT = 'SET_UNREAD_MESSAGE_COUNT'
export const SET_UNREAD_NOTIFICATION_COUNT = 'SET_UNREAD_NOTIFICATION_COUNT'
export const REDIRECT_TO = 'REDIRECT_TO'
export const SHOW_LOGIN_NOTIFICATION = 'SHOW_LOGIN_NOTIFICATION'
export const SHOW_MESSAGE_DIALOG = 'SHOW_MESSAGE_DIALOG'
export const CLEAR_MESSAGE_DIALOG = 'CLEAR_MESSAGE_DIALOG'
export const SET_USER_STATS = 'SET_USER_STATS'
export const SET_READY_TO_RENDER = 'SET_READY_TO_RENDER'
export const SET_LAST_ERROR = 'SET_LAST_ERROR'
