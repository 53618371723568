import { gql } from '@apollo/client/core'
import slugify from 'slugify'

import { AxiosService, BaseCrudService, ConfigService, inject, StoreService } from 'booksprout-app'
import { PenNameServiceInterface } from './penName.service.interface'
import {
  CreatePenNameDto,
  EntityDeletionSummaryDto,
  PenNameDto,
  PenNameGetByIdArgs,
  PenNamePagedResultDto,
  UpdatePenNameDto
} from 'booksprout'

export class PenNameService extends BaseCrudService<PenNameDto> implements PenNameServiceInterface<PenNameDto> {
  public apiControllerPath = 'penName'

  public constructor (
    @inject('ConfigService') configService: ConfigService,
    @inject('StoreService') storeService: StoreService,
    @inject('AxiosService') axiosService: AxiosService
  ) {
    super(configService, storeService, axiosService)
  }

  private readonly penNameFragments = {
    penName: gql`
      fragment PenNameFragment on PenNameDto {
        id,
        name,
        firstName,
        lastName,
        bio,
        image,
        facebookLink,
        bookbubLink,
        twitterLink,
        instagramLink,
        websiteLink,
        newsletterLink,
        tiktokLink,
        amazonLink,
        goodreadsLink,
        isDraft,
        followerCount,
        averageRating,
        reviewCount,
        followers {
          id
        }
      }
    `
  }

  public get (params?: any): Promise<PenNamePagedResultDto> {
    return this.apolloClientService.query({
      variables: {
        ...params
      },
      query: gql`
        query GetPenNames (
          $genericSearchTerm: String,
          $genericSearch: Boolean,
          $skip: Int,
          $take: Int,
          $sortBy: String,
          $descending: Boolean,
          $following: Boolean
          $forUserId: Int
        ) {
          penNames (
            genericSearchTerm: $genericSearchTerm,
            genericSearch: $genericSearch,
            skip: $skip,
            take: $take,
            sortBy: $sortBy,
            descending: $descending,
            following: $following
            forUserId: $forUserId
          ) {
            totalRows,
            items {
              ...PenNameFragment
            }
          }
        }
        ${this.penNameFragments.penName}
      `
    })
  }

  public getById (id?: number, args?: PenNameGetByIdArgs): Promise<PenNameDto> {
    return this.apolloClientService.query({
      variables: {
        id,
        ...args
      },
      query: gql`
        query GetPenName (
          $id: Int!,
          $getAverageRating: Boolean,
          $genericSearch: Boolean
        ) {
          penName (id: $id, getAverageRating: $getAverageRating, genericSearch: $genericSearch) {
            ...PenNameFragment
          }
        }
        ${this.penNameFragments.penName}
      `
    })
  }

  public create (dto: CreatePenNameDto): Promise<PenNameDto> {
    return this.apolloClientService.mutate({
      variables: {
        name: dto.name,
        bio: dto.bio,
        image: dto.image,
        facebookLink: dto.facebookLink,
        bookbubLink: dto.bookbubLink,
        twitterLink: dto.twitterLink,
        instagramLink: dto.instagramLink,
        websiteLink: dto.websiteLink,
        newsletterLink: dto.newsletterLink,
        tiktokLink: dto.tiktokLink,
        amazonLink: dto.amazonLink,
        goodreadsLink: dto.goodreadsLink
      },
      mutation: gql`
        mutation CreatePenName (
          $name: String!,
          $bio: String,
          $image: String,
          $facebookLink: String,
          $bookbubLink: String,
          $twitterLink: String,
          $instagramLink: String,
          $websiteLink: String,
          $newsletterLink: String,
          $tiktokLink: String,
          $amazonLink: String,
          $goodreadsLink: String
        ) {
          createPenName (
            name: $name,
            bio: $bio,
            image: $image,
            facebookLink: $facebookLink,
            bookbubLink: $bookbubLink,
            twitterLink: $twitterLink,
            instagramLink: $instagramLink,
            websiteLink: $websiteLink,
            newsletterLink: $newsletterLink,
            tiktokLink: $tiktokLink,
            amazonLink: $amazonLink,
            goodreadsLink: $goodreadsLink
          ) {
            ...PenNameFragment
          }
        }
        ${this.penNameFragments.penName}
      `
    })
  }

  public update (dto: UpdatePenNameDto): Promise<PenNameDto> {
    return this.apolloClientService.mutate({
      variables: {
        id: dto.id,
        name: dto.name,
        bio: dto.bio,
        image: dto.image,
        facebookLink: dto.facebookLink,
        bookbubLink: dto.bookbubLink,
        twitterLink: dto.twitterLink,
        instagramLink: dto.instagramLink,
        websiteLink: dto.websiteLink,
        newsletterLink: dto.newsletterLink,
        tiktokLink: dto.tiktokLink,
        amazonLink: dto.amazonLink,
        goodreadsLink: dto.goodreadsLink
      },
      mutation: gql`
        mutation UpdatePenName (
          $id: Int!,
          $name: String,
          $bio: String,
          $image: String,
          $facebookLink: String,
          $bookbubLink: String,
          $twitterLink: String,
          $instagramLink: String,
          $websiteLink: String,
          $newsletterLink: String,
          $tiktokLink: String,
          $amazonLink: String,
          $goodreadsLink: String
        ) {
          updatePenName (
            id: $id,
            name: $name,
            bio: $bio,
            image: $image,
            facebookLink: $facebookLink,
            bookbubLink: $bookbubLink,
            twitterLink: $twitterLink,
            instagramLink: $instagramLink,
            websiteLink: $websiteLink,
            newsletterLink: $newsletterLink,
            tiktokLink: $tiktokLink,
            amazonLink: $amazonLink,
            goodreadsLink: $goodreadsLink
          ) {
            ...PenNameFragment
          }
        }
        ${this.penNameFragments.penName}
      `
    })
  }

  public checkDelete (id: number): Promise<EntityDeletionSummaryDto[]> {
    return this.apolloClientService.query({
      variables: {
        id
      },
      query: gql`
        query CheckDeletePenName ($id: Int!) {
          checkDeletePenName(id: $id) {
            count,
            dtoTypeName,
            ids
          }
        }
      `
    })
  }

  public delete (id?: number): Promise<any> {
    return this.apolloClientService.mutate({
      variables: {
        id
      },
      mutation: gql`
        mutation ConfirmDeletePenName (
          $id: Int!
        ) {
          confirmDeletePenName(id: $id) {
            id
          }
        }
      `
    })
  }

  public follow (id: number): Promise<PenNameDto> {
    return this.apolloClientService.mutate({
      variables: {
        id
      },
      mutation: gql`
        mutation FollowPenName (
          $id: Int!
        ) {
          followPenName(id: $id) {
            ...PenNameFragment
          }
        }
        ${this.penNameFragments.penName}
      `
    })
  }

  public unfollow (id: number): Promise<PenNameDto> {
    return this.apolloClientService.mutate({
      variables: {
        id
      },
      mutation: gql`
        mutation UnfollowPenName (
          $id: Int!
        ) {
          unfollowPenName(id: $id) {
            ...PenNameFragment
          }
        }
        ${this.penNameFragments.penName}
      `
    })
  }

  public getUrl (model: PenNameDto): string {
    return `https://booksprout.co/pen-name/${model.id}/${slugify(model.name)}`
  }
}
