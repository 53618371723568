import { Component } from 'vue-property-decorator'
import { WebsitePaths } from '../../router/path.constants'
import { ArcServiceInterface, CommonBaseMixin, inject } from 'booksprout-app'
import { Getter } from 'vuex-class'
import { ArcDto, ArcWebStatsDto } from 'booksprout'
import { SET_WEBSITE_STATS } from '../../store/actions/stats'

@Component
export class BaseMixin extends CommonBaseMixin {
  @inject('ArcService')
  arcService!: ArcServiceInterface<ArcDto>

  websitePaths = { ...WebsitePaths }

  @Getter('getStats') stats!: ArcWebStatsDto

  abbreviatedCount (count: number, useShortAbbreviation: boolean = true): string {
    const result = this.countAsLocaleString(count)

    switch (result.length) {
      case 6:
        return useShortAbbreviation ? result.substring(0, 2) + 'k+' : result
      case 7:
        return useShortAbbreviation ? result.substring(0, 3) + 'k+' : result
      case 9:
        return useShortAbbreviation ? result.substring(0, 3) + 'm+' : result
      case 10:
        return useShortAbbreviation ? result.substring(0, 4) + 'm+' : result
      default:
        return result
    }
  }

  countAsLocaleString (count: number): string {
    const result = count + ''
    // using german culture so we can have a dot separator, not comma
    const numberFormat = new Intl.NumberFormat(
      [5, 6].includes(result.length) ? 'en-US' : 'de-DE',
      {
        style: 'decimal'
      })

    switch (result.length) {
      case 5:
      case 6:
        return numberFormat.format(Math.floor(count / 5000) * 5000)
      case 7:
        return numberFormat.format(Math.floor(count / 50000) * 50000)
      default:
        return numberFormat.format(count)
    }
  }

  async getWebsiteStats (): Promise<ArcWebStatsDto> {
    if (this.stats) {
      return this.stats
    }

    return this.arcService.globalLoader(false).getWebStats().then(stats => {
      return this.$store.dispatch(SET_WEBSITE_STATS, stats).then(() => {
        this.arcService.globalLoader(true)
        return stats
      })
    })
  }
}
