import { CreateElement } from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { QIcon, QSelect } from 'quasar'
import * as emailValidator from 'email-validator'

// Mixins
import ValidationMixin from '../mixins/validation'
import { validStr } from 'booksprout'
import { ComponentState } from '../mixins/common'
import { BsChip } from '../index'

@Component
export default class BsChipBox extends ValidationMixin {
  @Prop({ type: [String, Array] }) public readonly value!: string
  @Prop({ type: Boolean }) public readonly returnArray!: boolean
  @Prop({ type: Array, default: () => { return [','] } }) public triggerChars!: string[]
  @Prop({ type: Boolean }) public readonly emailInput!: boolean
  @Prop({ type: Array }) readonly readonlyValues!: string[]
  @Prop({ type: Number }) readonly maxCharsPerEntry!: number
  @Prop({ type: Number }) readonly maxEntries!: number

  rollingChange = ''
  invalidValues: string[] = []

  public setContent (str: string) {
    const qSelect = (this.$refs.bsChipBox as QSelect)
    qSelect.add(str.replace('\n', ''), true)

    this.rollingChange = ''
  }

  public setCsvData (csv: string, separator: string = ',') {
    const
      pasteArray = csv.split(separator),
      items = pasteArray.map((m: any) => m.trim()).filter((f: any) => !!f)

    for (let i = 0; i < items.length; i++) {
      const data = items[i]

      if (data) {
        // Add doesn't quite work fast enough so need a timeout here.
        setTimeout(() => {
          this.setContent(data)

          if (i === items.length - 1) {
            this.$emit('pasteComplete')
          }
        }, 50)
      }
    }
  }

  public mounted () {
    // Attach a paste event to the underlying input
    ((this.$refs.bsChipBox as QSelect).$refs.target as HTMLElement).addEventListener('paste', (e: any) => {
      // @ts-ignore
      const pasteData = (e.clipboardData || window.clipboardData).getData('text')

      // If it has commas, process it and add each individually.
      if (pasteData.indexOf(',') > -1) {
        this.setCsvData(pasteData)
      } else if (pasteData.indexOf('\n') > -1) { // If user copied data from upload file
        this.setCsvData(pasteData, '\n')
      } else {
        this.setContent(pasteData)
        this.$emit('pasteComplete')
      }
    })
  }

  public doBeforeAutoSave (val: any) {
    if (validStr(this.rollingChange)) {
      this.setContent(this.rollingChange)
    }
  }

  __renderChip (h: CreateElement, scope: any) {
    const isError = this.invalidValues.indexOf(scope.opt) > -1
    const isReadonly = this.readonlyValues?.includes(scope.opt)

    return h(BsChip, {
      staticClass: 'text-bold',
      class: isReadonly ? 'bs-chip__readonly' : '',
      props: {
        dense: true,
        color:
          !isError ?
            isReadonly ?
              'bs-lb-r' :
              'bs-g'
            : 'bs-r',
        textColor: isReadonly && !isError ? 'bs-lb' : 'white'
      }
    }, [
      h('span',{
        staticClass: 'ellipsis'
      }, [
        scope.opt
      ]),
      !isReadonly && h(QIcon, {
        staticClass: 'cursor-pointer',
        props: {
          name: 'app:close',
          size: '.5rem'
        },
        on: {
          click: () => scope.removeAtIndex(scope.index)
        }
      })
    ])
  }

  public render (h: CreateElement) {
    const value = this.value && this.value.indexOf(',') > -1
      // CSV data
      ? this.value.split(',').map((m: any) => m.trim()).filter((f: any) => !!f)
      : (
        // Is an array
        Array.isArray(this.value)
          ? this.value
          // Not an array, make one.
          : this.value ? [this.value] : null
      )

    const props = {
      filled: true,
      useInput: true,
      useChips: true,
      multiple: true,
      hideDropdownIcon: true,
      inputDebounce: '0',
      newValueMode: 'add-unique',
      bottomSlots: true,
      value,
      rules: [
        (chipValues: string[]) => {
          if (!chipValues || chipValues.length === 0) return true

          let foundInvalid = false
          for (const val of chipValues) {
            if (val === '' || emailValidator.validate(val) || !this.emailInput) {
              if (!foundInvalid) {
                this.componentState = ComponentState.Valid
              }
              continue
            }

            this.invalidValues.push(val)
            this.componentState = ComponentState.Invalid
            this.errorMessage = this.$tc('system.errors.chipBox.invalidEmailsDetected')
            foundInvalid = true
          }

          return this.componentState === ComponentState.Valid
        },
        (chipValues: string[]) => {
          if (!chipValues || chipValues.length === 0) return true

          for (const val of chipValues) {
            if (this.maxCharsPerEntry !== void 0 && val && val.length > this.maxCharsPerEntry) {
              this.invalidValues.push(val)
              this.componentState = ComponentState.Invalid
              this.errorMessage = this.$tc('system.errors.chipBox.maxCharsPerEntry', void 0, {
                userEnteredValue: val,
                maxCharsPerEntry: this.maxCharsPerEntry
              })

              return false
            }
          }

          return this.componentState === ComponentState.Valid
        }
      ]
    }

    const listeners = {
      keydown: (e: any) => {
        // If the user presses the tab key and they've entered some text, add it as a chip.
        if (
          (
            // legacy -> https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
            e.keyCode === 9 /*TAB*/ ||
            e.keyCode === 13 /*ENTER*/ ||
            e.which === 9 /*TAB*/ ||
            e.which === 13 /*ENTER*/ ||
            e.key === 'Tab' ||
            e.key === 'Enter' ||
            // newest property, as per suggestion by MDN (see above link)
            e.code === 'Tab' ||
            e.code === 'Enter'
          ) && validStr(e.target.value)) {
          if (e.target.value.indexOf(',') > -1) {
            this.setCsvData(e.target.value)
          } else {
            this.setContent(e.target.value)
          }
        }
      },
      keyup: (e: any) => {
        // This is to track the data inputted for when we use doOnAutosave (blur) so we can save the value before it's lost.
        this.rollingChange = e.target.value

        if (this.triggerChars.includes(e.key)) {
          let value = e.target.value
          this.triggerChars.forEach(c => value = value.replace(c, ''))
          this.setContent(value)
        }
      },
      input: (items: []) => {
        const concatenatedArray = items.reduce((accumulator: string[], currentValue: string) => {
          if (currentValue && currentValue.includes(',')) {
            return [...accumulator, ...currentValue.split(',')]
          }

          return [...accumulator, currentValue]
        }, [])
        const uniqueConcatenatedArray = [...new Set(concatenatedArray)]
        const returnData = this.returnArray ? uniqueConcatenatedArray : uniqueConcatenatedArray.join(',')
        const qSelect = (this.$refs.bsChipBox as QSelect)

        if (this.maxEntries !== void 0 && uniqueConcatenatedArray.length > this.maxEntries) return

        this.$emit('input', returnData)

        // clear input value once validated and data is emitted
        qSelect.updateInputValue('', true)
      }
    }

    return h(QSelect, {
      ref: 'bsChipBox',
      staticClass: 'bs-chip-box',
      class: this.getClasses(),
      props: this.getRenderProps(props),
      on: this.getListeners(listeners),
      attrs: this.getAttributes(),
      scopedSlots: this.getScopedSlots(h, {
        ['selected-item']: (slotData: any) => {
          return this.__renderChip(h, slotData)
        }
      })
    }, [
      this.slot(this, 'default'),
      this.slot(this, 'hint')
    ])
  }
}

