import { Module } from 'vuex'
import authState from './state'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'
import { AuthState } from './types'
import { BaseRootState } from '../../baseRootState'

export const auth: Module<AuthState, BaseRootState> = {
  state: authState,
  getters,
  mutations,
  actions
}
