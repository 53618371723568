import { AuthState } from './types'
import { UserDto } from 'booksprout'

const resetAuthState = (): AuthState => {
  return {
    csrfToken: '',
    user: new UserDto(),
    token: {
      data: '',
      expires: 0
    },
    mimicUser: new UserDto(),
    termsConsentNeeded: false,
    termsConsentFailed: false,
    termsConsentData: {
      emailAddress: '',
      password: '',
      extendLogin: true,
      loginResult: void 0
    },
    authExpired: false
  }
}

export default (): AuthState => ({
  ...resetAuthState()
})

export { resetAuthState }
