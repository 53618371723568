import { inject } from 'inversify-props'
import { ApolloClientServiceInterface } from './apolloClient.service.interface'
import { StoreService } from '../store.service'
import { ConfigService } from '../config.service'
import { ApiErrorHandler } from '../apiErrorHandler'
import {
  ApolloClient,
  from,
  InMemoryCache,
  MutationOptions,
  OperationVariables,
  QueryOptions,
  HttpLink
} from '@apollo/client/core'
import 'cross-fetch/polyfill'

import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { BaseModelDto } from 'booksprout'
import VueI18n from 'vue-i18n'
import { BaseRootState } from '../../../store/baseRootState'
import { REDIRECT_TO } from '../../../store/actions/ui'
import { AUTH_EXPIRED, GET_CSRF_TOKEN } from '../../../store/actions/auth'
import { requireConfigFile } from 'app-reviewer/src/constants'
import promiseToObservable from './promiseToObservable'
import { Notify, Loading } from 'quasar'
import { AxiosServiceInterface } from '../axios/axios.service.interface'
import { container, IsReviewerApp } from '../../../../index'
import * as Sentry from '@sentry/vue'

let failedToConnectNotification: Function | undefined
export class BaseApolloClientService extends ApiErrorHandler implements ApolloClientServiceInterface {
  constructor (
    @inject('StoreService') private readonly storeService: StoreService<BaseRootState>,
    @inject('ConfigService') public readonly configService: ConfigService,
    @inject('TranslationService') translationService: VueI18n
  ) {
    super(translationService)
  }

  showGlobalLoader = true

  get client () : ApolloClient<any> {
    const errorLink = onError((error: any): any => {
      const { graphQLErrors, response, networkError, operation, forward } = error

      if (process.env.NODE_ENV === 'development') {
        console.log(graphQLErrors, networkError, operation)
        console.log('================DEV: There was an error in Apollo:================')
        console.log('operation: ', operation)
        console.log('response: ', response)
        console.log('graphQLErrors: ', graphQLErrors)
        console.log('networkError: ', networkError)
        console.log('==================================================================')
      }

      Sentry.withScope((scope) => {
        scope.setTransactionName(operation.operationName)
        scope.setContext('apolloGraphQLOperation', {
          operationName: operation.operationName,
          variables: operation.variables,
          extensions: operation.extensions
        })

        graphQLErrors?.forEach((error: any) => {
          Sentry.captureException(error.message, {
            fingerprint: ['{{ default }}', '{{ transaction }}'],
            contexts: {
              apolloGraphQLError: {
                error,
                message: error.message,
                extensions: error.extensions,
              }
            }
          })
        })

        if (networkError) {
          Sentry.captureException(networkError.message, {
            contexts: {
              apolloNetworkError: {
                error: networkError,
                extensions: (networkError as any).extensions
              }
            }
          })
        }
      })

      if (networkError) {
        // This will only ever happen for mobile as the desktop version will have hit a wall in nginx by now.
        if (networkError?.result?.offline) {
          return this.storeService.store.dispatch(REDIRECT_TO, {
            url: 'maintenance'
          })
        }

        if (!networkError.result) {
          if (!failedToConnectNotification) {
            failedToConnectNotification = Notify.create({
              type: 'ongoing',
              message: 'Connecting ...'
            })

            const reconnecting = (): Promise<any> => {
              return new Promise(resolve => {
                const axiosService: AxiosServiceInterface = container.get('AxiosService')
                setInterval(async () => {
                  return axiosService.axios.post('auth/live', {}, {
                    timeout: 5000
                  }).then(async () => {
                    if (failedToConnectNotification) {
                      failedToConnectNotification({
                        type: 'positive',
                        message: 'Connected. Reloading ...',
                        icon: 'app:check',
                        timeout: 2000
                      })
                    }

                    // Just reload, it makes sure everything is tip top on the app.
                    document.location.reload()

                    /*await this.storeService.store.dispatch(AUTH_LOAD)
                    clearInterval(interval)
                    if (failedToConnectNotification) {
                      failedToConnectNotification({
                        type: 'positive',
                        message: 'Connected!',
                        timeout: 1000
                      })
                    }
                    failedToConnectNotification = void 0
                    resolve({})*/
                  })
                }, 5000)
              })
            }

            const reconnect = reconnecting()
            return promiseToObservable(reconnect).flatMap(() => {
              operation.setContext(({ headers = {} }) => ({
                headers: {
                  ...headers,
                  authorization: this.storeService.store.getters.isAuthenticated
                    ? `Bearer ${this.storeService.store.getters.authToken}`
                    : ''
                }
              }))
              return forward(operation)
            })
          }
        } else if (networkError.result.message === 'invalid csrfToken') {
          return promiseToObservable(this.refreshToken()).flatMap(() => {
            operation.setContext(({ headers = {} }) => ({
              headers: {
                ...headers,
                'CSRF-Token': this.storeService.store.getters.csrfToken
              }
            }))
            return forward(operation)
          })
        }
      } else if (response !== void 0) {
        const errors = response.errors
        if (errors && errors.length && errors[0].extensions) {
          const error = errors[0]?.extensions?.exception?.response || errors[0]?.extensions?.response
          if (error !== void 0 && error.statusCode) {
            switch (error.statusCode) {
              case 401:
                // this.storeService.store.dispatch(NOTIFY_ERROR, error.message)
                this.storeService.store.dispatch(AUTH_EXPIRED, true)
                break
              case 403:
                this.storeService.store.dispatch(REDIRECT_TO, {
                  name: 'login'
                })
                break
              case 404:
                this.storeService.store.dispatch(REDIRECT_TO, {
                  name: '404'
                })
                break
              // default: this.storeService.store.dispatch(NOTIFY_ERROR, error.message); break;
            }
          }
        }
      }
    })

    // this will retry operation related to Network Errors
    // as per https://www.apollographql.com/docs/react/api/link/apollo-link-retry
    // and https://www.apollographql.com/docs/react/data/error-handling/
    const retryLink = new RetryLink({
      delay: {
        initial: 300,
        max: Infinity,
        jitter: true
      },
      attempts: {
        max: 3,
        retryIf: (error) => !!error
      }
    })

    const apolloClient = new ApolloClient({
      link: from([
        errorLink,
        retryLink,
        new HttpLink({
          uri: this.configService.config.apolloClient.url,
          credentials: 'include',
          headers: {
            'CSRF-Token': this.storeService.store.getters.csrfToken,
            // If we have an authenticated user, attach the appropriate headers.
            authorization: this.storeService.store.getters.isAuthenticated
              ? `Bearer ${this.storeService.store.getters.authToken}`
              : '',
            'Referring-App': IsReviewerApp() ? 'reviewer' : 'publisher'
          }
        })
      ]),
      cache: new InMemoryCache({
        // This will fix an issue whereby a MessagePostDto.sender could be a user, arc team or pen name
        // If one post is a pen name with id: 1 and another post is an arc team with id: 1, the cached results
        // show whichever is loaded first. modelKey will have a format of "penName_{id}", "arcTeam_{id}" or "user_{id}"
        // to prevent this.
        typePolicies: {
          BaseThreadUserDto: {
            keyFields: ['modelKey']
          }
        }
      }),
      connectToDevTools: process.env.NODE_ENV === 'development',
      defaultOptions: {
        query: {
          fetchPolicy: 'network-only',
          errorPolicy: 'all'
        },
        mutate: {
          errorPolicy: 'all'
        }
      }
    })

    return apolloClient
  }

  refreshToken () {
    console.log('Refreshing token')
    return this.storeService.store.dispatch(GET_CSRF_TOKEN, requireConfigFile())
  }

  query<T = BaseModelDto, TVariables = OperationVariables> (options: QueryOptions<TVariables>): Promise<T> {
    // Set this var loading won't trigger after the return call if its faster than 200ms
    let shouldShowLoading = true

    if (this.showGlobalLoader) {
      setTimeout(() => {
        if (shouldShowLoading) {
          Loading.show()
        }
      }, 200)
    }

    return this.client.query(options).then(r => {
      shouldShowLoading = false
      Loading.hide()
      return this.handleResponse(r)
    })
  }

  mutate<T = BaseModelDto, TVariables = OperationVariables> (options: MutationOptions<T, TVariables>): Promise<T> {
    // Set this var loading won't trigger after the return call if its faster than 200ms
    let shouldShowLoading = true
    if (this.showGlobalLoader) {
      setTimeout(() => {
        if (shouldShowLoading) {
          Loading.show()
        }
      }, 200)
    }

    return this.client.mutate(options).then(r => {
      shouldShowLoading = false
      Loading.hide()
      return this.handleResponse(r)
    })
  }

  private generateErrorResponse (error: any) {
    if (error.statusCode !== void 0) {
      if (error.message && typeof error?.message === 'string') {
        return this.getErrorResponse(error.message)
      } else {
        return this.getErrorResponse(error.error)
      }
    } else if (typeof error === 'string') {
      return this.getErrorResponse(error)
    } else if (error !== void 0 && error.message.length > 0 && error.message[0].constraints !== void 0) {
      return this.getConstraintErrorResponse(error.message[0].constraints)
    } else if (error !== void 0 && error.message.errorCode === void 0) {
      return this.getErrorResponse(error.message)
    } else {
      return this.getErrorResponseFromCode(error.message.errorCode)
    }
  }

  private handleResponse (r: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (r && r.errors) {
        let error = r.errors[0].extensions?.exception?.response || r.errors[0].extensions?.response
        if (error === void 0) {
          error = r.errors[0]
        }
        reject(this.generateErrorResponse(error))
      } else if (r.networkError) {
        return reject(this.getErrorResponse(r.networkError.result.errors))
      } else {
        // Make sure we found something, i.e r.data.penNames, r.data.arcs
        const keys = Object.keys(r.data)
        if (!r || !r.data || keys.length === 0) {
          resolve(null)
        }

        const thisData = r.data[keys[0]]

        /**
         * Destructuring used here so we're not returning a cached reference to the object as this will throw issues when
         * attaching the v-model in vue as the cache value is immutable.
         */

        // Array data? Destructure into an Array.
        if (Array.isArray(thisData)) {
          // NOTE: Can't do the below as it doesn't detatch the items in the array from the cache.
          // return resolve([...thisData])

          // Not ideal but build a new array of data
          const result: any[] = []
          thisData.forEach(item => {
            result.push({...item})
          })
          return resolve(result)
        } else if (typeof thisData === 'object') {
          if (thisData === null) {
            return resolve(null)
          }

          // Object? Destructure into an object.
          // return resolve({ ...thisData })
          // Have changed to stringify/parse instead of the above as nested props were still readonly i.e
          // user preferences. This should be save enough as the data coming back from apollo would have been
          // serializable anyway.
          return resolve(JSON.parse(JSON.stringify(thisData)))
        } else {
          return resolve(thisData) // Flat data with one return prop
        }
      }
    })
  }
}
