import { CreateElement } from 'vue'
import {Component, Prop} from 'vue-property-decorator'
import { QChip } from 'quasar'

// Mixins
import { BaseBsComponentMixin } from '../mixins/common'

@Component
export default class BsTag extends BaseBsComponentMixin {
    @Prop({ type: String }) public readonly label!: string
    @Prop({ type: String }) public readonly icon!: string
    @Prop({ type: String }) public readonly textColor!: string

    public render (h: CreateElement): any {
        return h(QChip, {
            staticClass: 'bs-tag',
            props: {
                label: this.label,
                icon: this.icon,
                square: true,
                size: '8px',
                textColor: this.textColor
            }
        })
    }
}