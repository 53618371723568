import { QSsrContext } from '@quasar/app'
import { Store } from 'vuex'
import { RootState } from '../../store/types'
import { container, ArcServiceInterface, ArcService } from 'booksprout-app'
import { ArcDto } from 'booksprout'

export default (ssrContext: QSsrContext, store: Store<RootState>) => {
  container.addTransient<ArcServiceInterface<ArcDto>>(ArcService, 'ArcService')
}

