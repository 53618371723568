import { CreateElement } from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { QSelect, QItem, QItemSection, QItemLabel, QAvatar, QImg } from 'quasar'

// Utils
import { slot } from '../utils'

// Mixins
import { BaseBsComponentMixin } from '../mixins/common'
import { ellipsis } from '../../../utils'

@Component
export default class BsSelect extends BaseBsComponentMixin {
  @Prop({ type: Boolean }) readonly buttonOption!: boolean
  @Prop({ type: Boolean }) readonly large!: boolean
  @Prop({ type: Boolean }) readonly squareAvatar!: boolean
  @Prop({ type: Boolean }) readonly fitAvatar!: boolean
  @Prop({ type: Boolean }) readonly flat!: boolean
  @Prop({ type: String }) readonly popupContentClass!: string
  @Prop({ type: Number }) readonly ellipsisCharCount!: number

  protected autoSaveMethod = 'input'

  // Override this so we can allow for negative number options to be something like "Please select..."
  isComponentIsValid (val?: any) {
    return val !== null && val !== '' && val !== void 0 && val > -1
  }

  get getPopupContentClass () {
    let classes = 'bs-select-options'

    if (this.flat) {
      classes = classes + '__flat'
    } else if (this.buttonOption) {
      classes += ' bs-select-options--button-enabled'
    }

    if (this.popupContentClass) {
      classes += ` ${this.popupContentClass}`
    }

    return classes
  }

  get backgroundColor () {
    if (this.hasErrors) {
      return 'imp-bs-r-l'
    }

    return this.$attrs['bg-color']
  }

  __renderOptions (props: Record<string, any>, h: CreateElement) {
    // Don't render the currently selected option as another option to select.
    if (props.opt.value === this.componentValue) {
      return
    }

    return h(QItem, {
      staticClass: 'bs-select-option',
      class: {
        'bs-select-option--large': this.large,
        'bs-select-option--fit-avatar': this.fitAvatar
      },
      props: {
        ...props.props,
        clickable: true
      },
      on: props.itemEvents
    }, [
      props.opt.avatar === void 0 || !this.large
        ? void 0
        : h(QItemSection, {
          props: {
            avatar: true
          }
        }, [
          h(QAvatar, {
            staticClass: 'on-left',
            props: {
              square: this.squareAvatar
            }
          }, [
            h(QImg, {
              props: {
                src: props.opt.avatar
              }
            })
          ])
        ]),
      h(QItemSection, {}, [
        h(QItemLabel, [
          props.opt.label
        ])
      ])
    ])
  }

  __renderSelectedItem (props: Record<string, any>, h: CreateElement) {
    return h(QItem, {
      staticClass: 'bs-selected-option',
      props: {
        ...props.props,
        clickable: true,
        dense: true
      },
      on: props.itemEvents
    }, [
      props.opt.avatar && h(QItemSection, {}, [
        h(QAvatar, {
          staticClass: 'on-left',
          props: {
            square: this.squareAvatar
          }
        }, [
          h(QImg, {
            props: {
              src: props.opt.avatar
            }
          })
        ])
      ]),
      h(QItemSection, {}, [
        h(QItemLabel, [
          props.opt.label
        ])
      ])
    ])
  }

  render (h: CreateElement) {
    const currentLabel = Array.isArray(this.$attrs['options'])
      ? Array.from(this.$attrs['options']).find(option => option.value === this.componentValue)?.label
      : '' // BsSelect would not work without options prop, using fallback value as a precaution

    const props = {
      mapOptions: this.$attrs['map-options'] !== void 0 ? this.$attrs['map-options'] : true,
      emitValue: this.$attrs['emit-value'] !== void 0 ? this.$attrs['emit-value'] : true,
      dropdownIcon: 'app:arrow-down',
      popupContentClass: this.getPopupContentClass,
      displayValue: ellipsis(currentLabel, this.ellipsisCharCount || 23),
      stackLabel: true,
      borderless: true,
      bottomSlots: true,
      noErrorIcon: true,
      filled: this.flat && !this.hasErrors,
      outlined: this.hasErrors,
      bgColor: this.backgroundColor,
      virtualScrollSliceSize: this.$attrs['options'].length,
      dense: true,
      // show menu regardless if it is desktop or mobile
      behavior: 'menu'
    }

    let scopedSlots = {}
    // Only if we're not trying to override from outside the component
    if (this.$scopedSlots.option === void 0) {
      scopedSlots = {
        option: (props: Record<string, any>) => this.__renderOptions(props, h)
      }
    }

    // Smaller select doesn't support this.
    if (this.$scopedSlots.selectedItem === void 0 && this.large) {
      scopedSlots = {
        ...scopedSlots,
        ['selected-item']: (props: Record<string, any>) => this.__renderSelectedItem(props, h)
      }
    }

    return h('div', {
      staticClass: 'bs-select-container',
      // @ts-ignore
      id: this.$vnode?.data?.model?.expression
    }, [
      h(QSelect, {
        ref: 'bsSelect',
        staticClass: 'bs-select',
        class: this.getClasses({
          'bs-select--flat': this.flat,
          'bs-select--flat__default-option-showing': this.flat && parseInt(this.$attrs.value) === -1,
          'bs-select--large': this.large,
          'bs-select--fit-avatar': this.fitAvatar
        }),
        props: this.getRenderProps(props),
        on: this.getListeners({
          blur: () => this.validate(this.$attrs.value)
        }),
        attrs: this.getAttributes(),
        scopedSlots: this.getScopedSlots(h, scopedSlots)
      }, [
        slot(this, 'default')
      ])
    ])
  }
}
