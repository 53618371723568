// import * as Sentry from '@sentry/browser'

import { container, resetContainer, inject } from 'inversify-props'
import { QSsrContext } from '@quasar/app'
import { Store } from 'vuex'
import { BaseRootState } from '../store/baseRootState'

export const buildDependencyContainer = (ssrContext: QSsrContext, store: Store<BaseRootState>) => {
  // Sentry.init({ dsn: 'https://a2047568417d44b1827728d57dc15a01@sentry.io/1895081' })
  resetContainer()

  // Look in all our modules (and register the dependencies.
  // @ts-ignore
  const moduleContainers = require.context('src/modules', true, /container\.ts$/).keys()
  for (const moduleContainerFile of moduleContainers) {
    const moduleContainer = require('src/modules/' + moduleContainerFile.replace('./', '')).default
    if (typeof moduleContainer === 'function') {
      moduleContainer(ssrContext, store)
    }
  }

  return container
}

export { container, inject, resetContainer }
