import Vue from 'vue'
import Vuex, { Store } from 'vuex'

import { auth, ui, user, system } from 'booksprout-app'
import { RootState } from './types'

import { QSsrContext } from '@quasar/app'
import { buildDependencyContainer } from 'booksprout-app'
import { stats } from './modules/stats'

Vue.use(Vuex)

export default function ({ ssrContext }: { ssrContext: QSsrContext }) {
  const store: Store<RootState> = new Vuex.Store( {
    modules: {
      auth,
      ui,
      user,
      system,
      stats
    },

    // enable strict mode (adds overhead!)
    // for dev mode only
    strict: !!process.env.DEV
  })

  buildDependencyContainer(ssrContext, store)
  return store
}
