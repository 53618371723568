// Utils
import { Component, Prop } from 'vue-property-decorator'
import { CreateElement } from 'vue/types/vue'

// Mixins
import CommonMixin from '../mixins/common'
import { QDialog, QIcon } from 'quasar'
import { BsBtn } from '../index'

@Component
export default class BsModal extends CommonMixin {
  @Prop({ type: Boolean }) readonly value!: boolean
  @Prop({ type: String }) readonly title!: string
  @Prop({ type: String }) readonly icon!: string
  @Prop({ type: String }) readonly okText!: string
  @Prop({ type: String }) readonly cancelText!: string
  @Prop({ type: Boolean }) readonly hideClose!: boolean
  @Prop({ type: Boolean }) readonly okButtonAsWarning!: boolean

  get dialog (): QDialog {
    return this.$refs.qDialog as QDialog
  }

  __renderCloseButton (h: CreateElement) {
    if (this.hideClose) return void 0

    return h(QIcon, {
      staticClass: 'bs-modal__close',
      on: {
        click: () => {
          this.$emit('input', false)
          this.$emit('close')
        }
      },
      props: {
        name: 'app:close-circle'
      }
    })
  }

  __renderTitle (h: CreateElement) {
    return h('div', {
      staticClass: 'bs-modal__title'
    }, [
      this.icon && h(QIcon, {
        staticClass: 'on-left',
        props: {
          name: this.icon
        }
      }),
      this.title && h('h2', this.title)
    ])
  }

  __renderHeader (h: CreateElement) {
    return h('div', {
      staticClass: 'bs-modal__header'
    }, [
      this.__renderTitle(h),
      this.__renderCloseButton(h)
    ])
  }

  __renderOkButton (h: CreateElement) {
    return h(BsBtn, {
      props: {
        large: true,
        warning: this.okButtonAsWarning
      },
      on: {
        click: () => {
          this.$emit('input', false)
          this.$emit('close', false)
          this.$emit('ok', false)
        }
      },
      attrs: {
        'data-model': 'btnModalOk'
      }
    }, this.okText || 'DONE')
  }

  __renderCancelButton (h: CreateElement) {
    return h(BsBtn, {
      props: {
        large: true,
        secondary: true,
        warning: !this.okButtonAsWarning
      },
      on: {
        click: () => {
          this.$emit('input', false)
          this.$emit('close', false)
          this.$emit('cancel', false)
        }
      },
      attrs: {
        'data-model': 'btnModalCancel'
      }
    }, this.cancelText)
  }

  __renderFooter (h: CreateElement) {
    return h('div', {
      staticClass: 'bs-modal__footer'
    }, [
      this.cancelText === void 0 ? void 0 : this.__renderCancelButton(h),
      this.__renderOkButton(h)
    ])
  }

  __renderContent (h: CreateElement) {
    return h('div', {
      staticClass: 'bg-white'
    }, [
      this.__renderHeader(h),
      this.slot(this, 'default'),
      this.__renderFooter(h)
    ])
  }

  __renderDialog (h: CreateElement) {
    const listeners = {
      input: (val: boolean) => {
        // this.dialog.hide()
        this.$emit('input', val)
        this.$emit('close')
      }
    }

    return h(QDialog, {
      ref: 'qDialog',
      props: {
        value: this.value,
        persistent: true,
        contentClass: 'bs-modal',
        maximized: this.$q.screen.xs
      },
      on: this.getListeners(listeners)
    }, [
      this.__renderContent(h)
    ])
  }

  render (h: CreateElement) {
    return this.__renderDialog(h)
  }
}
