import { CreateElement } from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { QTabPanels, QTabPanel } from 'quasar'
import { BsBtn, BsForm } from '../index'

// Mixins
import ValidationMixin from '../mixins/validation'
import { BsTabDefinition } from '../types'
import { updateUrlHash, validStr } from 'booksprout'

@Component
export default class BsTabStepper extends ValidationMixin {
  @Prop({ type: Number }) public readonly value!: number
  @Prop({ type: Array }) public readonly tabs!: BsTabDefinition[]
  @Prop({ type: Array }) public readonly pad!: []
  @Prop({ type: String }) public readonly doneLabel!: string
  @Prop({ type: String }) public readonly returnLabel!: string
  @Prop({ type: [String, Function] }) public readonly returnTo!: string | Function
  @Prop({ type: Boolean }) public readonly hideNext!: boolean
  @Prop({ type: Boolean }) public readonly hideActions!: boolean
  @Prop({ type: Boolean }) public readonly useSlotKey!: boolean
  @Prop({ type: [Function] }) public readonly doBeforeNext!: Function

  public get loadingWidth () {
    const pc = (100 / this.tabs.length) * this.value
    return `${pc}%`
  }

  public get nextStep () {
    return this.value < this.tabs.length ? this.value + 1 : this.tabs.length
  }

  public get previousStep () {
    return this.value > 1 ? this.value - 1 : this.value
  }

  public getSlot (step: BsTabDefinition) {
    return this.useSlotKey ? this.$scopedSlots[step.slotKey] : this.$scopedSlots[this.value]
  }

  public updateUrl (step: number) {
    updateUrlHash(step)
  }

  public previous () {
    if (this.value === this.previousStep) {
      if (this.returnTo !== void 0) {
        if (typeof this.returnTo === 'function') {
          this.returnTo()
        } else {
          this.$router.push(this.returnTo)
        }
      } else {
        this.$emit('return')
      }
    } else {
      this.updateUrl(this.previousStep)
      ;(this.$refs.bsStepperTabPanels as QTabPanels)?.previous()
    }
  }

  public validateForm () {
    return (this.$refs.bsForm as BsForm).validateForm()
  }

  public __submitForm () {
    void this.__next().catch(() => {
      // do nothing
      // promise rejected, for example epub preview generation failed
    })
  }

  public async __next () {
    if (typeof this.doBeforeNext !== 'function' || await this.doBeforeNext()) {
      const finished = this.nextStep === this.value
      if (finished) {
        this.$emit('done')
      } else {
        this.updateUrl(this.nextStep)
        ;(this.$refs.bsStepperTabPanels as QTabPanels)?.next()
      }
    }
  }

  public __renderLoadingBar (h: CreateElement) {
    return h('div', {
      staticClass: 'bs-stepper__loading-bar'
    }, [
      h('div', {
        staticClass: 'bs-stepper__loading-bar--progress',
        style: {
          width: this.loadingWidth
        }
      })
    ])
  }

  public __renderContent (h: CreateElement) {
    const nodes = []
    for (const step of this.tabs) {
      const slot = this.getSlot(step)

      nodes.push(
        h(QTabPanel, {
          staticClass: 'bs-stepper__content',
          props: {
            name: step.value,
            disable: step.disable
          }
        }, [
          h(BsForm, {
            ref: 'bsForm',
            on: {
              submit: () => this.__submitForm()
            }
          }, [
            // @ts-ignore
            typeof slot === 'function' ? slot() : void 0,
            this.__renderActions(h)
          ])
        ])
      )
    }

    return h(QTabPanels, {
      props: {
        value: this.value
      },
      on: {
        input: (v: any) => this.$emit('input', v)
      },
      ref: 'bsStepperTabPanels'
    }, [
      nodes
    ])
  }

  public __renderDefaultActions (h: CreateElement) {
    const
      previousStep = this.previousStep,
      nextStep = this.nextStep,
      // casting below because we know it'll exist.
      currentTab = this.tabs.find(f => f.value === this.value) as BsTabDefinition,
      hiddenCount = this.tabs.filter(f => f.hidden || f.disable).length,
      finished = nextStep === this.value

    if (currentTab === void 0) {
      return void 0
    } else {
      // Default labels
      let previousStepLabel = hiddenCount > 0 ? (previousStep - hiddenCount).toString() : previousStep.toString()
      let nextStepLabel = hiddenCount > 0 ? (nextStep - hiddenCount).toString() : nextStep.toString()

      // Check for overrides
      if (validStr(currentTab.previousLabel)) {
        previousStepLabel = currentTab.previousLabel
      } else { // Prepend step
        previousStepLabel = `Step ${previousStepLabel}`
      }

      // Check for overrides
      if (validStr(currentTab.nextLabel)) {
        nextStepLabel = currentTab.nextLabel
      } else { // Prepend step
        nextStepLabel = `Step ${nextStepLabel}`
      }

      const nodes = []
      const finalPreviousLabel = previousStep === this.value && this.returnLabel !== void 0 ? this.returnLabel : previousStepLabel
      nodes.push(h(BsBtn, {
        staticClass: 'bs-stepper__actions-default--left text-bs-g',
        class: {
          'invisible': previousStep === this.value && this.returnLabel === void 0
        },
        props: {
          flat: true,
          icon: 'app:arrow-left'
        },
        on: {
          // use a custom previous function if available, otherwise go back to previous tab.
          click: () => typeof currentTab.previousAction === 'function' ? currentTab.previousAction() : this.previous()
        },
        attrs: {
          ['data-model']: `stepper_action_${finalPreviousLabel}`
        }
      }, [
        h('span', {
          staticClass: 'on-right',
        }, finalPreviousLabel)
      ]))

      nodes.push(h('div', {
        staticClass: 'bs-stepper__actions-hint'
      }, [
        currentTab.actionHint,
        this.slot(this, 'actionsHint')
      ]))

      if (!this.hideNext && !currentTab.hideNext) {
        // If we have a custom "next" click event, use it, otherwise fallback to type submit.
        const on = typeof currentTab.nextAction === 'function'
          ? {
            click: () => {
              currentTab.nextAction !== void 0 && currentTab.nextAction()
            }
          }
          : void 0

        const finalNextLabel = finished ? (this.doneLabel || 'Finish') : nextStepLabel
        nodes.push(h(BsBtn, {
          staticClass: 'bs-stepper__actions-default--right text-bs-g',
          props: {
            flat: true,
            type: typeof currentTab.nextAction === 'function' ? void 0 : 'submit',
            iconRight: finished ? void 0 : 'app:arrow-right-alt'
          },
          on,
          attrs: {
            ['data-model']: finalNextLabel
          }
        }, [
          h('span', {
            staticClass: 'on-left',
          }, finalNextLabel)
        ]))
      }

      return [
        h('div', {
          staticClass: 'row justify-between items-center'
        }, nodes)
      ]
    }
  }

  public __renderActions (h: CreateElement) {
    const useDefaultActions = this.$scopedSlots.actions === void 0 && this.hideActions === false
    return h('div', {
      staticClass: 'bs-stepper__actions',
      class: [{
        'bs-stepper__actions-default': useDefaultActions
      }]
    }, [
      useDefaultActions ? this.__renderDefaultActions(h) : this.slot(this, 'actions')
    ])
  }

  public render (h: CreateElement) {
    return h('div', {
      staticClass: 'bs-stepper'
    }, [
      this.__renderLoadingBar(h),
      h('div', {
        class: [{
          'q-pa-md': this.pad
        }]
      }, [
        this.slot(this, 'default'),
        this.__renderContent(h)
      ])
    ])
  }
}
