import { Component, Prop } from 'vue-property-decorator'
import { CommonBaseMixin } from './mixins/base'

@Component
export default class NoResults extends CommonBaseMixin {
  @Prop({ type: String }) public readonly icon!: string
  @Prop({ type: String }) public readonly to!: string
  @Prop({ type: String }) public readonly btn!: string
  @Prop({ type: String }) public readonly label!: string
  @Prop({ type: String }) public readonly hint!: string
  @Prop({ type: String }) public readonly model!: string
  @Prop({ type: String }) public readonly searchTerm!: string
  @Prop({ type: Boolean }) public readonly hideNew!: boolean
  @Prop({ type: Boolean }) public readonly alt!: boolean
  @Prop({ type: Boolean }) public readonly noMargin!: boolean

  public get modelFriendlyName () {
    return this.$tc('system.labels.models.' + this.model)
  }

  public get showLabel () {
    return this.label || this.$t('system.labels.noResults.label', [this.modelFriendlyName])
  }

  public get showHint () {
    return this.hint || this.searchTerm
      ? this.$t('system.labels.noResults.hint', [this.modelFriendlyName, this.searchTerm])
      : void 0
  }

  public get showBtn () {
    return this.btn || this.$t('system.labels.noResults.createNew', [this.modelFriendlyName])
  }
}
