import { BaseApolloCrudService } from './apollo/baseApolloCrud.service'
import { BaseModel } from './base.model'
import { StoreService } from './store.service'
import { ConfigService } from './config.service'
import { inject } from 'inversify-props'
import { BaseRootState } from '../../store/baseRootState'
import { AxiosService } from './axios/axios.service'

export abstract class BaseCrudService<T extends BaseModel> extends BaseApolloCrudService<T> {
  public abstract apiControllerPath: string

  protected constructor (
    @inject('ConfigService') protected readonly configService: ConfigService,
    @inject('StoreService') protected readonly storeService: StoreService<BaseRootState>,
    @inject('AxiosService') protected readonly axiosService: AxiosService
  ) {
    super()
  }

  public getApiControllerUrl (path: string) {
    return `${this.configService.config.axios.url}${this.apiControllerPath}/${path}`
  }

  public validateAddPictureFactory (files: any[], model?: T): Promise<boolean> {
    return Promise.resolve(true)
  }

  public async cleanFiles (fileName?: string): Promise<string | undefined> {
    if (!fileName) {
      return void 0
    }

    const res = await this.axiosService.axios.post(this.getApiControllerUrl('clean-files'), {
      fileType: '',
      fileName
    })

    return res.data
  }

  public addPictureFactory (files: any[], model?: T) {
    return this.validateAddPictureFactory(files, model).then((valid: boolean) => {
      if (!valid) {
        return Promise.reject()
      }

      let formFields: { name: string, value: number }[] | undefined = void 0
      if (model) {
        formFields = [
          {
            name: 'id',
            value: model.id === void 0 ? -1 : model.id
          }
        ]
      }

      return {
        url: this.getApiControllerUrl('add-picture'),
        withCredentials: true,
        headers: [
          { name: 'Authorization', value: `Bearer ${this.storeService.store.getters.authToken}` },
          { name: 'CSRF-Token', value: this.storeService.store.getters.csrfToken }
        ],
        formFields
      }
    })
  }
}
