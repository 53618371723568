import { inject } from 'inversify-props'

import { BaseCrudServiceInterface } from '../baseCrud.service.interface'
import { BaseModel } from '../base.model'
import {
  ArcPagedResultDto,
  ArcTeamPagedResultDto,
  BookPagedResultDto,
  CategoryPagedResultDto,
  CreateDtoInterface,
  MessagePostPagedResultDto,
  MessageThreadPagedResultDto,
  NotificationPagedResultDto,
  PenNamePagedResultDto,
  UpdateDtoInterface,
  UserPagedResultDto
} from 'booksprout'
import { ApolloClientServiceInterface } from './apolloClient.service.interface'

export abstract class BaseApolloCrudService<T extends BaseModel> implements BaseCrudServiceInterface<T> {
  /**
   * Our Apollo Client instance.
   * Note: Will (and should) be a singleton.
   */
  @inject('ApolloClientService')
  public readonly apolloClientService!: ApolloClientServiceInterface

  abstract create (model: CreateDtoInterface): Promise<T>

  abstract delete (id: number): Promise<any>

  abstract get (params?: any): Promise<ArcPagedResultDto |
    ArcTeamPagedResultDto |
    BookPagedResultDto |
    CategoryPagedResultDto |
    MessageThreadPagedResultDto |
    MessagePostPagedResultDto |
    NotificationPagedResultDto |
    PenNamePagedResultDto |
    UserPagedResultDto |
    T[]>

  abstract getById (id: number): Promise<T | null>

  abstract update (model: UpdateDtoInterface): Promise<T>

  abstract cleanFiles (fileName?: string): Promise<string | undefined>

  globalLoader (enable: boolean): this {
    this.apolloClientService.showGlobalLoader = enable
    return this
  }
}
