import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import I18nMixin from './i18n'
import { CreateElement } from 'vue'
import * as emailValidator from 'email-validator'

import { slot } from '../utils'
import { QIcon } from 'quasar'

export const enum ComponentState {
  Valid,
  Invalid,
  AutoSaving,
  AutoSaved,
  Resetting
}

// @ts-ignore
@Component
export default class CommonMixin extends I18nMixin {
  @Prop({ type: Function }) public readonly autoSave!: Function
  @Prop({ type: Boolean }) public readonly triggerOnChange!: boolean
  @Prop({ type: Boolean }) public readonly required!: boolean
  @Prop({ type: Boolean }) public readonly validEmail!: boolean
  @Prop({ type: Array }) public readonly validation!: ((val: any) => { ref?: Vue, label: string } | undefined)[]
  @Prop() public readonly validationResultTarget!: any
  @Prop({ type: Boolean }) readonly lazyValidation!: boolean

  private initialValue!: any
  private crossValidatedRef!: BaseBsComponentMixin

  /**
   * The event which is listened for by default for auto save.
   * Can be overridden in consuming components i.e BsSelect
   * protected autoSaveMethod = 'input'
   */
  protected autoSaveMethod: string = 'blur'

  public autoSaveMessage: string = ''
  public errorMessage: string = ''
  public componentState: ComponentState = ComponentState.Valid
  // Make a component always trigger save on change i.e Radio button first click
  public forceTriggerOnChange = false
  // Avoid an auto save when initial value is set on the component (mainly BsRadio)
  public stopSaveOnFirstSet = false
  alreadyChangedValues: {
    [x: number]: {
      from: string | number | boolean;
      to: string | number | boolean;
    };
  }[] = []

  public debug (str: string) {
    if (process.env.NODE_ENV === 'development') {
      // @ts-ignore
      console.log(`[${this._uid}]`, str)
    }
  }

  public get componentValue () {
    // @ts-ignore
    return this.value || this.$attrs.value
  }

  public get hasErrors () {
    return this.componentState === ComponentState.Invalid
  }

  /**
   * Some components will want to trigger the save the moment something changes i.e BsSelect
   * When the trigger-on-change prop is passed then this will watch for changes and save.
   * @param from
   * @param to
   */
  @Watch('componentValue')
  public onComponentValueChanged (to: any, from: any) {
    // this covers duplicate calls to autoSave
    // due to the values being the same
    // no idea how it happens, but seems to only happen with BsRadio component
    // maybe v-model gets updated twice with the same value?
    const alreadyChanged = this.alreadyChangedValues.find((obj) => {
      // @ts-ignore
      return obj[this._uid].from === from && obj[this._uid].to === to
    })

    if (alreadyChanged) {
      this.alreadyChangedValues = []
      return
    }

    // Validate when any changes happen to the data as this will clear the state if another
    // method / field has updated this value.
    if (!this.lazyValidation) {
      this.validate(to)
    }

    if (
      (!this.forceTriggerOnChange && !this.triggerOnChange) || this.autoSave === void 0 ||
      from === to
    ) {
      return
    }

    /**
     * Components like BsRadio will trigger an @input event as soon as a value has been assigned to them.
     * This isn't good as it'll trigger an auto save as soon as that component is assigned a value.
     * We set the stopSaveOnFirstSet = true against that component so that it doesn't do the initial save
     * when the from value === void 0 (no value set already).
     */
    if (!this.stopSaveOnFirstSet || from !== void 0) {
      // @ts-ignore
      if (this._uid) {
        const obj = {
          // @ts-ignore
          [this._uid]: { from: from, to: to }
        }
        this.alreadyChangedValues.push(obj)
      }

      this.doAutoSave()
    }
  }

  @Watch('errorMessage')
  onErrorMessageChanged (to: string) {
    // If the error message is cleared, also clear the content of our validation result target
    if (!to && this.validationResultTarget) {
      this.validationResultTarget.innerText = ''
    }
  }

  public slot (vm: any, slotName: any, otherwise?: any) {
    return slot(vm, slotName, otherwise)
  }

  doBeforeAutoSave (val: any) {
    // do nothing here. Here for when we need to do something in the component on the same event which is auto saving
    // i.e BsChipBox
  }

  public getOnAutoSave () {
    if (!this.autoSaveMethod) {
      return void 0
    }

    // if we have already validated the component
    // don't do anything else (example BsChipBox rules)
    if (this.componentState === ComponentState.Invalid) {
      return void 0
    }

    return {
      [this.autoSaveMethod]: (val: any) => {
        this.doBeforeAutoSave(val)

        // Validate the component.
        // If not valid, emit the autoSaveMethod anyway as other code might need it to bubble.
        this.validate(this.componentValue)

        if (this.componentState === ComponentState.Invalid) {
          this.$emit(this.autoSaveMethod, val)
          return void 0
        }

        this.componentState = ComponentState.AutoSaving

        // Set some defaults
        let componentValue = this.componentValue
        let ignoreCheck = false

        // If a checkbox, override as behaviour is different. Possibility to move these to consuming component.
        // ['BsCheckbox', 'BsChipBox'].includes(this.$options.name + '') doesn't work in production
        // this.$options.name is changed to "re" name and the check fails, which in turn doesn't fire autosave
        if (this.$refs.bsCheckbox || this.$refs.bsChipBox) {
          componentValue = val
          // Checkbox will always have changed as it was clicked.
          ignoreCheck = true
        }

        // Emit the event we've just hijacked so the component works properly.
        // i.e input will stop the v-model bind from working.
        this.$emit(this.autoSaveMethod, val)

        // We don't want to trigger an auto save on this component if nothing is actually happening.
        if (this.initialValue !== componentValue || ignoreCheck === true) {
          // Update the intial value so it can auto save again on next change.
          this.initialValue = componentValue
          // Do auto save.
          this.doAutoSave()
        }
        return
      }
    }
  }

  public __renderAutoSaveMessage (h: CreateElement) {
    return {
      hint: () => {
        return h('span', this.autoSaveMessage)
      }
    }
  }

  public __renderErrorSlot (h: CreateElement) {
    // If we're trying to put the error message in a different place,
    // do it and don't show the error slot on the component.
    if (this.validationResultTarget === void 0) {
      return h('div', {
        staticClass: 'row nowrap bs-par-s-i'
      }, [
        h(QIcon, {
          staticClass: 'on-left',
          props: {
            name: 'app:error'
          }
        }),
        h('span', {
          staticClass: 'col'
        }, this.errorMessage)
      ])
    } else {
      this.validationResultTarget.innerText = this.errorMessage
    }

    return void 0
  }

  public doAutoSave () {
    if (this.autoSave !== void 0 && this.componentState !== ComponentState.Invalid) {
      this.autoSave().then((r: any) => {
        this.errorMessage = ''
        this.autoSaveMessage = this.$tc('system.labels.changesSaved')
        this.componentState = ComponentState.AutoSaved
        setTimeout(() => {
          this.autoSaveMessage = ''
          this.componentState = ComponentState.Valid
        }, 5000)
      }).catch((e: any) => {
        this.errorMessage = e.message
        this.componentState = ComponentState.Invalid
      })
    }
  }

  public getListeners (additional?: any) {
    let listeners = {
      ...this.$listeners,
      ...additional
    }

    // getOnAutoSave will trigger its own validation
    if (this.autoSave === void 0) {
      listeners = {
        ...listeners,
        input: (e: any) => {
          if (!this.lazyValidation) {
            this.validate(e)
          }
          this.$emit('input', e)
        }
      }
    } else {
      listeners = {
        ...listeners,
        ...this.getOnAutoSave()
      }
    }

    return listeners
  }

  public getScopedSlots (h: CreateElement, addSlots?: any) {
    let slots =  {
      ...this.$scopedSlots,
      ...addSlots
    }

    if (this.autoSaveMessage !== '') {
      slots = {
        ...slots,
        ...this.__renderAutoSaveMessage(h)
      }
    }

    if (this.componentState === ComponentState.Invalid) {
      slots = {
        ...slots,
        error: () => this.__renderErrorSlot(h)
      }
    }

    return slots
  }


  public getAttributes (additional?: any, skipDataModel = false) {
    // So our components have some sort of selector (mainly for tests) we attach the prop name of the vmodel as a
    // data-model attribute. This allows quick DOM querying.
    // @ts-ignore
    if (!skipDataModel && this.$vnode.data !== void 0 && this.$vnode.data.model !== void 0) {
      if (additional === void 0) {
        additional = {}
      }
      // @ts-ignore
      additional['data-model'] = this.$vnode.data.model.expression
    }

    return {
      placeholder: this.$attrs.placeholder,
      error: this.errorMessage !== '',
      ...additional
    }
  }

  public getRenderProps (additional?: any)  {
    return this.__getRenderProps(additional)
  }

  protected __getRenderProps (additional?: any) {
    let attrs = this.$attrs
    if (attrs === void 0) {
      attrs = {}
    }

    if (this.tkey !== void 0 && this.tkey !== '') {
      attrs.label = this.$tc(this.tkey)
    }

    return {
      ...this.$vnode.data?.props,
      ...attrs,
      ...this.getValidationProps(additional)
    }
  }

  public getValidationProps (additional?: any) {
    return {
      ...additional,
      color: this.hasErrors ? 'imp-bs-r' : additional?.color || this.$attrs?.color,
      error: this.hasErrors
    }
  }

  public getClasses (additional?: any) {
    return [{
      'bs-auto-saving': this.componentState === ComponentState.AutoSaved,
      ...additional
    }]
  }

  public get isFormValid () {
    const errors = this.validateComponentTree(this.$parent.$children)
    return !errors
  }

  public validateComponentTree (components: any): boolean {
    let isValid = true
    for (const component of components) {
      if (component.$children.length > 0) {
        // NOTE: This must be this way around because if not and isValid === false, it will skip
        // the this.validateComponentTree() call meaning it won't validate all the form components
        // under the form. Took too long to figure this out ...
        isValid = this.validateComponentTree(component.$children) && isValid
      }

      if (component.validate === void 0) {
        continue // If it doesn't have validation function, say it's valid
      }

      const isComponentValid = component.validate(component.$attrs.value || component.$vnode?.data?.model?.value)
      isValid = isValid && isComponentValid
    }

    return isValid
  }

  public isComponentIsValid (val?: any) {
    return val !== null && val !== '' && val !== void 0
  }

  public validate (val?: any) {
    // https://app.shortcut.com/booksprout/story/7858/bs-common-issues-with-login
    if (val && typeof val === 'string') {
      val = val.trim()
    }

    // If we're resetting this component, don't trigger validation
    if (this.componentState === ComponentState.Resetting) {
      this.componentState = ComponentState.Valid
      this.errorMessage = ''
      return true // Return this as valid as the empty state in this instance is valid.
    }

    const isEmpty = this.isComponentIsValid(val) === false

    // Is required and is empty check
    this.componentState = this.required && isEmpty ? ComponentState.Invalid : ComponentState.Valid
    if (this.componentState === ComponentState.Invalid) {
      this.errorMessage = this.$tc('system.labels.requiredField')
      return false
    }

    if (this.validEmail && !emailValidator.validate(val)) {
      this.componentState = ComponentState.Invalid
      this.errorMessage = this.$tc('system.errors.invalidEmail')
      return false
    }

    if (this.validation !== void 0) {
      // Validate any validation rules attached to the component
      for (const validationRule of this.validation) {
        // Pass in the value of our component so it can use that for validation
        const validationResult = validationRule(val || this.componentValue)

        // If it has a value, it means it FAILED validation
        if (validationResult !== void 0) {
          this.errorMessage = validationResult.label
          this.componentState = ComponentState.Invalid

          // If this validation references something else, it means it wants that field flagged as well (i.e Fields must match)
          if (validationResult.ref !== void 0) {
            // Keep track of the reference to the "other" field we're marking as invalid so it can be cleared later when valid
            this.crossValidatedRef = validationResult.ref as BaseBsComponentMixin
            this.crossValidatedRef.componentState = ComponentState.Invalid
            this.crossValidatedRef.errorMessage = validationResult.label
          }
        } else if (this.crossValidatedRef !== void 0) {
          // Clear the validation state against the reference component
          this.crossValidatedRef.componentState = ComponentState.Valid
          this.crossValidatedRef.errorMessage = ''
        }
      }
    }
    return this.componentState === ComponentState.Valid
  }

  public reset () {
    this.componentState = ComponentState.Resetting
    this.$emit('input', this.initialValue)
  }

  public mounted () {
    this.initialValue = this.componentValue
  }

  public updated () {
    // If by this point we have a value but didn't on mounted, that means it took a moment to
    // get the data. Update that now so that we have a value set for the "blur" event so it
    // won't trigger an auto save for a value that hasn't changed.
    if (this.initialValue === void 0 && this.componentValue !== void 0) {
      this.initialValue = this.componentValue
    }
  }
}

export class BaseBsComponentMixin extends CommonMixin {

}
