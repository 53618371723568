import { CreateElement } from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { QBtn, QIcon, QInput } from 'quasar'

// Mixins
import { BaseBsComponentMixin, ComponentState } from '../mixins/common'
import { BsBtn } from '../index'
import { validInt, validStr } from 'booksprout'

@Component
export default class BsInput extends BaseBsComponentMixin {
  @Prop({ type: Boolean }) readonly password!: boolean
  @Prop({ type: Boolean }) readonly search!: boolean
  @Prop({ type: Boolean }) readonly hideOptional!: boolean
  @Prop({ type: Boolean }) readonly small!: boolean
  @Prop({ type: Boolean }) readonly srLabel!: boolean
  @Prop({ type: Boolean }) readonly trimAllSpaces!: boolean

  @Prop({ type: String }) readonly icon!: string | undefined
  @Prop({ type: String }) readonly append!: string

  passwordVisible = false

  get backgroundColor () {
    if (this.hasErrors) {
      return 'imp-bs-r-l'
    }

    return this.$attrs['bg-color']
  }

  get isNumericField () {
    return this.$attrs.type === 'number'
  }

  triggerAutoSave () {
    return this.doAutoSave()
  }

  setValue (v: string | number) {
    // @ts-ignore
    this.value = v
    if (validStr(v + '') || validInt(v)) {
      this.componentState = ComponentState.Valid
    }
  }

  __renderBeforeSlot (h: CreateElement) {
    return h('div', {
      staticClass: 'bs-input__icon'
    }, [
      h(QIcon, {
        props: {
          name: this.icon
        }
      })
    ])
  }

  __renderNumberSlots (h: CreateElement) {
    const rootValue = this.componentValue
    const value = parseInt(rootValue)
    const valueIsMin = value === parseInt(this.$attrs.min)
    const valueIsMax = value === parseInt(this.$attrs.max)

    return {
      before: () => {
        return h(BsBtn, {
          props: {
            icon: 'app:minus',
            iconOnly: true,
            primary: valueIsMin,
            secondary: !valueIsMin,
            disable: valueIsMin
          },
          on: {
            click: () => {
              const value = isNaN(parseInt(rootValue)) ? 0 : parseInt(rootValue) - 1
              if (this.$attrs.min !== void 0 && value < parseInt(this.$attrs.min)) {
                return
              }

              this.validate(value)
              this.$emit('input', value) // Still allow them to change the value as they need to go down / up
              this.doAutoSave()
            }
          },
          attrs: {
            ['data-cy']: 'decreaseValue'
          }
        })
      },
      after: () => {
        return h(BsBtn, {
          props: {
            icon: 'app:plus',
            iconOnly: true,
            primary: valueIsMax,
            secondary: !valueIsMax,
            disable: valueIsMax
          },
          on: {
            click: () => {
              const value = isNaN(parseInt(rootValue)) ? 1 : parseInt(rootValue) + 1
              if (this.$attrs.max !== void 0 && value > parseInt(this.$attrs.max)) {
                return
              }

              this.validate(value)
              this.$emit('input', value) // Still allow them to change the value as they need to go down / up
              this.doAutoSave()
            }
          },
          attrs: {
            ['data-cy']: 'increaseValue'
          }
        })
      }
    }
  }

  __renderSearchSlot (h: CreateElement) {
    return {
      append: () => {
        return h(QBtn, {
          staticClass: 'bs-search-btn',
          class: {'bs-search-btn__focused': this.$attrs.value},
          on: {
            // @ts-ignore
            click: () => this.$emit('search', this.value)
          },
          scopedSlots: {
            default: () => h(QIcon, {
              props: {
                name: 'app:magnifier-dense',
                color: this.$attrs.value ? 'white' : 'bs-grey-m',
                size: '.75rem'
              }
            })
          }
        })
      }
    }
  }

  __renderScopedSlots (h: CreateElement) {
    let slots = {}

    if (this.password) {
      slots = {
        ...slots,
        append: () => h(QIcon, {
          staticClass: 'cursor-pointer',
          props: {
            name: this.passwordVisible ? 'app:password-visible' : 'app:password-hidden'
          },
          on: {
            click: () => this.passwordVisible = !this.passwordVisible
          }
        })
      }
    }

    if (this.append) {
      slots = {
        ...slots,
        append: () => h('span', {
          staticClass: 'font-size-13 text-primary'
        }, this.append)
      }
    }

    if (this.icon) {
      slots = {
        ...slots,
        before: () => this.__renderBeforeSlot(h)
      }
    }

    if (this.isNumericField) {
      slots = {
        ...slots,
        ...this.__renderNumberSlots(h)
      }
    }

    if (this.search) {
      slots = {
        ...slots,
        ...this.__renderSearchSlot(h)
      }
    }

    return slots
  }

  __renderLabelSlot (h: CreateElement) {
    const label = this.$attrs.label

    if (label === void 0 && this.$scopedSlots.actions === void 0) {
      return void 0
    }

    return h('div', {
      staticClass: 'bs-input-container__label flex justify-between items-center',
      class: {
        'sr-only': this.srLabel
      },
      attrs: {
        ['aria-hidden']: true // just needed visually
      }
    }, [
      h('div', {
        staticClass: 'cursor-pointer',
        on: {
          click: this.focus
        }
      }, [
        label,
        this.required || this.hideOptional
          ? void 0
          : h('span', {
            staticClass: 'bs-input__optional'
          }, ' (optional)')
      ]),
      this.$scopedSlots.actions && h('div', this.slot(this, 'actions'))
    ])
  }

  focus () {
    ;(this.$refs.qInput as QInput).focus()
  }

  resetValidation () {
    (this.$refs.qInput as QInput).resetValidation()
    this.reset()
  }

  ensureValidIntValue (val: string) {
    const intVal = parseInt(val)
    const min = parseInt(this.$attrs.min)
    const max = parseInt(this.$attrs.max)

    if (
      this.isNumericField && // Only run check if we're on a numeric type field
      val !== '' && // Allow the user to clear the field so they can type
      (
        !validInt(val) || // Not an int
        intVal < min || // OR the entered value is less than min ...
        intVal > max // OR the entered value is more than max
      )
    ) {
      if (intVal < min) {
        this.$emit('input', parseInt(this.$attrs.min))
      } else {
        this.$emit('input', parseInt(this.$attrs.max))
      }
    }
  }

  render (h: CreateElement) {
    if (this.isNumericField) {
      this.ensureValidIntValue(this.componentValue)
    }

    const value = this.componentValue
    const props = this.getRenderProps({
      stackLabel: true,
      value,
      bottomSlots: false,
      noErrorIcon: true,
      outlined: this.hasErrors,
      bgColor: this.backgroundColor,
      type: this.password ? this.passwordVisible ? 'text' : 'password' : this.$attrs.type || 'text',
      clearable: this.search,
      clearIcon: 'app:close-fat',
      readonly: this.$attrs.readonly
    })

    // We handle the label ourselves by putting it above the input.
    // Remove the prop
    delete props.label

    const scopedSlots = {
      ...this.__renderScopedSlots(h)
    }

    return h('div', {
      staticClass: 'bs-input-container',
      attrs: {
        // @ts-ignore
        id: this.$vnode?.data?.model?.expression
      }
    }, [
      this.__renderLabelSlot(h),
      h(QInput, {
        ref: 'qInput',
        staticClass: this.$attrs.type === 'textarea' ? 'bs-textarea' : 'bs-input',
        class: this.getClasses({
          'bs-input__search': this.search,
          'bs-input__search--not-empty': this.search && this.$attrs.value,
          'bs-input__icon': this.icon,
          'bs-input__small': this.small,
          'bs-input__number': this.isNumericField
        }),
        props,
        on: this.getListeners({
          blur: () => {
            this.validate(this.$attrs.value)
            this.$emit('blur')
          },
          input: (val: any) => {
            if (this.trimAllSpaces) {
              val = val.replace(' ', '')
            }
            this.$emit('input', this.isNumericField ? parseInt(val) : val)
          },
          keyup: (e: any) => {
            const val = e.target.value
            this.ensureValidIntValue(val)
          },
          clear: () => {
            this.$emit('clear', this.isNumericField ? parseInt(this.$attrs.min) : '')
          }
        }),
        attrs: this.getAttributes({
          ['data-cy']: this.$attrs['data-cy'],
          ['data-hj-allow']: this.$attrs['data-hj-allow'],
          inputmode: this.isNumericField ? 'numeric' : this.$attrs.inputmode,
          pattern: this.isNumericField ? '[0-9]+' : this.$attrs.pattern
        }),
        scopedSlots: this.getScopedSlots(h, scopedSlots)
      }, [
        this.slot(this, 'default')
      ])
    ])
  }
}
