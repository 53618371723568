import { CreateElement } from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { QBtn, QIcon } from 'quasar'

// Mixins
import ValidationMixin from '../mixins/validation'

@Component
export default class BsBtn extends ValidationMixin {
  @Prop({ type: Boolean }) readonly secondary!: boolean
  @Prop({ type: Boolean }) readonly warning!: boolean
  @Prop({ type: Boolean }) readonly active!: boolean | undefined
  @Prop({ type: Boolean }) readonly disable!: boolean
  @Prop({ type: Boolean }) readonly large!: boolean
  @Prop({ type: Boolean }) readonly asLink!: boolean
  @Prop({ type: Boolean }) readonly flat!: boolean
  @Prop({ type: Boolean }) readonly iconOnly!: boolean

  clickCounter = 0

  get isDisabled () {
    return this.$vnode.data?.attrs?.type === 'submit' && !this.isFormValid
  }

  __renderActiveIcon (h: CreateElement) {
    if (this.active === false) return void 0

    return h(QIcon, {
      props: {
        name: 'icon-check'
      },
      staticClass: 'bs-btn-active__icon'
    })
  }

  getBtnProps () {
    return this.getRenderProps({
      disable: this.disable,
      outline: false
    })
  }

  getBtnClasses () {
    return this.getClasses({
      'bs-btn--active': this.active,
      'bs-btn--secondary': this.secondary,
      'bs-btn--disabled': this.disable,
      'bs-btn--warning': this.warning,
      'bs-btn--large': this.large,
      'bs-btn--as-link': this.asLink,
      'bs-btn--flat': this.flat,
      'bs-btn--icon-only': this.iconOnly
    })
  }

  clickHandler () {
    return {
      'click': (v: any) => {
        this.clickCounter += 1

        if (this.clickCounter === 1) {
          this.$emit('click', v)
        }

        setTimeout(() => {
          this.clickCounter = 0
        }, 500)
      }
    }
  }

  render (h: CreateElement) {
    return h(QBtn, {
      staticClass: 'bs-btn',
      class: this.getBtnClasses(),
      props: this.getBtnProps(),
      on: this.getListeners(this.clickHandler()),
      attrs: this.getAttributes({
        ...this.$attrs,
        ripple: !this.iconOnly && !this.asLink
      })
    }, [
      this.__renderActiveIcon(h),
      this.slot(this, 'default')
    ])
  }
}
