import { gql } from '@apollo/client/core'
import { AxiosService, BaseCrudService, ConfigService, inject, StoreService } from 'booksprout-app'
import { BookServiceInterface } from './book.service.interface'
import {
  ArcConvertedFileDto,
  ArcConvertFileDto,
  ArcSite,
  BookDto,
  BookGetArgs, BookGetByIdArgs,
  BookGetTagsArgs,
  BookPagedResultDto,
  BookTagDto, bsConstants,
  CreateBookDto, EntityDeletionSummaryDto, EpubPreviewDto, GenerateEpubPreviewDto, HasIndex,
  UpdateBookDto,
  validStr
} from 'booksprout'

export class BookService extends BaseCrudService<BookDto> implements BookServiceInterface<BookDto> {
  public apiControllerPath = 'book'

  public constructor (
    @inject('ConfigService') configService: ConfigService,
    @inject('StoreService') storeService: StoreService,
    @inject('AxiosService') readonly axiosService: AxiosService
  ) {
    super(configService, storeService, axiosService)
  }

  public readonly bookFragments = {
    book: gql`
      fragment BookFragment on BookDto {
        id,
        title,
        description,
        penName,
        authorPenNameId,
        authorPenName {
          id,
          name,
          image,
          averageRating,
          followers {
            id
          }
        },
        penNameType,
        bookCover,
        releaseDate,
        seriesName,
        seriesNumber,
        categoryId,
        keywords,
        wordCount,
        audience,
        linkAmazonAsin,
        linkAmazonAsinPrint,
        linkAudibleUs,
        linkAudibleUk,
        linkBarnes,
        linkBookbub,
        linkGoodreads,
        linkGoogle,
        linkITunes,
        linkKobo,
        linkSmashwords,
        smashwordsCouponCode,
        linkITunesAudio,
        linkBarnesAudio,
        linkGoogleAudio,
        linkGoodreadsAudio,
        linkKoboAudio,
        linkBookbubAudio,
        isDraft,
        averageRating,
        reviewCount,
        lastArcOn,
        newReviewCount,
        updatedReviewCount,
        importedId,
        pdfFile,
        pdfFileSizeBytes,
        pdfSystemGenerated,
        pdfFileDate,
        pdfFileFriendlyName,
        epubFile,
        epubFileSizeBytes,
        epubSystemGenerated,
        epubPreviewFile,
        epubFileDate,
        epubFileFriendlyName
      }
    `
  }

  private baseBookVars (model: CreateBookDto | UpdateBookDto) {
    return {
      title: model.title,
      description: model.description,
      penName: model.penName,
      authorPenNameId: model.authorPenNameId,
      penNameType: model.penNameType,
      bookCover: model.bookCover,
      releaseDate: model.releaseDate,
      seriesName: model.seriesName,
      seriesNumber: model.seriesNumber,
      categoryId: model.categoryId,
      keywords: model.keywords,
      wordCount: model.wordCount,
      audience: model.audience,
      linkAmazonAsin: model.linkAmazonAsin,
      linkAmazonAsinPrint: model.linkAmazonAsinPrint,
      linkGoodreads: model.linkGoodreads,
      linkBookbub: model.linkBookbub,
      linkBarnes: model.linkBarnes,
      linkGoogle: model.linkGoogle,
      linkKobo: model.linkKobo,
      linkSmashwords: model.linkSmashwords,
      smashwordsCouponCode: model.smashwordsCouponCode,
      linkITunes: model.linkITunes,
      linkAudibleUs: model.linkAudibleUs,
      linkAudibleUk: model.linkAudibleUk,
      linkITunesAudio: model.linkITunesAudio,
      linkBarnesAudio: model.linkBarnesAudio,
      linkGoogleAudio: model.linkGoogleAudio,
      linkGoodreadsAudio: model.linkGoodreadsAudio,
      linkKoboAudio: model.linkKoboAudio,
      linkBookbubAudio: model.linkBookbubAudio,
      pdfFile: model.pdfFile,
      pdfFileSizeBytes: model.pdfFileSizeBytes,
      pdfSystemGenerated: model.pdfSystemGenerated,
      pdfFileDate: model.pdfFileDate,
      pdfFileFriendlyName: model.pdfFileFriendlyName,
      epubFile: model.epubFile,
      epubFileSizeBytes: model.epubFileSizeBytes,
      epubSystemGenerated: model.epubSystemGenerated,
      epubPreviewFile: model.epubPreviewFile,
      epubFileDate: model.epubFileDate,
      epubFileFriendlyName: model.epubFileFriendlyName
    }
  }

  private getBookResult (book: BookDto): BookDto | null {
    if (book === null || book === void 0) return null

    const result = {
      ...book,
      authorPenNameId: book.authorPenName?.id,
      releaseDate: book.releaseDate ? new Date(book.releaseDate) : void 0,
      pdfFileDate: book.pdfFileDate ? new Date(book.pdfFileDate) : void 0,
      epubFileDate: book.epubFileDate ? new Date(book.epubFileDate) : void 0,
    }

    return result
  }

  public get (params?: BookGetArgs): Promise<BookPagedResultDto> {
    return this.apolloClientService.query({
      variables: {
        ...params
      },
      query: gql`
        query GetBooks (
          $needle: String,
          $skip: Int,
          $take: Int,
          $sortBy: String,
          $descending: Boolean,
          $isDraft: Boolean,
          $allowImportedDrafts: Boolean,
          $hasReviews: Boolean
          $missingPreview: Boolean
        ) {
          books (
            needle: $needle,
            skip: $skip,
            take: $take,
            sortBy: $sortBy,
            descending: $descending,
            isDraft: $isDraft,
            allowImportedDrafts: $allowImportedDrafts,
            hasReviews: $hasReviews
            missingPreview: $missingPreview
          ) {
            totalRows,
            items {
              ...BookFragment
            }
          }
        }
        ${this.bookFragments.book}
      `
    })
  }

  public getById (id?: number, args?: BookGetByIdArgs): Promise<BookDto | null> {
    if (id === void 0) {
      throw new Error('Missing id.')
    }

    return this.apolloClientService.query({
      variables: {
        id,
        ...args
      },
      query: gql`
        query GetBook (
          $id: Int!,
          $genericSearch: Boolean
        ) {
          book (id: $id, genericSearch: $genericSearch) {
            ...BookFragment
          }
        }
        ${this.bookFragments.book}
      `
    }).then((book: any) => {
      return this.getBookResult(book)
    })
  }

  public create (dto: CreateBookDto): Promise<BookDto> {
    return this.apolloClientService.mutate({
      variables: {
        ...this.baseBookVars(dto)
      },
      mutation: gql`
        mutation CreateBook (
          $title: String!,
          $description: String,
          $penName: String,
          $authorPenNameId: Int,
          $penNameType: Int,
          $bookCover: String,
          $releaseDate: DateTime,
          $seriesName: String,
          $seriesNumber: Int,
          $categoryId: Int,
          $keywords: String,
          $wordCount: Int,
          $audience: Int,
          $linkAmazonAsin: String,
          $linkAmazonAsinPrint: String,
          $linkGoodreads: String,
          $linkBookbub: String,
          $linkBarnes: String,
          $linkGoogle: String,
          $linkKobo: String,
          $linkSmashwords: String,
          $smashwordsCouponCode: String,
          $linkITunes: String,
          $linkAudibleUs: String,
          $linkAudibleUk: String,
          $linkITunesAudio: String,
          $linkBarnesAudio: String,
          $linkGoogleAudio: String,
          $linkGoodreadsAudio: String,
          $linkKoboAudio: String,
          $linkBookbubAudio: String,
          $pdfFile: String,
          $pdfFileSizeBytes: Int,
          $pdfSystemGenerated: Boolean,
          $pdfFileFriendlyName: String,
          $epubFile: String,
          $epubFileSizeBytes: Int,
          $epubPreviewFile: String,
          $epubSystemGenerated: Boolean
          $epubFileFriendlyName: String
        ) {
          createBook (
            title: $title,
            description: $description,
            penName: $penName,
            authorPenNameId: $authorPenNameId,
            penNameType: $penNameType,
            bookCover: $bookCover,
            releaseDate: $releaseDate,
            seriesName: $seriesName,
            seriesNumber: $seriesNumber,
            categoryId: $categoryId,
            keywords: $keywords,
            wordCount: $wordCount,
            audience: $audience,
            linkAmazonAsin: $linkAmazonAsin,
            linkAmazonAsinPrint: $linkAmazonAsinPrint,
            linkGoodreads: $linkGoodreads,
            linkBookbub: $linkBookbub,
            linkBarnes: $linkBarnes,
            linkGoogle: $linkGoogle,
            linkKobo: $linkKobo,
            linkSmashwords: $linkSmashwords,
            smashwordsCouponCode: $smashwordsCouponCode,
            linkITunes: $linkITunes,
            linkAudibleUs: $linkAudibleUs,
            linkAudibleUk: $linkAudibleUk,
            linkITunesAudio: $linkITunesAudio,
            linkBarnesAudio: $linkBarnesAudio,
            linkGoogleAudio: $linkGoogleAudio,
            linkGoodreadsAudio: $linkGoodreadsAudio,
            linkKoboAudio: $linkKoboAudio,
            linkBookbubAudio: $linkBookbubAudio,
            pdfFile: $pdfFile,
            pdfFileSizeBytes: $pdfFileSizeBytes,
            pdfSystemGenerated: $pdfSystemGenerated,
            pdfFileFriendlyName: $pdfFileFriendlyName,
            epubFile: $epubFile,
            epubFileSizeBytes: $epubFileSizeBytes,
            epubPreviewFile: $epubPreviewFile,
            epubSystemGenerated: $epubSystemGenerated
            epubFileFriendlyName: $epubFileFriendlyName
          ) {
            ...BookFragment
          }
        }
        ${this.bookFragments.book}
      `
    }).then((book: any) => this.getBookResult(book) || new BookDto()) // TODO: Why return new one if not updated?
  }

  public update (model: UpdateBookDto): Promise<BookDto> {
    return this.apolloClientService.mutate({
      variables: {
        ...this.baseBookVars(model),
        id: model.id
      },
      mutation: gql`
        mutation UpdateBook (
          $id: Int!,
          $title: String,
          $description: String,
          $penName: String,
          $authorPenNameId: Int,
          $penNameType: Int
          $bookCover: String,
          $releaseDate: DateTime,
          $seriesName: String,
          $seriesNumber: Int,
          $categoryId: Int,
          $keywords: String,
          $wordCount: Int,
          $audience: Int,
          $linkAmazonAsin: String,
          $linkAmazonAsinPrint: String,
          $linkGoodreads: String,
          $linkBookbub: String,
          $linkBarnes: String,
          $linkGoogle: String,
          $linkKobo: String,
          $linkSmashwords: String,
          $smashwordsCouponCode: String,
          $linkITunes: String,
          $linkAudibleUs: String,
          $linkAudibleUk: String,
          $linkITunesAudio: String,
          $linkBarnesAudio: String,
          $linkGoogleAudio: String,
          $linkGoodreadsAudio: String,
          $linkKoboAudio: String,
          $linkBookbubAudio: String,
          $pdfFile: String,
          $pdfFileSizeBytes: Int,
          $pdfSystemGenerated: Boolean,
          $pdfFileDate: DateTime,
          $pdfFileFriendlyName: String,
          $epubFile: String,
          $epubFileSizeBytes: Int,
          $epubPreviewFile: String,
          $epubSystemGenerated: Boolean,
          $epubFileDate: DateTime
          $epubFileFriendlyName: String
        ) {
          updateBook (
            id: $id,
            title: $title,
            description: $description,
            penName: $penName,
            authorPenNameId: $authorPenNameId,
            penNameType: $penNameType,
            bookCover: $bookCover,
            releaseDate: $releaseDate,
            seriesName: $seriesName,
            seriesNumber: $seriesNumber,
            categoryId: $categoryId,
            keywords: $keywords,
            wordCount: $wordCount,
            audience: $audience,
            linkAmazonAsin: $linkAmazonAsin,
            linkAmazonAsinPrint: $linkAmazonAsinPrint,
            linkGoodreads: $linkGoodreads,
            linkBookbub: $linkBookbub,
            linkBarnes: $linkBarnes,
            linkGoogle: $linkGoogle,
            linkKobo: $linkKobo,
            linkSmashwords: $linkSmashwords,
            smashwordsCouponCode: $smashwordsCouponCode,
            linkITunes: $linkITunes,
            linkAudibleUs: $linkAudibleUs,
            linkAudibleUk: $linkAudibleUk,
            linkITunesAudio: $linkITunesAudio,
            linkBarnesAudio: $linkBarnesAudio,
            linkGoogleAudio: $linkGoogleAudio,
            linkGoodreadsAudio: $linkGoodreadsAudio,
            linkKoboAudio: $linkKoboAudio,
            linkBookbubAudio: $linkBookbubAudio,
            pdfFile: $pdfFile,
            pdfFileSizeBytes: $pdfFileSizeBytes,
            pdfSystemGenerated: $pdfSystemGenerated,
            pdfFileDate: $pdfFileDate,
            pdfFileFriendlyName: $pdfFileFriendlyName,
            epubFile: $epubFile,
            epubFileSizeBytes: $epubFileSizeBytes,
            epubPreviewFile: $epubPreviewFile,
            epubSystemGenerated: $epubSystemGenerated,
            epubFileDate: $epubFileDate
            epubFileFriendlyName: $epubFileFriendlyName
          ) {
            ...BookFragment
          }
        }
        ${this.bookFragments.book}
      `
    }).then((book: any) => this.getBookResult(book) || new BookDto()) // TODO: Why return new one if not updated?
  }

  public checkDelete (id: number): Promise<EntityDeletionSummaryDto[]> {
    return this.apolloClientService.query({
      variables: {
        id
      },
      query: gql`
        query CheckDeleteBook ($id: Int!) {
          checkDeleteBook (id: $id) {
            count,
            dtoTypeName,
            ids
          }
        }
      `
    })
  }

  public delete (id?: number): Promise<any> {
    if (id === void 0) {
      throw new Error('Missing id.')
    }

    return this.apolloClientService.mutate({
      variables: {
        id
      },
      mutation: gql`
        mutation ConfirmDeleteBook (
          $id: Int!
        ) {
          confirmDeleteBook(id: $id) {
            id
          }
        }
      `
    })
  }

  public getImportOptions (penName: string, needle: string): Promise<BookDto[]> {
    return this.axiosService.axios.post('book/amazon-books-to-import', {
      penName,
      needle
    }).then((res: any) => {
      return res.data
    })
  }

  public getBooksGeneric (args: BookGetArgs): Promise<BookPagedResultDto> {
    return this.apolloClientService.query({
      variables: {
        ...args
      },
      query: gql`
        query getBooksGeneric (
          $needle: String,
          $skip: Int,
          $take: Int,
          $sortBy: String,
          $descending: Boolean,
          $penNameId: Int,
          $seriesName: String,
          $beforeSeriesNumber: Int
        ) {
          booksGeneric (
            needle: $needle,
            skip: $skip,
            take: $take,
            sortBy: $sortBy,
            descending: $descending,
            penNameId: $penNameId,
            seriesName: $seriesName,
            beforeSeriesNumber: $beforeSeriesNumber
          ) {
            totalRows,
            items {
              ...BookFragment
            }
          }
        }
        ${this.bookFragments.book}
      `
    })
  }

  public getTags (args: BookGetTagsArgs): Promise<BookTagDto[]> {
    return this.apolloClientService.query({
      variables: {
        ...args
      },
      query: gql`
        query GetBookTagsForCategory (
          $categoryId: Int,
          $searchValue: String,
          $skip: Int,
          $take: Int
        ) {
          bookTagsForCategory (
            searchValue: $searchValue,
            skip: $skip,
            take: $take,
            categoryId: $categoryId
          ) {
            name,
            count
          }
        }
      `
    })
  }

  splitKeywords (book: BookDto | undefined): string[] {
    return (book?.keywords?.split(',')) || []
  }

  getAffiliateLink (book: BookDto, site: ArcSite): string {
    const { ...sites } = bsConstants.ARCS.REVIEWS.FLAGS
    switch (site.site) {
      case sites.AMAZON: return `https://www.amazon.com/dp/${book.linkAmazonAsin}?tag=booksprout09-20`
      case sites.ITUNES: return book.linkITunes + '?at=1001l9EK'
      default: return (book as HasIndex)[site.fieldName]
    }
  }

  validateAddPictureFactory (files: any[], model?: BookDto): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(files[0])
      reader.onload = function (e: any) {
        const image = new Image()
        image.src = e.target.result
        image.onload = function () {
          const height = image.height
          if (height < 415) {
            return reject('invalidBookCoverSize')
          }
          resolve(true)
        }
      }
    })
  }

  public convertFile (book: BookDto, fileExt: string): Promise<ArcConvertedFileDto> {
    let fromPath: string | undefined = ''
    if (validStr(book.epubFile)) {
      fromPath = book.epubFile
    } else if (validStr(book.pdfFile)) {
      fromPath = book.pdfFile
    }

    if (fromPath !== void 0) {
      const params: ArcConvertFileDto = {
        id: book.id.toString() || '-1',
        convertIntoFileExt: fileExt.startsWith('.') ? fileExt : '.' + fileExt,
        sourceFilePath: fromPath
      }

      return this.axiosService.axios.post('book/generate-file', params, {
        timeout: 300000
      }).then((r: { data: ArcConvertedFileDto }) => {
        return r.data
      })
    } else {
      return Promise.reject()
    }
  }

  public addFileFactory (files: any[], model: BookDto): object {
    return {
      url: this.getApiControllerUrl('add-file'),
      withCredentials: true,
      headers: [
        { name: 'Authorization', value: `Bearer ${this.storeService.store.getters.authToken}` },
        { name: 'CSRF-Token', value: this.storeService.store.getters.csrfToken }
      ],
      formFields: [
        // AddFileDto shape
        { name: 'id', value: model.id === void 0 ? -1 : model.id },
        { name: 'fileName', value: files[0].name }
      ]
    }
  }

  public generateEpubPreview (dto: GenerateEpubPreviewDto): Promise<EpubPreviewDto> {
    return this.axiosService.axios.post('book/generate-epub-preview', dto, {
      timeout: 300000
    }).then((r: { data: EpubPreviewDto }) => {
      return r.data
    })
  }

  public removeRemoteFile(id: number, fileExt: string): Promise<any> {
    return this.axiosService.axios.post('book/remove-remote-file', {
      id,
      fileExt
    })
  }

  public stopEpubPreviewGeneration (bookId: number): Promise<any> {
    return this.axiosService.axios.post('book/stop-generate-epub-preview', {
      bookId
    })
  }
}
