import { Component } from 'vue-property-decorator'
import { UnreadCountDto, UserNotificationDto, UserNotificationPagedResultDto } from 'booksprout'
import { CommonBaseListModuleMixin, inject, SET_UNREAD_NOTIFICATION_COUNT, ValueLabel } from 'booksprout-app'
import{ NotificationServiceInterface } from '../notification.service.interface'

interface GroupedNotification {
  userNotification: UserNotificationDto,
  groupCount: number,
  groupedItems: UserNotificationDto[]
}

// @ts-ignore
@Component
export abstract class BaseNotificationMixin extends CommonBaseListModuleMixin<UserNotificationDto> {
  @inject('NotificationService')
  crudService!: NotificationServiceInterface<UserNotificationDto>
  list: UserNotificationDto[] = []
  urlModelName = 'notification'
  expanded = false
  expandId = -1
  filterType = 'All'
  filters: ValueLabel[] = []
  selectedFilters: string[] = []
  unreadNotificationCount = 0

  abstract get allFilters (): ValueLabel[]
  abstract showReview (claimId: number | undefined): void

  get groupedList (): GroupedNotification[] {
    const notificationsToGroup = [
      this.MODULE_CONSTANTS.NOTIFICATIONS.NOTIFIERS.NEW_ARC_PARTICIPANT.CODE,
      this.MODULE_CONSTANTS.NOTIFICATIONS.NOTIFIERS.TIME_TO_PUBLISH.CODE,
      this.MODULE_CONSTANTS.NOTIFICATIONS.NOTIFIERS.NEW_TEAM_APPLICANT.CODE
    ]

    const result: GroupedNotification[] = []
    if (this.list?.length > 0) {
      for (const n of this.list.sort((a, b) => a.statusDate.valueOf() - b.statusDate.valueOf())) {
        let addNew = true
        // Are we grouping? If so, try and find an existing one and just update the count
        if (notificationsToGroup.includes(n.notification.code)) {
          const existing = result.find(f => {
            return f.userNotification.notification.code === n.notification.code &&
              (
                f.userNotification.book?.id === n.book?.id &&
                f.userNotification.arcTeamMember?.arcTeam?.id === n.arcTeamMember?.arcTeam?.id
              )
          })
          if (existing) {
            existing.groupCount++
            addNew = false
            existing.groupedItems.push(n)
          }
        }

        // No existing one or not grouping - add new one.
        if (addNew) {
          result.push({
            userNotification: n,
            groupCount: 1,
            groupedItems: []
          })
        }
      }
    }
    return result
  }

  getIconAndColor (n: UserNotificationDto) {
    switch (n.notification.code) {
      case this.MODULE_CONSTANTS.NOTIFICATIONS.NOTIFIERS.ARC_CANCELLATION.CODE:
        return {
          icon: 'app:cancelled-review',
          color: 'bs-r'
        }
      case this.MODULE_CONSTANTS.NOTIFICATIONS.NOTIFIERS.ARC_CANNOT_ACTIVATE.CODE:
        return {
          icon: 'app:exclamation',
          color: 'bs-m-y'
        }
      case this.MODULE_CONSTANTS.NOTIFICATIONS.NOTIFIERS.TIME_TO_PUBLISH.CODE:
        return {
          icon: 'app:time-to-publish',
          color: 'bs-g'
        }
      case this.MODULE_CONSTANTS.NOTIFICATIONS.NOTIFIERS.NEW_REVIEW.CODE:
        return {
          icon: 'app:new-review',
          color: 'bs-m-y'
        }
      case this.MODULE_CONSTANTS.NOTIFICATIONS.NOTIFIERS.NEW_ARC_PARTICIPANT.CODE:
        return {
          icon: 'app:new-arc-participant',
          color: 'bs-nb-m'
        }
      case this.MODULE_CONSTANTS.NOTIFICATIONS.NOTIFIERS.REVIEW_REJECTED.CODE:
        return {
          icon: 'app:rejected-review',
          color: 'bs-r'
        }
      case this.MODULE_CONSTANTS.NOTIFICATIONS.NOTIFIERS.NEW_EXTERNAL_REVIEW.CODE:
        return {
          icon: 'app:external-review',
          color: 'bs-g'
        }
      case this.MODULE_CONSTANTS.NOTIFICATIONS.NOTIFIERS.INVITED_TO_TEAM.CODE:
        return {
          icon: 'app:open-mail',
          color: 'bs-l-b'
        }
      case this.MODULE_CONSTANTS.NOTIFICATIONS.NOTIFIERS.NEW_TEAM_APPLICANT.CODE:
        return {
          icon: 'app:your-teams',
          color: 'bs-m-y'
        }
      case this.MODULE_CONSTANTS.NOTIFICATIONS.NOTIFIERS.FREE_TEXT.CODE:
        return n.parsedDescription.toLowerCase().includes('deleted') || n.parsedDescription.toLowerCase().includes('ended')
          ? {
            icon: 'app:bin',
            color: 'bs-r'
          }
          : {
            icon: 'app:books',
            color: 'bs-nb-m'
          }
      default:
        return {
          icon: 'app:books',
          color: 'bs-nb-m'
        }
    }
  }

  getMessage (n: UserNotificationDto) {
    if (n.notification.code === this.MODULE_CONSTANTS.NOTIFICATIONS.NOTIFIERS.INVITED_TO_TEAM.CODE) {
      // removing team name from the message as we add it in HTML at the end.
      return n.parsedMessage.replace(n.arcTeamMember?.arcTeam?.name + '', '')
    } else {
      // removing book title from the message as we add it in HTML at the end.
      return n.parsedMessage.replace(n.book?.title + '', '')
    }
  }

  markAsRead (n: UserNotificationDto) {
    void this.crudService.markAsRead(n.id)
    const dto: UnreadCountDto = {
      author: this.$store.getters.getUnreadNotificationCount.author,
      reviewer: this.$store.getters.getUnreadNotificationCount.reviewer
    }

    if (this.isReviewerApp) {
      dto.reviewer = dto.reviewer - 1
    } else {
      dto.author = dto.author - 1
    }

    void this.$store.dispatch(SET_UNREAD_NOTIFICATION_COUNT, dto)
  }

  async navigateToNotification (n: UserNotificationDto, group: GroupedNotification) {
    // Mark the parent notification as read
    await this.markAsRead(n)
    // Now mark all its children as read as they're grouped - they're all part of the same thing and navigate to the same thing.
    for (const groupedItem of group.groupedItems) {
      await this.markAsRead(groupedItem)
    }

    if (n.notification.code === this.MODULE_CONSTANTS.NOTIFICATIONS.NOTIFIERS.FREE_TEXT.CODE) {
      return
    }

    let url = ''
    switch (n.notification.code) {
      case this.MODULE_CONSTANTS.NOTIFICATIONS.NOTIFIERS.ARC_CANCELLATION.CODE:
        this.showReview(n.arcClaim?.id)
        break
      case this.MODULE_CONSTANTS.NOTIFICATIONS.NOTIFIERS.TIME_TO_PUBLISH.CODE:
      case this.MODULE_CONSTANTS.NOTIFICATIONS.NOTIFIERS.ARC_CANNOT_ACTIVATE.CODE:
        url = this.linkService.adminView('review-campaign', n.arc?.id)
        break
      case this.MODULE_CONSTANTS.NOTIFICATIONS.NOTIFIERS.NEW_REVIEW.CODE:
        this.showReview(n.arcReview?.claim?.id)
        break
      case this.MODULE_CONSTANTS.NOTIFICATIONS.NOTIFIERS.NEW_ARC_PARTICIPANT.CODE:
        url = this.linkService.adminView('review-campaign', n.arcClaim?.arc?.id + '#2')
        break
      case this.MODULE_CONSTANTS.NOTIFICATIONS.NOTIFIERS.REVIEW_REJECTED.CODE:
      case this.MODULE_CONSTANTS.NOTIFICATIONS.NOTIFIERS.NEW_EXTERNAL_REVIEW.CODE:
        this.showReview(n.arcReviewSite?.review?.claimId)
        break
      case this.MODULE_CONSTANTS.NOTIFICATIONS.NOTIFIERS.NEW_ARC.CODE:
      case this.MODULE_CONSTANTS.NOTIFICATIONS.NOTIFIERS.DELAY_EXPIRING.CODE:
      case this.MODULE_CONSTANTS.NOTIFICATIONS.NOTIFIERS.DELAY_EXPIRED.CODE:
      case this.MODULE_CONSTANTS.NOTIFICATIONS.NOTIFIERS.READY_TO_REVIEW.CODE:
      case this.MODULE_CONSTANTS.NOTIFICATIONS.NOTIFIERS.REVIEW_DUE_SOON.CODE:
      case this.MODULE_CONSTANTS.NOTIFICATIONS.NOTIFIERS.INVITED_TO_TEAM.CODE:
        url = url = n.to
        break
      case this.MODULE_CONSTANTS.NOTIFICATIONS.NOTIFIERS.NEW_TEAM_APPLICANT.CODE:
        url = this.linkService.adminView('arc-team', n.arcTeamMember?.arcTeam?.id)
        break
    }

    if (url) {
      await this.$router.push(url)
    }
  }

  timeSince (date: Date) {
    date = new Date(date)
    const seconds = Math.floor((new Date().valueOf() - date.valueOf()) / 1000)

    let interval = seconds / 31536000

    if (interval > 1) {
      return Math.floor(interval) + ' years'
    }
    interval = seconds / 2592000
    if (interval > 1) {
      return Math.floor(interval) + ' months'
    }
    interval = seconds / 86400
    if (interval > 1) {
      return Math.floor(interval) + ' days'
    }
    interval = seconds / 3600
    if (interval > 1) {
      return Math.floor(interval) + ' hours'
    }
    interval = seconds / 60
    if (interval > 1) {
      return Math.floor(interval) + ' minutes'
    }
    return Math.floor(seconds) + ' seconds'
  }

  getDaysAgo (n: UserNotificationDto) {
    return this.timeSince(n.statusDate)
  }

  onFilterClick (filter: ValueLabel, reload?: boolean) {
    // if they click all, just res
    if (filter.value === 'all' && !this.selectedFilters.includes('all')) {
      this.clearFilters()
    } else {
      this.selectedFilters = this.selectedFilters.filter(m => m !== 'all')

      const existingFilterIndex = this.selectedFilters.indexOf(filter.value + '')
      if (existingFilterIndex > -1) {
        this.selectedFilters.splice(existingFilterIndex, 1)
      } else {
        this.selectedFilters.push(filter.value + '')
      }
    }

    if (reload) {
      void this.load()
    }
  }

  async onDeleteClick (notification: UserNotificationDto) {
    this.crudService.deleteNotificationQueueItem(notification.id)
      .then(() => this.load())
  }

  resetFilters () {
    this.selectedFilters = ['all']
  }

  clearFilters () {
    this.resetFilters()
    void this.load()
  }

  getData () {
    let filtersWithoutAll = this.selectedFilters.filter(m => m !== 'all')
    if (filtersWithoutAll.length === 0) {
      filtersWithoutAll = this.allFilters.map(m => m.value + '')
    }
    return this.crudService.get(this.makeGetArgs({
      notificationCodes: filtersWithoutAll
    })).catch(e => {
      if (e?.message === 'Unauthorized') {
        // do nothing
      } else {
        throw e
      }
    })
  }

  preparseList (list: UserNotificationPagedResultDto) {
    this.unreadNotificationCount = list?.unreadNotificationCount
    return list
  }

  mounted () {
    this.filters = [
      new ValueLabel('all', this.$tc('system.labels.all')),
      ...this.allFilters
    ]

    this.resetFilters()
  }
}

