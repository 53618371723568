import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { boot } from 'quasar/wrappers'
import { configure, i18nConfig } from 'booksprout-app'

Vue.use(VueI18n)

const i18n = new VueI18n(i18nConfig as VueI18n.I18nOptions)

export default boot(async ({ app, Vue }) => {
  configure(i18n, app, Vue)
})

export { i18n }
