import { gql } from '@apollo/client/core'

import { ArcServiceInterface, AxiosService, BaseCrudService, ConfigService, inject, StoreService } from 'booksprout-app'
import {
  AcceptTeamInvitationArgs, ArcClaimDto,
  ArcTeamBlockUserArgs,
  ArcTeamDto,
  ArcTeamMemberDto,
  ArcTeamMemberGetArgs,
  ArcTeamMemberGetByIdArgs,
  ArcTeamMembershipDto,
  ArcTeamUserStatusLogDto,
  CreateDtoInterface,
  UpdateDtoInterface,
  UserAnalysisArgs,
  UserAnalysisDto
} from 'booksprout'
import { ArcTeamMemberServiceInterface } from './arcTeamMember.service.interface'

export class ArcTeamMemberService extends BaseCrudService<ArcTeamMemberDto> implements ArcTeamMemberServiceInterface<ArcTeamMemberDto> {
  public apiControllerPath = 'arcTeam'


  constructor (
    @inject('ConfigService') configService: ConfigService,
    @inject('StoreService') storeService: StoreService,
    @inject('ArcService') private readonly arcService: ArcServiceInterface<ArcTeamDto>,
    @inject('AxiosService') axiosService: AxiosService
  ) {
    super(configService, storeService, axiosService)
  }

  public create (model: CreateDtoInterface): Promise<ArcTeamMemberDto> {
    throw new Error('Method not implemented.')
  }

  public delete (id: number): Promise<any> {
    throw new Error('Method not implemented.')
  }

  public update (model: UpdateDtoInterface): Promise<ArcTeamMemberDto> {
    throw new Error('Method not implemented.')
  }

  /**
   * Get all members that belong to a team - does not return the same detailed info as getMember
   * @param args
   */
  public get (args: ArcTeamMemberGetArgs): Promise<ArcTeamMemberDto[]> {
    const
      filterStatus = args.status === void 0 ? -1 : args.status,
      filterType = args.type === void 0 ? -1 : args.type

    return this.apolloClientService.query({
      variables: {
        teamId: args.teamId,
        status: filterStatus,
        type: filterType,
        needle: args.needle
      },
      query: gql`
        query GetArcTeamMembers (
          $teamId: Int!,
          $status: Int,
          $type: Int,
          $needle: String
        ) {
          arcTeamMembers (teamId: $teamId, status: $status, type: $type, needle: $needle) {
            id,
            arcTeamId,
            userEmail {
              emailAddress
            },
            user {
              id,
              image,
              name
            },
            status,
            type,
            statusReasonType,
            statusReason,
            reportedDate,
            statusDate,
            createdDate,
            activeArcCount,
            cancelledArcCount,
            reviewedArcCount,
            pastDueArcCount,
            averageRating
          }
        }
      `
    })
  }

  public getById (id: number): Promise<ArcTeamMemberDto> {
    throw new Error('Method not implemented.')
  }

  public getMember (args: ArcTeamMemberGetByIdArgs): Promise<ArcTeamMemberDto> {
    return this.apolloClientService.query({
      variables: {
        userId: args.userId,
        teamId: args.teamId
      },
      query: gql`
        query GetArcTeamMembers (
          $userId: Int,
          $teamId: Int
        ) {
          arcTeamMember (userId: $userId, teamId: $teamId) {
            id,
            userEmail {
              emailAddress
            },
            user {
              id,
              image,
              name,
              arcClaims {
                id,
                status,
                statusDate,
                arc {
                  id,
                  book {
                    id,
                    title,
                    bookCover,
                    penNameType,
                    penName,
                    authorPenName {
                      id,
                      name
                    }
                  }
                },
                review {
                  id,
                  rating,
                  privateFeedback,
                  review,
                  sites {
                    id,
                    link,
                    status,
                    statusDate
                  },
                  disclaimer,
                  createdDate
                }
              }
            },
            status,
            type,
            statusReasonType,
            statusReason,
            reportedDate,
            statusDate,
            createdDate,
            activeArcCount,
            cancelledArcCount,
            reviewedArcCount,
            pastDueArcCount,
            averageRating,
            arcTeamId
          }
        }
      `
    })
  }

  public getUserAnalysis (args: UserAnalysisArgs): Promise<UserAnalysisDto> {
    return this.apolloClientService.query({
      variables: {
        ...args
      },
      query: gql`
        query GetUserAnalysis (
          $userId: Int!
          $includeCancelled: Boolean,
          $includeReviews: Boolean,
          $teamId: Int,
          $teamOnly: Boolean,
        ) {
          userAnalysis (
            includeCancelled: $includeCancelled,
            includeReviews: $includeReviews,
            teamId: $teamId,
            teamOnly: $teamOnly,
            userId: $userId
          ) {
            user {
              id,
              name,
              emailAddress,
              image,
              reviewerRole
            },
            stats {
              active,
              averageRating,
              cancelled,
              complete,
              pastDue,
              reviewCount
            },
            teams {
              id,
              name,
              image,
              memberStatus,
              memberType,
              memberId,
              memberUserId,
              memberStatusReasonType,
              memberStatusReason
            }
          }
        }
      `
    })
  }

  public getUserAnalysisClaims (args: UserAnalysisArgs): Promise<ArcClaimDto[]> {
    return this.apolloClientService.query({
      variables: {
        ...args
      },
      query: gql`
        query GetUserAnalysisClaims (
          $userId: Int!
          $includeCancelled: Boolean,
          $includeReviews: Boolean,
          $teamId: Int,
          $teamOnly: Boolean,
          $take: Int,
          $skip: Int
        ) {
          userAnalysisClaims (
            includeCancelled: $includeCancelled,
            includeReviews: $includeReviews,
            teamId: $teamId,
            teamOnly: $teamOnly,
            userId: $userId,
            skip: $skip,
            take: $take
          ) {
            ...ArcClaimFragment,
            arc {
              ...ArcFragment
            }
          }
        }
        ${this.arcService.arcFragments.claim},
        ${this.arcService.arcFragments.arc},
      `
    })
  }

  public getTeams (teamMemberId: number): Promise<ArcTeamDto[]> {
    return this.apolloClientService.query({
      variables: {
        teamMemberId
      },
      query: gql`
        query GetArcTeams (
          $teamMemberId: Int
        ) {
          arcTeams (
            teamMemberId: $teamMemberId
          ) {
            id,
            name,
            description,
            image,
            defaultDisclaimer,
            arcTeamMembers {
              id,
              user {
                id
              }
            }
          }
        }
      `
    })
  }

  public setType (memberId: number, teamId: number, type: number): Promise<ArcTeamMemberDto> {
    return this.apolloClientService.mutate({
      variables: {
        type,
        memberId,
        teamId
      },
      mutation: gql`
        mutation SetArcTeamMemberType (
          $memberId: Int!,
          $teamId: Int!,
          $type: Int
        ) {
          setArcTeamMemberType (
            id: $memberId,
            teamId: $teamId,
            type: $type
          ) {
            id
          }
        }
      `
    })
  }

  public setStatus (memberId: number, teamId: number, status: number, statusReason?: string, statusReasonType?: number, report?: boolean): Promise<ArcTeamMemberDto> {
    return this.apolloClientService.mutate({
      variables: {
        status,
        memberId,
        teamId,
        statusReason,
        statusReasonType,
        report
      },
      mutation: gql`
        mutation SetArcTeamMemberStatus (
          $memberId: Int!,
          $teamId: Int!,
          $status: Int,
          $statusReason: String,
          $statusReasonType: Int,
          $report: Boolean
        ) {
          setArcTeamMemberStatus (
            id: $memberId,
            teamId: $teamId,
            status: $status,
            statusReason: $statusReason,
            statusReasonType: $statusReasonType,
            report: $report
          ) {
            id
          }
        }
      `
    })
  }

  public joinTeam (model: ArcTeamMembershipDto): Promise<ArcTeamMemberDto> {
    return this.apolloClientService.mutate({
      variables: {
        ...model
      },
      mutation: gql`
        mutation JoinArcTeam (
          $memberUserId: Int!,
          $teamId: Int!
          $status: Int
        ) {
          joinTeam (
            memberUserId: $memberUserId,
            teamId: $teamId,
            status: $status
          ) {
            id
          }
        }
      `
    })
  }

  public blockMember (args: ArcTeamBlockUserArgs): Promise<ArcTeamMemberDto> {
    return this.apolloClientService.mutate({
      variables: {
        ...args
      },
      mutation: gql`
        mutation BlockMember (
          $userId: Int!
          $statusReasonType: Int!,
          $arcId: Int,
          $arcTeamId: Int,
          $report: Boolean,
          $statusReason: String,
          $memberId: Int,
          $claimId: Int
        ) {
          blockMember (
            userId: $userId,
            statusReasonType: $statusReasonType,
            arcId: $arcId,
            arcTeamId: $arcTeamId,
            report: $report,
            statusReason: $statusReason,
            memberId: $memberId,
            claimId: $claimId
          ) {
            id
          }
        }
      `
    })
  }

  public removeFromTeam (model: ArcTeamMembershipDto): Promise<ArcTeamMemberDto> {
    return this.apolloClientService.mutate({
      variables: {
        teamId: model.teamId,
        memberUserId: model.memberUserId
      },
      mutation: gql`
        mutation RemoveFromArcTeam (
          $memberUserId: Int!,
          $teamId: Int!
        ) {
          removeFromTeam (
            memberUserId: $memberUserId,
            teamId: $teamId
          ) {
            id
          }
        }
      `
    })
  }

  requestToJoin (args: AcceptTeamInvitationArgs): Promise<ArcTeamMemberDto> {
    return this.apolloClientService.mutate({
      variables: {
        ...args
      },
      mutation: gql`
        mutation RequestToJoinTeam (
          $teamId: Int!
          $userEmailId: Int
        ) {
          requestToJoinTeam (
            teamId: $teamId
            userEmailId: $userEmailId
          ) {
            id
          }
        }
      `
    })
  }

  leaveTeam (id: number): Promise<any> {
    return this.apolloClientService.mutate({
      variables: {
        id
      },
      mutation: gql`
        mutation LeaveTeam (
          $id: Int!
        ) {
          leaveTeam (
            id: $id
          ) {
            id
          }
        }
      `
    })
  }

  teamUserStatusLog (teamId: number): Promise<ArcTeamUserStatusLogDto> {
    return this.apolloClientService.query<ArcTeamUserStatusLogDto>({
      variables: {
        id: teamId
      },
      query: gql`
        query TeamUserStatusLog (
          $id: Int!
        ) {
          teamUserStatusLog (
            id: $id
          ) {
            date,
            type
          }
        }
      `
    }).then(model => {
      return {
        ...model,
        date: new Date(model?.date)
      }
    })
  }

  acceptInvitation (args: AcceptTeamInvitationArgs): Promise<ArcTeamMemberDto> {
    return this.apolloClientService.mutate({
      variables: {
        ...args
      },
      mutation: gql`
        mutation AcceptTeamInvitation (
          $teamId: Int!,
          $userEmailId: Int
        ) {
          acceptTeamInvitation (
            teamId: $teamId,
            userEmailId: $userEmailId
          ) {
            id,
            type,
            status,
            userEmail {
              emailAddress
            }
          }
        }
      `
    })
  }

}
