import { BaseModel } from '../../base.model'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { CreateDtoInterface, UpdateDtoInterface } from 'booksprout'
import { BsTabDefinition } from '../../../../components/bs-component-bundle/types'
import { SET_RETURN_MODEL } from '../../../../store/actions/system'
import { CommonViewModuleMixin } from './viewModule'

interface TabTracker {
  name: string,
  heading: string,
  description: string,
  hint: string,
  actionHint: string
  i18nKey: string
}

interface TabRegistrar {
  i18nKey: string
  hidden?: boolean
  disable?: boolean
  previousLabel?: string
  nextLabel?: string
  hideNext?: boolean
  isEditMode?: boolean
  stepComplete?: boolean
  previousAction? (): void
  nextAction? (): void
}

// @ts-ignore
@Component
export abstract class CommonCrudModuleMixin<TModel extends BaseModel> extends CommonViewModuleMixin<TModel> {
  @Prop({ type: Object }) public readonly assignModel!: TModel

  public currentTab: number = 1
  public tabs: BsTabDefinition[] = []
  public tabsTracker: TabTracker[] = []

  /**
   * When we refresh on a subcomponent that uses assignModel, it'll assign a null crudModel on first load
   * Need to reassign the model when it's loaded (changed).
   * @param to
   */
  @Watch('assignModel')
  doOnAssignModelChanged (to: TModel) {
    this.assignLoadedDoc(to)
  }

  // @ts-ignore
  private performDelete (redirectTo: string) {
    return this.crudService.delete(this.crudModel.id).then(() => {
      return this.$router.push(redirectTo)
    }).catch(e => {
      return Promise.reject(e)
    })
  }

  /**
   * Function to use when you want to navigate a user somewhere but then allow
   * them to come back and it remember the document they were editing.
   * @param toUrl
   */
  public navigateWithReturn (toUrl: string, returnLabel: string, completeLabel: string, from: string = '') {
    this.$store.dispatch(SET_RETURN_MODEL, {
      crudModel: this.crudModel,
      from: from || this.$route.fullPath,
      returnLabel,
      completeLabel
    })
    return this.$router.push(toUrl)
  }

  /**
   * Should the object be able to auto save?
   */
  public autoSaveValid (): boolean {
    return true
  }

  /**
   * A function that is called before auto save is called which allows object manipulation
   */
  public doBeforeSave (): Promise<void> {
    return Promise.resolve()
  }

  /**
   * A function that is called after a successful auto save is called which allows object manipulation
   */
  public doAfterSave (doc: TModel) {
    // Do nothing
  }

  /**
   * Are we in edit mode?
   */
  public get isEdit () {
    // We're in edit more IF;

    // 1. We have an update URL
    return this.$route.path.indexOf('/update/') > -1
    // NOTE: Removed this as part of [ch1157]
    // We don't really want to be in edit mode when we're creating anyway. Might need more work.
    /*|| (
      // Or we've auto saved on a create URL and now have an ID against our crudModel
      this.crudModel !== void 0 && this.crudModel !== null && validInt(this.crudModel.id)
    )*/
  }

  public get currentTabTracker (): TabTracker {
    const result = this.tabsTracker[this.currentTab - 1]

    // If there isn't a tab yet, return some empty values.
    if (result === void 0) {
      return {
        name: '',
        heading: '',
        description: '',
        hint: '',
        actionHint: '',
        i18nKey: ''
      }
    }
    return result
  }

  public get tabName () {
    return this.currentTabTracker.name
  }

  public get tabHeading () {
    return this.currentTabTracker.heading
  }

  public get tabDescription () {
    return this.currentTabTracker.description
  }

  public get tabHint () {
    return this.currentTabTracker.hint
  }

  public get tabActionHint () {
    return this.currentTabTracker.actionHint
  }

  /**
   * Technically, we're passing the model in to save as they generally match.
   * This provides a way to override the mapping of crudModel -> CreateDtoModel
   * @param model
   */
  public getCreateModel (): CreateDtoInterface {
    return {
      ...this.crudModel
    }
  }

  /**
   * Technically, we're passing the model in to save as they generally match.
   * This provides a way to override the mapping of crudModel -> UpdateDtoModel
   * @param model
   */
  public getUpdateModel (): UpdateDtoInterface {
    return {
      ...this.crudModel,
      id: this.crudModel.id || 0
    }
  }

  public async save (): Promise<TModel> {
    await this.doBeforeSave()

    if (this.crudModel.id === void 0) {
      const createModel = this.getCreateModel()
      const newModel = await this.crudService.create(createModel)
      this.doAfterSave(newModel)
      return newModel
    } else {
      const updateModel = this.getUpdateModel()
      const updatedModel = await this.crudService.update(updateModel)
      this.doAfterSave(updatedModel)
      return updatedModel
    }
  }

  public deleteCrudModel (confirmProp: string, redirectTo: string = this.listUrl()) {
    this.$q.dialog({
      component: this.$bs.deleteModuleComponent,
      parent: this,
      model: this.crudModel
    }).onCancel(() => {
      return this.performDelete(redirectTo).then(() => {
        if ((this.crudModel as any).image) {
          return this.crudService.cleanFiles((this.crudModel as any).image)
        } else if ((this.crudModel as any).bookCover) {
          return this.crudService.cleanFiles((this.crudModel as any).bookCover)
        }
        return void 0
      })
    })
  }

  public async autoSave (): Promise<TModel | any> {
    if (!this.autoSaveValid()) {
      return Promise.resolve(void 0)
    } else {
      return this.save().then(r => {
        // Update our local model if we're "creating" and auto save kicks in because each subsequent
        // save will need to be an update instead of create.
        if (this.crudModel.id === void 0) {
          this.crudModel.id = r.id
          this.crudModel.createdDate = r.createdDate
        }
      }).catch(e => {
        // Rejecting this here will send the error down to the auto save and in turn show the error
        // against the offending field
        return Promise.reject(e)
      })
    }
  }

  /**
   * Register an individual tab to be added to this.tabs.
   * Note: Consider using this.registerTabs() instead an registering all you need.
   * @param i18nRoot
   * @param tab
   */
  public registerTab (i18nRoot: string, tab: TabRegistrar) {
    this.tabsTracker.push({
      name: this.$tcD(i18nRoot + '.name', ''),
      heading: this.$tcD(i18nRoot + '.heading', ''),
      description: this.$tcD(i18nRoot + '.description', ''),
      hint: this.$tcD(i18nRoot + '.hint', ''),
      actionHint: this.$tcD(i18nRoot + '.actionHint', ''),
      i18nKey: tab.i18nKey
    })

    const nextTabIndex = this.tabs.length + 1
    this.tabs.push(
      {
        ...new BsTabDefinition(nextTabIndex, this.$tc(i18nRoot + '.name')),
        ...tab,
        actionHint: this.$tcD(i18nRoot + '.actionHint', ''),
        slotKey: tab.i18nKey
      }
    )
  }

  /**
   * Register tabs which will then become available in this.tabs
   * @param rootI18nModuleKey
   * @param tabs
   */
  public registerTabs (rootI18nModuleKey: string, tabs: TabRegistrar[]) {
    this.tabs.splice(0, this.tabs.length)
    this.tabsTracker.splice(0, this.tabsTracker.length)
    for (const tab of tabs) {
      this.registerTab(`modules.${rootI18nModuleKey}.formFields.tabs.${tab.i18nKey}`, tab)
    }
  }
}
