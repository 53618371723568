import { Module } from 'vuex'
import systemState from './state'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'
import { SystemState } from './types'
import { BaseRootState } from '../../baseRootState'

export const system: Module<SystemState, BaseRootState> = {
  state: systemState,
  getters,
  mutations,
  actions
}
