import { GetterTree } from 'vuex'
import { AuthState } from './types'
import { newDate, PermissionService, validInt } from 'booksprout'
import { BaseRootState } from '../../baseRootState'

export const getters: GetterTree<AuthState, BaseRootState> = {
  isAuthenticated (state) {
    return !!state.user?.id && !!state.token.expires && state.token.expires > newDate().getTime() / 1000
  },
  authToken (state) {
    return state.token.data
  },
  authenticatedUser (state) {
    if (state.user === null) return null
    return {
      ...state.user,
      permissionQuery: new PermissionService().configure(state.user)
    }
  },
  mimicUser (state) {
    if (!validInt(state.mimicUser?.id)) return null

    return {
      ...state.mimicUser,
      permissionQuery: new PermissionService().configure(state.mimicUser)
    }
  },
  termsConsentNeeded (state) {
    return state.termsConsentNeeded
  },
  termsConsentFailed (state) {
    return state.termsConsentFailed
  },
  termsConsentData (state) {
    return state.termsConsentData
  },
  csrfToken (state) {
    return state.csrfToken
  },
  getIsAuthExpired (state) {
    return state.authExpired
  }
}
