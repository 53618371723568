import { inject } from 'inversify-props'
import VueI18n from 'vue-i18n'

export class ApiErrorHandler {
  public constructor (
    @inject('TranslationService') protected readonly translationService: VueI18n
  ) { }

  /**
   * The API might respond with an error code, use this to get the translation for an error message
   * @param code
   */
  public getErrorResponseFromCode (code: string): object {
    console.log('ERROR RES:: ', code)
    console.log(this.translationService.tc('system.errors.' + code))
    return this.getErrorResponse(this.translationService.tc('system.errors.' + code))
  }

  /**
   * Wrapper to return a Promise rejection as an error object
   * @param errorMessage
   */
  public getErrorResponse (errorMessage: string): object {
    return {
      message: errorMessage
    }
  }

  /**
   * Determine if the response from the API is a "Bad Request" (400).
   * @param r
   */
  public isBadRequest (r: any): boolean {
    return r && r.response !== void 0 && r.response.status === 400
  }

  /**
   * Determine if the response from the API is an "Unauthorised" error (403).
   * @param r
   */
  public isAuthError (r: any): boolean {
    return r && r.response !== void 0 && (r.response.status === 403 || r.response.status === 401)
  }

  /**
   * The API uses class-validator. Parse the response to something that will either be an actual error
   * or an error code which is then translated using i18n
   * @param constraints
   */
  public getConstraintErrorResponse (constraints: any): object {
    const
      firstKey = Object.keys(constraints)[0],
      message = constraints[firstKey]
    // Assume that if the message is a single word, we're using it as a translation key
    // i.e invalidEmail
    return firstKey.indexOf(' ') > -1
      ? this.getErrorResponse(message)
      : this.getErrorResponseFromCode(message)
  }
}
