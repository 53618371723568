import { RootState } from '../../types'
import { ActionTree } from 'vuex'
import { WebsiteStatsState } from './state'
import { SET_WEBSITE_STATS } from '../../actions/stats'

export const actions: ActionTree<WebsiteStatsState, RootState> = {
  [SET_WEBSITE_STATS] ({ commit }, stats) {
    commit(SET_WEBSITE_STATS, stats)
  }
}
