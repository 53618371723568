import { Component } from 'vue-property-decorator'
import { BaseModel } from '../../base.model'
import { CommonBaseModuleMixin } from './baseModule'
import { BsPaginator } from '../../../../components/bs-component-bundle'
import { validInt } from 'booksprout'
import { QInfiniteScroll } from 'quasar'

// @ts-ignore
@Component
export abstract class CommonBaseListModuleMixin<TModel extends BaseModel> extends CommonBaseModuleMixin<TModel> {
  abstract list: TModel[]
  currentPage = 1
  rowsPerPage = 12
  skip = 0
  /**
   * Use sparingly, not all lists return this prop - check the API call
   */
  totalRows = 0
  defaultPagination = { rowsPerPage: 999 }
  sortBy: string | undefined = void 0
  sortDescending: boolean | undefined = true
  previousSortDescending: boolean | undefined = void 0
  loaded = false
  infiniteScroll = false
  minTimeBetweenInfiniteCalls = 500 // ms
  doneLoadingInfiniteScroll = false
  scrollPageLoaded = false
  genericSearchTerm = ''
  disableGlobalSearchOnLoad = false
  currentlyLoadingMoreData = new Date()

  get mergeGetArgs () {
    return {}
  }

  get sortIcon () {
    return 'arrow_' + (this.sortDescending ? 'downward' : this.sortDescending === void 0 ? '' : 'upward')
  }

  /**
   * This is used to attach to our <no-results> component via v-if.
   * We want to make sure it only shows when the list is empty AND it's been loaded.
   * Don't want it to show when we're just loading the page.
   */
  get showNoResults () {
    return this.list?.length === 0 && this.loaded
  }

  get infiniteScroller (): QInfiniteScroll & { setIndex: (page: number) => void} {
    const scroller = (this.$refs.infiniteScroller as QInfiniteScroll & { setIndex: (page: number) => void})
    if (Array.isArray(scroller)) {
      return scroller[0]
    }

    return scroller
  }

  resetPagination () {
    const paginator = (this.$refs.paginator as BsPaginator)
    if (paginator) {
      paginator.reset()
    }
    this.skip = 0
    this.currentPage = 1
    if (this.infiniteScroller) {
      this.infiniteScroller.reset()
      this.infiniteScroller.resume()
      this.doneLoadingInfiniteScroll = false
    }
    this.resetList()
  }

  add () {
    this.$router.push(this.createUrl())
  }

  getData (params?: any) {
    return this.crudService.globalLoader(this.disableGlobalSearchOnLoad).get(this.makeGetArgs(params)).catch(e => {
      if (e?.message === 'Unauthorized') {
        // do nothing
      } else {
        return e
      }
    })
  }

  preparseList (list: any) {
    return list
  }

  assignToList (result: any) {
    result = this.preparseList(result)
    let items = []

    // The result might come back as a paged result or just a standard list.
    // Paged will always have a totalRows field so we can use this to check which result set we have.
    if (result?.totalRows !== void 0) {
      items = result.items
      this.totalRows = result.totalRows
    } else {
      items = result
    }

    if (this.infiniteScroll) {
      const currentListIds = this.list?.map(m => m.id)
      const filteredItems = items?.length ? items.filter((f: TModel) => !currentListIds.includes(f.id)) : []
      this.list = this.list?.concat(filteredItems)
    } else {
      this.list = items
    }

    if ((this.list || []).length === 0) {
      this.$emit('empty')
    }

    this.loaded = true
  }

  loadComplete () {
    // DO nothing here
  }

  loadUrlPage () {
    const page = this.$route.query.page + ''
    if (validInt(page) && !this.scrollPageLoaded) {
      this.currentPage = parseInt(page)
      this.skip = this.currentPage * this.rowsPerPage - this.rowsPerPage
    }
  }

  loadScrollPage () {
    const page = this.$route.query.page + ''
    if (validInt(page) && !this.scrollPageLoaded) {
      this.currentPage = parseInt(page)
      // Set skip here for the initial load if we have a page in the URL
      this.skip = this.currentPage * this.rowsPerPage - this.rowsPerPage
      if (this.infiniteScroller) {
        this.infiniteScroller.setIndex(this.currentPage)
      }
    }
    this.scrollPageLoaded = true
  }

  onResetList () {
    // do nothing
  }

  resetList () {
    this.onResetList()
    this.list = []
    const urlIndex = window.location.href.indexOf('?page=')
    if (urlIndex > -1) {
      // This means we've been using infinite scroll paging so reset our paging to 0
      const newUrl = window.location.href.substr(0, urlIndex)
      history.pushState(null, '', newUrl)
      this.skip = 0
      this.currentPage = 1
    }
  }

  async load (params?: any) {
    this.loaded = false
    const page = this.$route.query.page
    // Don't reset the list if we're loading a page using the URL
    if (!page) {
      this.resetList()
    }
    let result = await this.getData(params).catch(e => {
      if (e?.message === 'Unauthorized') {
        // do nothing
      } else {
        throw e
      }
    })

    interface PagedResult { totalRows: number, items?: any[] }
    if (page && (result.length === 0 || (result as PagedResult)?.items?.length === 0)) {
      this.resetList()
      result = await this.getData(params)
    }

    this.assignToList(result)
    this.crudService.globalLoader(!this.disableGlobalSearchOnLoad)
    this.loadComplete()
  }

  async lazyLoad (index: number, done: (stop?: boolean) => void) {
    if (this.doneLoadingInfiniteScroll) {
      done(true)
      return
    }
    /**
     * Only allow this method to run, if:
     * 1. The scrollPage data has loaded (paging from URL)
     * 2. Infinite scroll is enabled
     * 3. We have enough items worth bothering about for infinite scrolling.
     * 4. We've not tried to load within the last X (minTimeBetweenInfiniteCalls)
     */
    const timeDiff = new Date(new Date().getTime() - this.currentlyLoadingMoreData.getTime()).getMilliseconds()
    if (this.scrollPageLoaded && this.infiniteScroll && this.list?.length >= this.rowsPerPage && (timeDiff > this.minTimeBetweenInfiniteCalls)) {
      this.currentlyLoadingMoreData = new Date()
      // This is here because we're only enabling the infinite scroll AFTER the first page load (which means page 1)
      // so if we're on page 1 (which is already loaded). Shift to page 2 so the lazy loading loads the next set of data
      if (this.currentPage === 1) {
        this.currentPage = 2
      } else {
        this.currentPage++
      }

      this.skip = this.currentPage * this.rowsPerPage - this.rowsPerPage

      // This might look odd but we only want to update the URL with the page BEFORE the one we're loading.
      // This means if the user clicks an item on page 2 even when page 3 is loaded, it'll take them back to page 2
      // on back button click and they can scroll down to get to page 3. If we took them to page 3, they wouldn't fine
      // their item they clicked on page 2.
      if (this.currentPage > 2) {
        if (history.pushState) {
          const id = '?page='
          const urlIndex = window.location.href.indexOf(id)
          const newUrl = urlIndex === -1 ? window.location.href : window.location.href.substr(0, urlIndex)
          // First time setting the page? Set to page -1 (see note above), otherwise set to current page (for page refresh and scroll)
          const setToPage = urlIndex > -1 ? this.currentPage : this.currentPage - 1
          history.pushState(null, '', newUrl + `${id}${setToPage}`)
        }
      }

      const listLength = this.list?.length
      const result = await this.getData()
      this.assignToList(result)
      this.doneLoadingInfiniteScroll = this.list?.length === listLength || result?.items?.length === 0
      await this.loadComplete()

      // Pass stop = true if the list length is the same after the load as it is before we loaded.
      // This means we have no more items to load with the current filters.
      done(this.doneLoadingInfiniteScroll)
    } else {
      done(this.doneLoadingInfiniteScroll)
    }
  }

  makeGetArgs(paramsToMerge: any) { // It really can be anything at this point
    return {
      skip: this.skip,
      take: this.rowsPerPage,
      sortBy: this.sortBy,
      descending: this.sortDescending,
      genericSearchTerm: this.genericSearchTerm,
      ...this.mergeGetArgs,
      ...paramsToMerge
    }
  }

  paginate (args: { pageNumber: number, skip: number, take: number }) {
    this.currentPage = args.pageNumber
    this.skip = args.skip
    this.load()
  }

  sortOn (col: { name: string, label: string }) {
    let clearSort = false
    if (this.sortBy === col.name) {
      if (this.previousSortDescending === false) {
        this.sortDescending = void 0 // reset to no sort
        clearSort = true
      } else {
        this.sortDescending = !this.sortDescending
      }
      this.previousSortDescending = this.sortDescending
    } else if (this.sortBy === void 0) {
      // If we have undefined sortBy, it means it's been reset. So use true here as default value for next click.
      this.sortDescending = true
    } else {
      this.sortDescending = void 0 // reset to default
    }

    // If we need to clear the sort (sort clicked past asc then desc) clear it.
    this.sortBy = clearSort ? void 0 : col.name

    this.load()
  }

  mounted () {
    if (this.infiniteScroll) {
      this.loadScrollPage()
    } else {
      this.loadUrlPage()
    }
    this.load()
  }
}
