import { ActionTree } from 'vuex'

import { AuthState } from './types'
import { NOTIFY_ERROR } from '../../actions/ui'
import {
  AUTH_CONSENT_FAILED, AUTH_CONSENT_NEEDED, AUTH_EXPIRED,
  AUTH_INVALID,
  AUTH_LOAD,
  AUTH_LOGOUT,
  AUTH_LOGOUT_REQUEST,
  AUTH_REQUEST,
  AUTH_REQUIRED,
  AUTH_SUCCESS,
  GET_CSRF_TOKEN,
  SET_CSRF_TOKEN,
  SET_MIMIC_USER,
  SET_USER
} from '../../actions/auth'
import {
  dateAddDays,
  getAdminUrl,
  getBillingPlan,
  getStripeUrl,
  LoginResultDto,
  UserDto
} from 'booksprout'
import { BaseRootState } from '../../baseRootState'
import { container } from '../../../modules/diContainer'
import { AuthService } from '../../../modules/_base/auth/auth.service'
import { StoreService } from '../../../modules/_base/store.service'
import { ConfigService } from '../../../modules/_base/config.service'
import { AxiosService } from '../../../modules/_base/axios/axios.service'
import VueI18n from 'vue-i18n'
import { AuthServiceInterface } from '../../../modules/_base/auth/auth.service.interface'
import { LocalStorage, Platform } from 'quasar'

const userTokenKey = 'user-token'
const userTokenExpiresKey = 'user-token-expires'

const cookieOptions = {
  path: '/',
  expires: dateAddDays(30)
}

export const actions: ActionTree<AuthState, BaseRootState> = {
  [GET_CSRF_TOKEN] ({ commit }, configFile: any) {
    if (this.getters.isAuthenticated) {
      const storeService = new StoreService()
      storeService.configure(this)

      const configService = new ConfigService()
      configService.configure(configFile)

      const axiosService = new AxiosService(storeService, configService, new VueI18n())

      return axiosService.axios.get('auth/getCsrfToken', {
        withCredentials: true
      }).then((r: { data: string }) => {
        // console.log('New csrf token: ', r.data)
        return commit(SET_CSRF_TOKEN, r.data)
      })
    } else {
      return Promise.resolve()
    }
  },
  /**
   * Page re-loaded - Try and load our user information from localstorage / cookie.
   * @param commit
   */
  async [AUTH_LOAD] ({ commit }): Promise<void> {
    const authService: AuthServiceInterface = container.get('AuthService')

    const isIOS = Platform.is.capacitor && Platform.is.ios && typeof LocalStorage.getItem === 'function'
    const data = isIOS
      ? LocalStorage.getItem<string>(userTokenKey)
      : void 0

    const expires = isIOS
      ? LocalStorage.getItem<number>(userTokenExpiresKey)
      : parseInt(this.$cookies.get(userTokenExpiresKey))

    // Build a "fake" login result we can pass for the state to be updated.
    const loginState = {
      user: new UserDto(),
      token: {
        data,
        expires
      },
      mimicUser: new UserDto()
    }

    // Commit to the state so we can make API calls etc.
    commit(AUTH_LOAD, loginState)

    if ((loginState.token.expires || 0) > (new Date().getTime() / 1000)) {
      // Now get the user information about the user logged in
      // id doesn't matter here
      const authenticatedUser = await authService.getAuthenticatedUser()
      if (authenticatedUser) {
        loginState.user = authenticatedUser.user
        if (authenticatedUser.mimicUser) {
          loginState.mimicUser = authenticatedUser.mimicUser as UserDto
        }

        // commit our actual user to the DB this time.
        commit(AUTH_LOAD, loginState)
      }
    }
  },
  /**
   * Request authorisation for a payload
   * @param dispatch
   * @param payload
   */
  // TODO: Type the payload...
  [AUTH_REQUEST] ({ dispatch }, payload): Promise<LoginResultDto | null> {
    const authService: AuthService = container.get('AuthService')

    return authService.login(payload.emailAddress, payload.password, payload.extendLogin, payload.reinstateConsent).then(loginResult => {
      return dispatch(AUTH_SUCCESS, {
        loginResult,
        payload
      })
    })
  },
  /**
   * When consent fails, the state needs updating to show some information to the user as they're not allowed to continue
   * using the site at this point.
   * @param commit
   * @param payload
   */
  [AUTH_CONSENT_FAILED] ({ commit }, payload): void {
    commit(AUTH_CONSENT_FAILED, payload)
  },
  /**
   * When consent date is not set, it means user is using social login and left site without clicking one of buttons
   * on AgreeToOAuthTermsDialog.
   * @param commit
   * @param payload
   */
  [AUTH_CONSENT_NEEDED] ({ commit }, payload): void {
    commit(AUTH_CONSENT_NEEDED, payload)
  },
  /**
   * Update localstorage or cookie, state etc based on a successful loginResult
   * @param commit
   * @param dispatch
   * @param payload
   */
  [AUTH_SUCCESS] ({ commit, dispatch }, payload: any): Promise<LoginResultDto | null> {
    if (payload.loginResult === null) return Promise.reject()

    if (payload.loginResult.termsConsentFailed === true) {
      dispatch(AUTH_CONSENT_FAILED, payload)
      return Promise.reject()
    } else if (payload.loginResult.termsConsentNeeded) {
      dispatch(AUTH_CONSENT_NEEDED, payload)
      return Promise.reject()
    } else {
      dispatch(SET_USER, payload.loginResult.user)

      if (Platform.is.capacitor && Platform.is.ios && typeof LocalStorage.getItem === 'function') {
        LocalStorage.set(userTokenKey, payload.loginResult.token.data)
        LocalStorage.set(userTokenExpiresKey, payload.loginResult.token.expires)
      } else if (this.$cookies !== void 0) {
        this.$cookies.set(userTokenExpiresKey, payload.loginResult.token.expires.toString(), cookieOptions)
      }

      commit(AUTH_SUCCESS, payload.loginResult)
      dispatch(AUTH_EXPIRED, false)

      // Make sure we update any services which requires auth info.
      const authService: AuthService = container.get('AuthService')
      authService.onLoggedIn()

      return payload.loginResult
    }
  },
  [AUTH_REQUIRED] ({ dispatch }) {
    dispatch(AUTH_LOGOUT)
  },
  [AUTH_LOGOUT] ({ commit }) {
    if (Platform.is.capacitor && Platform.is.ios && typeof LocalStorage.getItem === 'function') {
      if (LocalStorage.has(userTokenKey)) {
        LocalStorage.remove(userTokenKey)
      }
      if (LocalStorage.has(userTokenExpiresKey)) {
        LocalStorage.remove(userTokenExpiresKey)
      }
    }

    commit(AUTH_LOGOUT)
  },
  [AUTH_LOGOUT_REQUEST] ({ dispatch, commit }) {
    void dispatch(AUTH_LOGOUT)

    const authService: AuthService = container.get('AuthService')
    return authService.logout()
  },
  [AUTH_INVALID] ({ dispatch }) {
    // This would be replaced by a translated version and also dispatch something so the user is prompted
    // to upgrade. UPSELL UPSELL UPSELL!
    // Not sure this is the right place for this.
    // Should we really be accessing i18n in actions?!
    dispatch(NOTIFY_ERROR, 'You do not have permission to do this.')
  },
  [AUTH_EXPIRED] ({ commit }, hasExpired: boolean) {
    commit(AUTH_EXPIRED, hasExpired)
  },
  /**
   * Assign a mimic user. Contact the server and provide fresh tokens for the user along with the
   * information about the user being mimic'd (Assuming they have permissions).
   * @param commit
   * @param dispatch
   * @param user
   */
  [SET_MIMIC_USER] ({ commit, dispatch }, user) {
    const authService: AuthService = container.get('AuthService')

    return authService.setMimicUser(user).then(loginResult => {
      // This will set the mimicUser and update all our tokens so we have a secure way of the server
      // knowing which user we're mimicing as it'll be baked into the auth token which is signed server side.
      return dispatch(AUTH_SUCCESS, { loginResult })
    })
  },
  [SET_USER] ({ commit }, user: UserDto) {
    commit(SET_USER, user)
    // Set this for use in index.html page.
    window.GlobalUserData = {
      name: user.name,
      emailAddress: user.emailAddress,
      image: user.image,
      plan: getBillingPlan(user.authorRole),
      userSince: (user.createdDate as unknown as string).slice(0, 10),
      stripeUrl: getStripeUrl(user.stripeCustomerId),
      adminUrl: getAdminUrl(user.id, user.name || user.emailAddress)
    }
  }
}

