import { CreateElement } from 'vue'
import { Component } from 'vue-property-decorator'
import { QRating } from 'quasar'

// Mixins
import { BaseBsComponentMixin } from '../mixins/common'

@Component
export default class BsRating extends BaseBsComponentMixin {
  // This combination forces the auto save on each click
  public autoSaveMethod = ''
  public forceTriggerOnChange = true

  public isComponentIsValid (val?: any) {
    return val === true
  }

  // https://app.shortcut.com/booksprout/story/7409/partial-star-ratings
  get roundedValue () {
    if (!this.componentValue) {
      return 0
    }

    const wholeNumber = Math.floor(this.componentValue)
    const decimalPart = this.componentValue % 1

    if (decimalPart < .25) {
      return wholeNumber
    } else if (decimalPart >= .25 && decimalPart < .75) {
      return wholeNumber + .5
    } else {
      return wholeNumber + 1
    }
  }

  public render (h: CreateElement): any {
    return h(QRating, {
      staticClass: 'bs-rating',
      props: this.getRenderProps({
        color: 'grey',
        icon: 'app:star-empty',
        iconSelected: 'app:star-full',
        iconHalf: 'app:star-half',
        noDimming: true,
        readonly: this.$attrs.readonly === void 0 ? true : this.$attrs.readonly,
        value: this.roundedValue
      }),
      on: this.getListeners(),
      scopedSlots: this.getScopedSlots(h),
      attrs: this.getAttributes()
    })
  }
}
