import { CreateElement } from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { QTooltip } from 'quasar'
import CommonMixin from '../mixins/common'
import BsBtn from '../btn/BsBtn'

@Component
export default class BsTooltip extends CommonMixin {
  @Prop({ type: String }) readonly text!: string
  @Prop({ type: Array }) readonly tooltipOffset!: number[]
  @Prop({ type: Number }) readonly tooltipOffsetY: number | undefined
  @Prop({ type: String }) readonly tooltipAnchor!: string
  @Prop({ type: String }) readonly tooltipSelf!: string

  __renderTooltip (h: CreateElement) {
    return h(QTooltip, {
      props: {
        contentClass: 'bs-tooltip__tip',
        offset: this.tooltipOffset || [-4, (this.tooltipOffsetY || 0) + 30],
        anchor: this.tooltipAnchor || 'bottom left',
        self: this.tooltipSelf || 'center left'
      }
    }, [
      this.text,
      this.slot(this, 'default')
    ])
  }

  render (h: CreateElement) {
    const props = {
      icon: 'app:question-circle',
      size: '.9375rem',
      color: 'transparent',
      textColor: 'bs-g',
      iconOnly: true
    }

    const scopedSlots = {
      default: () => this.__renderTooltip(h)
    }

    return h(BsBtn, {
      staticClass: 'bs-tooltip',
      props: this.getRenderProps(props),
      scopedSlots: this.getScopedSlots(h, scopedSlots),
      on: this.getListeners()
    })
  }
}
