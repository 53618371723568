export class ValueLabel {
  public constructor (
    public value?: number | string,
    public label?: string,
    public avatar?: string,
    public disable?: boolean
  ) { }
}

export class ValueLabelWithClick {
  public constructor (
    public value: number | string,
    public label: string,
    public onClick: () => void
  ) { }
}

export class BsTabDefinition {
  public hidden: boolean = false
  public disable: boolean = false
  public previousLabel: string = ''
  public nextLabel: string = ''
  public hideNext: boolean = false
  public actionHint: string = ''
  public isEditMode: boolean = false
  public slotKey: string = ''
  public stepComplete: boolean | undefined = void 0
  public previousAction? (): void
  public nextAction? (): void

  public constructor (public value: number, public label: string) { }
}
