import { PaymentServiceInterface } from './payment.service.interface'
import { BaseApolloClientService } from '../_base/apollo/baseApolloClient.service'
import {
  CalculatedTax,
  CancelSubscriptionArgs,
  ChangePlanSummaryDto,
  ChangeSubscriptionArgs,
  CreateSubscriptionFromTokenData,
  CreditCardTokenData,
  CrudSubscriptionResponseDto,
  GetCouponArgs,
  GetPlanInfoArgs,
  PaymentDto, RetryPaymentArgs,
  UpdateTaxArgs
} from 'booksprout'
import { gql } from '@apollo/client/core'
import { inject } from 'inversify-props'
import { ApolloClientServiceInterface } from '../_base/apollo/apolloClient.service.interface'

export class PaymentService extends BaseApolloClientService implements PaymentServiceInterface {
  @inject('ApolloClientService')
  public readonly apolloClientService!: ApolloClientServiceInterface

  readonly paymentFragments = {
    planInfo: gql`
      fragment PlanInfoFragment on PaymentDto {
        stripeCustomerId,
        stripeSubscriptionId,
        plan,
        price,
        cycle,
        nextBillDue,
        cardLast4,
        cardExpiry,
        taxCountry,
        taxCountryCode,
        taxCode,
        postalCode,
        cardType,
        cancelAt,
        discountPcApplied,
        discountAmountApplied,
        lastFailedPaymentId
        invoices {
          id,
          description,
          paidOn,
          cardLast4,
          downloadLink,
          cardType,
          status,
          paymentFailedReason,
          paymentFailedDescription,
          paymentFailedType
        }
      }
    `
  }

  get stripeKey (): string {
    return this.configService.config.stripeApiKey
  }

  get cycles () {
    return {
      year: 'year',
      month: 'month'
    }
  }

  globalLoader (show: boolean) {
    this.apolloClientService.showGlobalLoader = show
    return this
  }

  async initQuaderno (): Promise<never | void> {
    // @ts-ignore
    if (typeof Quaderno === 'undefined') {
      // make sure we capture quadernoInitError so Sentry can pick it up
      console.error('quadernoInitError')
      return Promise.reject('quadernoInitError')
    }

    // @ts-ignore
    if (!Quaderno.initialized) {
      // @ts-ignore
      await Quaderno.init(this.configService.config.quadernoApiKey).then(() => {
        if (process.env.NODE_ENV === 'development') {
          console.log('Quaderno.js successfully initialized')
        }
        // @ts-ignore
        Quaderno.initialized = true
      }).catch((error: any) => {
        console.log(error.description, error.messages)
        return Promise.reject(error.messages)
      })
    }
  }

  async calculateTaxes (amount: number, countryCode: string, postalCode: string, taxNumber: string): Promise<CalculatedTax> {
    await this.initQuaderno()

    // @ts-ignore
    return Quaderno.calculateTaxes({
      country: countryCode,
      businessNumber: taxNumber,
      postalCode: postalCode
    }).then((taxObject: any) => {
      // @ts-ignore
      const calculatedPrice = Quaderno.calculatePrice({
        amount: amount * 100,
        quantity: 1,
        taxType: 'excluded'
      })

      return {
        rate: taxObject.rate,
        countryCode,
        name: taxObject.name,
        extraName: taxObject.extraName,
        extraRate: taxObject.extraRate,
        price: {
          total: calculatedPrice.total / 100,
          taxes: calculatedPrice.taxes / 100
        }
      }
    })
  }

  async isValidTaxNumber (taxNumber: string, countryCode: string): Promise<boolean> {
    await this.initQuaderno()

    // @ts-ignore
    return Quaderno.validateBusinessNumber(taxNumber, countryCode.toUpperCase()).then((response) => {
      return response.valid
    }).catch((error: any) => {
      return false
    })
  }

  async subscribe (data: CreateSubscriptionFromTokenData): Promise<CrudSubscriptionResponseDto> {
    return this.apolloClientService.mutate({
      variables: {
        cardBrand: data.tokenData.card.brand,
        cardCountry: data.tokenData.card.country,
        cardId: data.tokenData.card.id,
        cardExpMonth: data.tokenData.card.expMonth,
        cardExpYear: data.tokenData.card.expYear,
        cardLast4: data.tokenData.card.last4,
        taxRate: data.tokenData.amount.rate,
        extraTaxRate: data.tokenData.amount.extraRate,
        subtotal: data.tokenData.amount.subtotal,
        taxCountryCode: data.tokenData.amount.taxCountryCode,
        taxAmount: data.tokenData.amount.taxes,
        total: data.tokenData.amount.total,
        token: data.tokenData.token,
        postalCode: data.tokenData.address.postalCode,
        plan: data.plan,
        planCycle: data.planCycle,
        taxCode: data.tokenData.taxCode,
        taxName: data.tokenData.taxName,
        extraTaxName: data.tokenData.extraTaxName,
        ipAddress: data.ipAddress
      },
      mutation: gql`
        mutation CreateSubscription (
          $cardBrand: String!
          $cardCountry: String!
          $cardId: String!
          $cardExpMonth: Int!
          $cardExpYear: Int!
          $cardLast4: String!
          $taxRate: Float!
          $extraTaxRate: Float
          $subtotal: Float!
          $taxCountryCode: String!
          $taxAmount: Float!
          $token: String!
          $total: Float!
          $postalCode: String!
          $plan: String!
          $planCycle: String!
          $taxCode: String!
          $taxName: String!
          $extraTaxName: String
          $ipAddress: String
        ) {
          createSubscription (
            cardBrand: $cardBrand
            cardCountry: $cardCountry
            cardId: $cardId
            cardExpMonth: $cardExpMonth
            cardExpYear: $cardExpYear
            cardLast4: $cardLast4
            taxRate: $taxRate
            extraTaxRate: $extraTaxRate
            subtotal: $subtotal
            taxCountryCode: $taxCountryCode
            taxAmount: $taxAmount
            token: $token
            total: $total
            postalCode: $postalCode
            plan: $plan
            planCycle: $planCycle
            taxCode: $taxCode,
            taxName: $taxName,
            extraTaxName: $extraTaxName,
            ipAddress: $ipAddress
          ) {
            actionUrl,
            actionUrlType,
            clientSecret,
            status
          }
        }
      `
    }).then((result: any) => {
      return result
    })
  }

  async getPlanInfo (args?: GetPlanInfoArgs): Promise<PaymentDto> {
    return this.apolloClientService.query({
      variables: {
        ...args
      },
      query: gql`
        query GetPlanInfo (
          $forUserId: Int,
          $skipInvoices: Boolean
        ) {
          planInfo (
            skipInvoices: $skipInvoices,
            forUserId: $forUserId
          ) {
            ...PlanInfoFragment
          }
        }
        ${this.paymentFragments.planInfo}
      `
    })
  }

  async changeSubscriptionSummary (args: ChangeSubscriptionArgs): Promise<ChangePlanSummaryDto> {
    return this.apolloClientService.query({
      variables: {
        ...args
      },
      query: gql`
        query ChangeSubscriptionSummary (
          $newPlan: String!
          $oldPlan: String!
          $newCycle: String!
          $proratedDate: Int
        ) {
          changeSubscriptionSummary (
            newPlan: $newPlan,
            newCycle: $newCycle,
            oldPlan: $oldPlan,
            proratedDate: $proratedDate
          ) {
            subtotal,
            tax,
            total,
            credit,
            taxCountryCode,
            proratedDate,
            taxCode,
            postalCode
          }
        }
      `
    })
  }

  async changeSubscription (args: ChangeSubscriptionArgs): Promise<CrudSubscriptionResponseDto> {
    return this.apolloClientService.mutate({
      variables: {
        ...args
      },
      mutation: gql`
        mutation changeSubscription (
          $newPlan: String!
          $oldPlan: String!
          $newCycle: String!
          $proratedDate: Int
          $ipAddress: String
        ) {
          changeSubscription (
            newPlan: $newPlan,
            newCycle: $newCycle,
            oldPlan: $oldPlan,
            proratedDate: $proratedDate,
            ipAddress: $ipAddress
          ) {
            actionUrl,
            actionUrlType,
            clientSecret,
            status
          }
        }
      `
    })
  }

  async cancelSubscription (args: CancelSubscriptionArgs): Promise<PaymentDto> {
    return this.apolloClientService.mutate({
      variables: {
        ...args
      },
      mutation: gql`
        mutation CancelSubscription (
          $reason: Int,
          $reasonDescription: String
        ) {
          cancelSubscription (
            reason: $reason,
            reasonDescription: $reasonDescription
          ) {
            ...PlanInfoFragment
          }
        }
        ${this.paymentFragments.planInfo}
      `
    })
  }

  async removeCreditCard (): Promise<PaymentDto> {
    return this.apolloClientService.mutate({
      mutation: gql`
        mutation RemoveCreditCard {
          removeCreditCard {
            ...PlanInfoFragment
          }
        }
        ${this.paymentFragments.planInfo}
      `
    })
  }

  addCreditCard (tokenData: CreditCardTokenData): Promise<PaymentDto> {
    return this.apolloClientService.mutate({
      variables: {
        cardBrand: tokenData.card.brand,
        cardCountry: tokenData.card.country,
        cardId: tokenData.card.id,
        cardExpMonth: tokenData.card.expMonth,
        cardExpYear: tokenData.card.expYear,
        cardLast4: tokenData.card.last4,
        taxRate: 0,
        subtotal: 0,
        taxCountryCode: '',
        taxAmount: 0,
        total: 0,
        token: tokenData.token,
        postalCode: tokenData.address.postalCode,
        plan: '',
        planCycle: '',
        taxCode: '',
        taxName: ''
      },
      mutation: gql`
        mutation AddCreditCard (
          $cardBrand: String!
          $cardCountry: String!
          $cardId: String!
          $cardExpMonth: Int!
          $cardExpYear: Int!
          $cardLast4: String!
          $taxRate: Float!
          $subtotal: Float!
          $taxCountryCode: String!
          $taxAmount: Float!
          $token: String!
          $total: Float!
          $postalCode: String!
          $plan: String!
          $planCycle: String!
          $taxCode: String!
          $taxName: String!
        ) {
          addCreditCard (
            cardBrand: $cardBrand
            cardCountry: $cardCountry
            cardId: $cardId
            cardExpMonth: $cardExpMonth
            cardExpYear: $cardExpYear
            cardLast4: $cardLast4
            taxRate: $taxRate
            subtotal: $subtotal
            taxCountryCode: $taxCountryCode
            taxAmount: $taxAmount
            token: $token
            total: $total
            postalCode: $postalCode
            plan: $plan
            planCycle: $planCycle
            taxCode: $taxCode,
            taxName: $taxName
          ) {
            ...PlanInfoFragment
          }
        }
        ${this.paymentFragments.planInfo}
      `
    }).then((result: any) => {
      return result
    })
  }

  updateTaxInformation (args: UpdateTaxArgs): Promise<ChangePlanSummaryDto> {
    return this.apolloClientService.mutate({
      variables: {
        ...args
      },
      mutation: gql`
        mutation CreateSubscription (
          $taxRate: Float!
          $extraTaxRate: Float
          $taxCountryCode: String!
          $postalCode: String!
          $taxCode: String!
          $taxName: String!
          $extraTaxName: String
        ) {
          updateTaxInfo (
            taxRate: $taxRate
            extraTaxRate: $extraTaxRate
            taxCountryCode: $taxCountryCode
            postalCode: $postalCode
            taxCode: $taxCode
            taxName: $taxName
            extraTaxName: $extraTaxName
          ) {
            subtotal,
            tax,
            total,
            credit,
            taxCountryCode,
            taxCode,
            postalCode
          }
        }
      `
    }).then((result: any) => {
      return result
    })
  }

  reactivateCurrentPlan (): Promise<PaymentDto> {
    return this.apolloClientService.mutate({
      mutation: gql`
        mutation ReactivateCurrentPlan {
          reactivateCurrentPlan {
            ...PlanInfoFragment
          }
        }
        ${this.paymentFragments.planInfo}
      `
    }).then((result: any) => {
      return result
    })
  }

  retryPayment (args: RetryPaymentArgs): Promise<PaymentDto> {
    console.log('Retry payment', args)
    return this.apolloClientService.mutate({
      variables: {
        ...args,
      },
      mutation: gql`
        mutation RetryPayment (
          $invoiceId: String!
        ) {
          retryPayment (
            invoiceId: $invoiceId
          ) {
            ...PlanInfoFragment
          }
        }
        ${this.paymentFragments.planInfo}
      `
    }).then((result: any) => {
      return result
    })
  }

  async getCouponDiscount (args: GetCouponArgs): Promise<number> {
    return this.apolloClientService.query({
      variables: {
        ...args
      },
      query: gql`
        query GetCouponDiscount (
          $forFp: Boolean
          $cycle: String
          $plan: String
        ) {
          getCouponDiscount (
            forFp: $forFp,
            cycle: $cycle
            plan: $plan
          )
        }
      `
    }).then((result: any) => {
      return result
    })
  }
}
