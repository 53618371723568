import { CreateElement } from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import BsField from '../field/BsField'
import { ValueLabel } from '../types'

@Component
export default class BsToggle extends BsField {
  // We're handling this manually.
  protected autoSaveMethod = ''

  @Prop({
    type: [String, Number],
    validator: prop =>
      typeof prop === 'string' ||
      typeof prop === 'number' ||
      prop === null
  }) public readonly value!: string | number
  @Prop() public readonly options!: ValueLabel[]

  @Prop({ type: Boolean, default: true }) public readonly evenSpacing!: boolean
  @Prop({ type: String }) public readonly toggleWrapperClass!: string | undefined
  @Prop({ type: String }) public readonly toggleClass!: string | undefined
  @Prop({ type: Boolean }) public readonly navigable!: boolean

  public __renderContainer (h: CreateElement) {
    return h('div', {
      staticClass: 'full-width',
      class: this.toggleWrapperClass
    }, [
      this.__renderToggle(h)
    ])
  }

  public __renderToggle (h: CreateElement) {
    return h('div', {
      staticClass: 'bs-toggle row',
      class: this.toggleClass
    }, this.__renderOptions(h))
  }

  public __renderOptions (h: CreateElement) {
    return this.options.map(option => this.__renderOption(h, option))
  }

  public updateUrl (step: string | number | undefined) {
    if (this.navigable) {
      // Update the url so when the page is refreshed it loads the same tab that the user was on.
      if (history.pushState && step !== void 0) {
        const index = window.location.href.indexOf('#')
        const newUrl = index === -1 ? window.location.href : window.location.href.substr(0, window.location.href.indexOf('#'))
        history.pushState(null, '', newUrl + `#${step}`)
      }
    }
  }

  public __renderOption (h: CreateElement, option: ValueLabel) {
    return h('div', {
      staticClass: 'bs-toggle__option col',
      class: [{
        'bs-toggle__option--active': this.value === option.value,
        'bs-toggle__option--even-spacing': this.evenSpacing,
        'bs-toggle__option--disabled': option.disable
      }],
      on: {
        click: () => {
          if (!option.disable) {
            this.$emit('input', option.value) // Input the value before saving so the v-model is usable in beforeSave
            this.updateUrl(option.value)
            this.doAutoSave()
          }
        }
      },
      attrs: this.getAttributes()
    }, [
      option.label
    ])
  }

  public __getControl (h: CreateElement) {
    return this.__renderContainer(h)
  }

  public __getFieldClass () {
    return 'bs-toggle__container'
  }

  public mounted () {
    if (this.navigable) {
      const hash = this.$route.hash
      if (hash !== void 0 && hash !== '') {
        this.$emit('input', parseInt(hash.replace('#', '')))
      }
    }
  }
}
