import { CreateElement } from 'vue'
import { Component } from 'vue-property-decorator'
import { QBtnDropdown } from 'quasar'

// Mixins
import BsBtn from './BsBtn'

@Component({
  name: 'BsBtnDropdown',
  inheritAttrs: false
})
export default class BsBtnDropdown extends BsBtn {
  getProps (): {} {
    return {
      dropdownIcon: 'app:arrow-down'
    }
  }

  render (h: CreateElement) {
    return h(QBtnDropdown, {
      staticClass: 'bs-btn bs-btn-dropdown', // So it uses the styles from BsBtn
      class: this.getBtnClasses(),
      props: {
        ...this.getBtnProps(),
        ...this.getProps()
      },
      on: this.getListeners(),
      attrs: this.getAttributes({...this.$attrs})
    }, [
      this.__renderActiveIcon(h),
      this.slot(this, 'default')
    ])
  }
}
