import { CreateElement } from 'vue'
import { Component, Prop } from 'vue-property-decorator'

// Mixins
import BsBtn from './BsBtn'
import { QIcon } from 'quasar'

@Component
export default class BsFilterBtn extends BsBtn {
  @Prop({ type: Number }) readonly filterCount!: number
  @Prop({ type: Boolean }) readonly filtersShowing!: boolean

  __renderIcon (h: CreateElement) {
    return h(QIcon, {
      props: {
        name: 'app:filter',
        size: '1.125rem'
      }
    })
  }

  __renderText (h: CreateElement) {
    const text = this.filterCount > 0
      ? this.$tc('system.actions.filtersX', void 0, [this.filterCount])
      : this.$tc('system.actions.filters')

    return h('span', text)
  }

  getProps (): {} {
    return {
      textColor: 'black'
    }
  }

  render (h: CreateElement) {
    return h(BsBtn, {
      staticClass: 'bs-filter-btn',
      class: {
        'bs-filter-btn__active': this.filtersShowing
      },
      props: {
        ...this.getBtnProps(),
        ...this.getProps()
      },
      on: this.getListeners(),
      attrs: this.getAttributes({
        ...this.$attrs,
        'data-cy': 'btnFilters'
      })
    }, [
      this.__renderIcon(h),
      this.$q.screen.gt.xs && this.__renderText(h)
    ])
  }
}
