import { ArcWebStatsDto } from 'booksprout'

export interface WebsiteStatsState {
  stats?: ArcWebStatsDto
}

const resetWebsiteStatsState = (): WebsiteStatsState => {
  return {
    stats: void 0
  }
}

export default (): WebsiteStatsState => ({
  ...resetWebsiteStatsState()
})

export { resetWebsiteStatsState }
