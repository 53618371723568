// Utils
import { Component, Prop } from 'vue-property-decorator'
import { CreateElement } from 'vue/types/vue'

// Mixins
import CommonMixin from '../mixins/common'
import { validStr } from 'booksprout'

@Component
export default class BsTabStepperTitle extends CommonMixin {
  @Prop({ type: String }) public readonly title!: string
  @Prop({ type: String }) public readonly description!: string
  @Prop({ type: String }) public readonly hint!: string

  public __renderTitle (h: CreateElement) {
    if (!validStr(this.title)) return void 0

    return h('h1', {
      staticClass: 'bs-stepper-title__title',
      domProps: {
        innerHTML: this.title
      }
    })
  }

  public __renderDescription (h: CreateElement) {
    if (!validStr(this.description)) return void 0

    return h('div', {
      staticClass: 'bs-stepper-title__description',
      domProps: {
        innerHTML: this.description
      }
    })
  }

  public __renderHint (h: CreateElement) {
    if (!validStr(this.hint)) return void 0

    return h('div', {
      staticClass: 'bs-stepper-title__hint',
      domProps: {
        innerHTML: this.hint
      }
    })
  }

  public __renderTitleContainer (h: CreateElement) {
    return h('div', {
      staticClass: 'bs-stepper-title__content'
    }, [
      this.__renderTitle(h),
      this.__renderDescription(h),
      this.__renderHint(h),
    ])
  }

  public render (h: CreateElement) {
    return h('div', {
      staticClass: 'bs-stepper-title',
    }, [
      this.__renderTitleContainer(h),
      h('div', {
        staticClass: 'bs-stepper-title__actions'
      }, this.slot(this, 'default'))
    ])
  }
}
