import { GetterTree } from 'vuex'
import { UIState } from './types'
import { BaseRootState } from '../../baseRootState'

export const getters: GetterTree<UIState, BaseRootState> = {
  getNotifications (state) {
    return state.notifications
  },
  getUnreadMessageCount (state) {
    return state.unreadMessageCount
  },
  getUnreadNotificationCount (state) {
    return state.unreadNotificationCount
  },
  getRedirectData (state) {
    return state.redirect
  },
  getTriggerLoginNotification (state) {
    return state.triggerLoginNotification
  },
  getShowMessageDialog (state) {
    return state.showMessageDialog
  },
  getUserStats (state) {
    return state.userStats
  },
  getIsReadyToRender (state) {
    return state.isReadyToRender
  },
  getLastError (state) {
    return state.lastError
  }
}
