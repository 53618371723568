import { ActionTree } from 'vuex'
import { SystemState } from './types'
import { CLEAR_RETURN_LOCATION, SET_META, SET_RETURN_MODEL } from '../../actions/system'
import { BaseRootState } from '../../baseRootState'

/**
 * NOTE: This module (UI) will not be loaded into the store when testing services so this can do things with the
 * front end i.e Routing.
 */

export const actions: ActionTree<SystemState, BaseRootState> = {
  [SET_RETURN_MODEL] ({ commit }, payload) {
    commit(SET_RETURN_MODEL, payload)
  },
  [CLEAR_RETURN_LOCATION] ({ commit }) {
    commit(CLEAR_RETURN_LOCATION)
  },
  [SET_META] ({ commit }, payload) {
    commit(SET_META, payload)
  }
}

