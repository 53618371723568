import { Component } from 'vue-property-decorator'
import { BaseModel } from '../../base.model'
import { BaseCrudServiceInterface } from '../../baseCrud.service.interface'
import { Getter } from 'vuex-class'
import { CLEAR_RETURN_LOCATION } from '../../../../store/actions/system'
import { validStr } from 'booksprout'
import { CommonBaseMixin } from '../../../../components/mixins/base'

// @ts-ignore
@Component
export abstract class CommonBaseModuleMixin<TModel extends BaseModel> extends CommonBaseMixin {
  @Getter('getReturnLocation') public returnLocation!: string
  @Getter('getReturnLabel') public stateReturnLabel!: string
  @Getter('getCompleteLabel') public stateCompleteLabel!: string

  public abstract crudService: BaseCrudServiceInterface<TModel>
  public abstract urlModelName: string

  public createUrl () {
    return this.linkService.adminCreate(this.urlModelName)
  }

  public editUrl (id: any) {
    return this.linkService.adminUpdate(this.urlModelName, id)
  }

  public deleteUrl (id: number) {
    return this.linkService.adminDelete(this.urlModelName, id)
  }

  public listUrl () {
    return this.linkService.adminList(this.urlModelName)
  }

  public viewUrl (id: any) {
    return this.linkService.adminView(this.urlModelName, id)
  }

  /**
   * Go back one step OR (if set) back to the return url in the state if we were in the middle of creating another model.
   */
  public goBack (fallbackRoute?: string) {
    if (validStr(this.returnLocation)) {
      this.$router.push(this.returnLocation).catch(() => {
        // do nothing, routeGuard redirected user
        // fixes "Uncaught (in promise) undefined" error
      })
      this.$store.dispatch(CLEAR_RETURN_LOCATION)
    } else if (fallbackRoute !== void 0 && validStr(fallbackRoute)) {
      this.$router.push(fallbackRoute).catch(() => {
        // do nothing, routeGuard redirected user
        // fixes "Uncaught (in promise) undefined" error
      })
    } else {
      this.$router.go(-1)
    }
  }

  /**
   * Get the label used for "go back to XX list". This will read the label set on the state if we're going to be
   * redirected back to a model we were creating
   * @param defaultLabel
   */
  public returnLabel (defaultLabel: string) {
    return validStr(this.stateReturnLabel) ? this.stateReturnLabel : defaultLabel
  }

  public doneLabel (defaultLabel: string) {
    return validStr(this.stateCompleteLabel) ? this.stateCompleteLabel : defaultLabel
  }
}
