import { ActionTree } from 'vuex'

import { AuthRequiredData, ShowMessageDialogData, StoreRedirectData, UIState } from './types'
import {
  CLEAR_MESSAGE_DIALOG,
  CLEAR_NOTIFICATIONS,
  NOTIFY_ERROR,
  REDIRECT_TO,
  SET_UNREAD_MESSAGE_COUNT, SET_USER_STATS,
  SHOW_LOGIN_NOTIFICATION, SHOW_MESSAGE_DIALOG, SET_UNREAD_NOTIFICATION_COUNT, SET_READY_TO_RENDER, SET_LAST_ERROR
} from '../../actions/ui'
import { AUTH_REQUIRED, CLEAR_AUTH_REQUIRED } from '../../actions/auth'
import { ERROR_GENERIC } from '../../actions/error'
import { BaseRootState } from '../../baseRootState'

/**
 * NOTE: This module (UI) will not be loaded into the store when testing services so this can do things with the
 * front end i.e Routing.
 */

export const actions: ActionTree<UIState, BaseRootState> = {
  [NOTIFY_ERROR] ({ commit }, message) {
    commit(NOTIFY_ERROR, message)
  },
  [CLEAR_NOTIFICATIONS] ({ commit }) {
    commit(CLEAR_NOTIFICATIONS)
  },
  [AUTH_REQUIRED] ({ commit }, data: AuthRequiredData) { // This is handled here as the UI module isn't loaded into the store for tests.
    const forwardToEncoded = data ? encodeURIComponent(data.forwardUrl) : 'undefined'
    const forwardUrl = forwardToEncoded !== 'undefined' ? '/login?forward=' + forwardToEncoded : ''

    if (data.router) {
      data.router.push(forwardUrl)
    } else {
      commit(SHOW_LOGIN_NOTIFICATION, {
        show: true,
        forwardUrl: forwardUrl
      })
    }
  },
  [CLEAR_AUTH_REQUIRED] ({ commit }) {
    commit(SHOW_LOGIN_NOTIFICATION, {
      show: false,
      forwardUrl: ''
    })
  },
  [ERROR_GENERIC] ({ commit }, error) {
    // TODO: This will need some more work.
    commit(NOTIFY_ERROR, error.message)
  },
  [SET_UNREAD_MESSAGE_COUNT] ({ commit }, count) {
    commit(SET_UNREAD_MESSAGE_COUNT, count)
  },
  [SET_UNREAD_NOTIFICATION_COUNT] ({ commit }, unreadCountDto) {
    commit(SET_UNREAD_NOTIFICATION_COUNT, unreadCountDto)
  },
  [REDIRECT_TO] ({ commit }, redirectionData: StoreRedirectData) {
    commit(REDIRECT_TO, redirectionData)
  },
  [SHOW_MESSAGE_DIALOG] ({ commit }, data: ShowMessageDialogData) {
    commit(SHOW_MESSAGE_DIALOG, {
      ...data,
      show: true
    })
  },
  [CLEAR_MESSAGE_DIALOG] ({ commit }) {
    commit(SHOW_MESSAGE_DIALOG, {
      show: false
    })
  },
  [SET_USER_STATS] ({ commit }, stats) {
    commit(SET_USER_STATS, stats)
  },
  [SET_READY_TO_RENDER] ({commit}, isReadyToRender) {
    commit(SET_READY_TO_RENDER, isReadyToRender)
  },
  [SET_LAST_ERROR] ({commit}, error) {
    commit(SET_LAST_ERROR, error)
  }
}

