import { Cookies } from 'quasar'
import { QSsrContext } from '@quasar/app'
import { Store } from 'vuex'
import { RootState } from '../../store/types'
import { AxiosService } from 'booksprout-app'
import { ApolloClientService } from 'booksprout-app'
import { AuthService } from 'booksprout-app'
import { StoreService } from 'booksprout-app'
import { UserServiceInterface } from 'booksprout-app'
import { UserService } from 'booksprout-app'
import { ConfigService } from 'booksprout-app'
import { LinkService } from 'booksprout-app'
import { bsConstants, UserDto } from 'booksprout'
import { i18n } from '../../boot/i18n'
import VueI18n from 'vue-i18n'
import { container } from 'booksprout-app'

export default (ssrContext: QSsrContext, store: Store<RootState>) => {
  const cookies = process.env.SERVER
    ? Cookies.parseSSR(ssrContext)
    : Cookies

  container.addSingleton<AxiosService>(AxiosService, 'AxiosService')
  container.addSingleton<ApolloClientService>(ApolloClientService, 'ApolloClientService')
  container.addSingleton<AuthService>(AuthService, 'AuthService')

  container.bind<VueI18n>("TranslationService").toConstantValue(i18n)

  container.addSingleton<LinkService>(LinkService, 'LinkService')
  const linkService = container.get<LinkService>('LinkService')
  linkService.configure(bsConstants.APP.HOME_URLS.WEBSITE)

  container.addSingleton<ConfigService>(ConfigService, 'ConfigService')
  const configService = container.get<ConfigService>('ConfigService')

  // Work out which config we need to be using based on the build env
  let configBuild = 'dev'
  let configMode: string | undefined
  switch (process.env.ENVIRONMENT) {
    case 'build':
      configBuild = 'build'
      configMode = process.env.BUILD_MODE
      break
    case 'testing':
      configBuild = 'testing'
      break
  }

  const configFile = require(`../../../config.${configBuild}${configMode ? '.' + configMode : ''}.js`).default
  configService.configure(configFile)

  container.addSingleton<StoreService>(StoreService, 'StoreService')
  const storeService = container.get<StoreService>('StoreService')
  storeService.configure(store)
  store.$cookies = cookies

  container.addTransient<UserServiceInterface<UserDto>>(UserService, 'UserService')
}
