import { CreateElement } from 'vue'
import { Component } from 'vue-property-decorator'
import { QToggle } from 'quasar'

// Mixins
import { BaseBsComponentMixin } from '../mixins/common'

@Component
export default class BsCheckToggle extends BaseBsComponentMixin {
  // This combination forces the auto save on each click
  public autoSaveMethod = ''
  public forceTriggerOnChange = true

  public isComponentIsValid (val?: any) {
    return val === true
  }

  public render (h: CreateElement): any {
    return h(QToggle, {
      staticClass: 'bs-check-toggle flex-start',
      props: this.getRenderProps({
        keepColor: true,
        color: this.$attrs.color || 'bs-lb-m'
      }),
      on: this.getListeners(),
      scopedSlots: this.getScopedSlots(h),
      attrs: this.getAttributes()
    })
  }
}
