import { CreateElement } from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { QEditor } from 'quasar'

// Mixins
import BsField from '../field/BsField'

@Component
export default class BsEditor extends BsField {
  @Prop( { type: Boolean }) readonly limitedToolbar!: boolean

  protected __getFieldClass (): string {
    return 'bs-editor'
  }

  protected __getControl (h: CreateElement) {
    if (this.$attrs.value === null) {
      this.$emit('input', '')
      return void 0
    } else {
      const props = {
        toolbar: this.getEditorToolbar(),
        value: this.$attrs.value || '',
        paragraphTag: 'p'
      }

      const listeners = {
        input: (value: string) => {
          const regex = /style=".*?"/mg

          this.$emit('input', value.replace(regex, ''))
        }
      }

      return h(QEditor, {
        staticClass: 'bs-editor full-width',
        class: this.getClasses(),
        props: this.getRenderProps(props),
        on: this.getListeners(listeners),
        attrs: this.getAttributes()
      }, this.slot(this, 'default'))
    }
  }

  private getEditorToolbar () {
    const allowedTags = this.limitedToolbar ? ['p'] : ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p']
    const allowedFontSizes = this.limitedToolbar ?
      [
        {
          label: this.$q.lang.editor.fontSize,
          icon: this.$q.iconSet.editor.fontSize,
          fixedLabel: true,
          fixedIcon: true,
          list: 'no-icons',
          options: [
            'size-1',
            'size-2',
            'size-3',
            'size-4',
            'size-5',
            'size-6',
            'size-7'
          ]
        }
      ] : void 0
    const allowedTagStyles = ['bold', 'italic', 'underline']
    const allowedListTypes = ['unordered', 'ordered']
    const allowedOtherTags = ['hr', 'removeFormat']
    const allowedViewSource = ['viewsource']
    // don't add empty or undefined items to the array
    // quasar validation will kick in if we do so
    const toolbar = []

    toolbar.push(allowedTags)

    if (allowedFontSizes) {
      toolbar.push(allowedFontSizes)
    }

    toolbar.push(allowedTagStyles)
    toolbar.push(allowedListTypes)
    toolbar.push(allowedOtherTags)

    if (allowedViewSource) {
      toolbar.push(allowedViewSource)
    }

    return toolbar
  }

  created () {
    this.$q.iconSet.editor.heading1 = 'mdi-format-header-1'
    this.$q.iconSet.editor.heading2 = 'mdi-format-header-2'
    this.$q.iconSet.editor.heading3 = 'mdi-format-header-3'
    this.$q.iconSet.editor.heading4 = 'mdi-format-header-4'
    this.$q.iconSet.editor.heading5 = 'mdi-format-header-5'
    this.$q.iconSet.editor.heading6 = 'mdi-format-header-6'
  }

  mounted () {
    // this is used by quasar too, but is executed on created() hook
    // which is a bit too early for us
    document.execCommand('defaultParagraphSeparator', false, 'p');
  }
}
