import { RouteConfig } from 'vue-router'

const routes: RouteConfig[] = [
  {
    path: '',
    meta: {
      requiresAuth: false
    },
    component: () => import(/* webpackChunkName: "common-app.layouts.not-logged-in" */'booksprout-app/src/areas/notLoggedIn/layouts/Default.vue'),
    children: [
      {
        path: '',
        meta: { metaInstructions: { module: 'login' } },
        component: () => import(/* webpackChunkName: "common-app.pages.login" */'booksprout-app/src/areas/notLoggedIn/pages/Login.vue')
      },
      { path: 'logout', component: () => import(/* webpackChunkName: "common-app.pages.logout" */'booksprout-app/src/areas/notLoggedIn/pages/Logout.vue') },
      {
        path: 'login',
        name: 'login',
        meta: { metaInstructions: { module: 'login' } },
        component: () => import(/* webpackChunkName: "common-app.pages.login" */'booksprout-app/src/areas/notLoggedIn/pages/Login.vue')
      },
      {
        path: 'sign-up/:stage?/:tab?',
        name: 'sign-up',
        meta: { metaInstructions: { module: 'signUp' } },
        component: () => import(/* webpackChunkName: "common-app.pages.sign-up" */'booksprout-app/src/areas/notLoggedIn/pages/signup/SignUp.vue')
      },
      {
        path: 'request-password-reset',
        meta: { metaInstructions: { module: 'requestPasswordReset' } },
        component: () => import(/* webpackChunkName: "common-app.pages.request-password" */'booksprout-app/src/areas/notLoggedIn/pages/ForgotPassword.vue')
      },
      {
        path: 'complete-password-reset/:token',
        meta: { metaInstructions: { module: 'completePasswordReset' } },
        component: () => import(/* webpackChunkName: "common-app.pages.request-password" */'booksprout-app/src/areas/notLoggedIn/pages/ForgotPassword.vue')
      },
      {
        path: 'site/verify-email',
        redirect: to => {
          return `sign-up/verify-email/${to.query.key}`
        }
      },
      {
        path: 'site/email-unsubscribe',
        component: () => import(/* webpackChunkName: "common-app.pages.unsubscribe" */'booksprout-app/src/areas/notLoggedIn/pages/UserEmailActions.vue')
      },
      {
        path: '403',
        meta: { metaInstructions: { module: '403' } },
        name: '403',
        component: () => import(/* webpackChunkName: "common-app.pages.error-403" */'booksprout-app/src/pages/httpError/403.vue')
      },
      {
        path: '404',
        meta: { metaInstructions: { module: '404' } },
        name: '404',
        component: () => import(/* webpackChunkName: "common-app.pages.error-404" */'booksprout-app/src/pages/httpError/404.vue')
      }
    ]
  }
]

// Always leave this as last one
if (process.env.MODE !== 'ssr') {
  routes.push({
    path: '*',
    component: () => import('booksprout-app/src/pages/httpError/404.vue')
  })
}

const baseRootPathRoutes = [
  {
    name: 'message',
    path: 'messages',
    meta: {
      requiresAuth: true,
      role: {
        action: 'read',
        module: 'message'
      },
      metaInstructions: {
        module: 'messages'
      }
    },
    component: () => import('booksprout-app/src/modules/messages/pages/Index.vue'),
    children: [
      {
        path: 'thread/:id',
        meta: {
          requiresAuth: true
        },
        component: () => import('booksprout-app/src/modules/messages/pages/Index.vue')
      },
      {
        path: ':state/:userId?',
        meta: {
          requiresAuth: true
        },
        component: () => import('booksprout-app/src/modules/messages/pages/Index.vue')
      }
    ]
  }
]

export { routes, baseRootPathRoutes }
