import { inject } from 'inversify-props'
import { ConfigService } from '../config.service'
import { BaseApolloClientService } from './baseApolloClient.service'
import VueI18n from 'vue-i18n'
import { StoreServiceInterface } from '../store.service.interface'
import { BaseRootState } from '../../../store/baseRootState'

export class ApolloClientService extends BaseApolloClientService {
  public constructor (
    @inject('StoreService') storeService: StoreServiceInterface<BaseRootState>,
    @inject('ConfigService') configService: ConfigService,
    @inject('TranslationService') translationService: VueI18n
  ) {
    super(storeService, configService, translationService)
  }
}
