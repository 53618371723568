import { CreateElement } from 'vue'
import { Component, Prop } from 'vue-property-decorator'

// Components
import { QIcon } from 'quasar'
import { BsClickable, BsTabStepper } from '../index'

// Mixins
import CommonMixin from '../mixins/common'
import { BsTabDefinition } from '../types'

@Component
export default class BsTabbedLayout extends CommonMixin {
  @Prop({ type: Number }) readonly value!: number
  @Prop({ type: Array }) readonly tabs!: BsTabDefinition[]
  @Prop({ type: String }) readonly title!: string
  @Prop({ type: Boolean }) readonly nextStepNavigationOnly!: boolean
  @Prop({ type: Boolean }) readonly disabled!: boolean
  @Prop({ type: String }) readonly stepTitle!: string

  __renderHeader (h: CreateElement) {
    return h('h2', {
      staticClass: 'bs-h4 bs-tabbed-layout__header'
    }, [this.title])
  }

  __renderTab (option: BsTabDefinition, h: CreateElement) {
    const
      isPastThisOption = option.value < this.value,
      isActive = option.value === this.value,
      isNextOption = option.value === this.value + 1

    return option.hidden ? void 0 : h(BsClickable, {
      staticClass: 'bs-tabbed-layout__tab',
      class: [{
        'bs-tabbed-layout__tab--past': isPastThisOption,
        'bs-tabbed-layout__tab--active': isActive,
        'bs-tabbed-layout__tab--future': isPastThisOption === false && !isActive,
        'bs-tabbed-layout__tab--next-option': isNextOption,
        'bs-tabbed-layout__tab--is-edit': option.isEditMode,
        'cursor-pointer': option.stepComplete
      }],
      on: {
        click: async () => {
          if (this.disabled) return
          let clickOption = option
          const currentOption = this.tabs.find(f => f.value === this.value)

          // Look for the tab we're going to and if it's a previous one, skip validation.
          const navigationAllowed = !this.nextStepNavigationOnly || // Continue if we don't care about limit navigation
            option.value <= this.value + 1 || // OR we're going backwards (assume other steps already done are ok)
            currentOption?.stepComplete // Or the step we're on is marked as done already

          if (navigationAllowed) {
            const requiresValidation = option !== void 0 ? this.value < option.value : true

            let outcome = true
            if (requiresValidation) {
              // @ts-ignore
              outcome = await this.$refs.bsTabStepper?.validateForm()
            }

            // If the user is trying to click past a step that is not yet complete, intercept and set current step to the next non complete one.
            for (const tab of this.tabs) {
              if (tab.stepComplete === false || tab.value === option?.value) {
                clickOption = tab
                break
              }
            }

            if (outcome || !requiresValidation) {
              // Update the url so when the page is refreshed it loads the same tab that the user was on.
              if (history.pushState) {
                const index = window.location.href.indexOf('#')
                const newUrl = index === -1 ? window.location.href : window.location.href.substr(0, window.location.href.indexOf('#'))
                history.pushState(null, '', newUrl + `#${clickOption.value}`)
              }
              this.$emit('input', clickOption.value)
            }
          }
        }
      }
    }, [
      this.__renderIcon(h, option, isPastThisOption, isActive),
      h('span', option.label)
    ])
  }

  __renderIcon (h: CreateElement, tab: BsTabDefinition, isPastThisOption: boolean, isActive: boolean) {
    if (tab.stepComplete !== false) {
      return h('div', {
        staticClass: 'bs-tabbed-layout__tab-count'
      }, [
        h(QIcon, {
          props: {
            name: 'app:check-filled',
            color: 'bs-g',
            size: '1.375rem'
          }
        })
      ])
    }

    if (isPastThisOption) {
      h(QIcon, {
        props: {
          name: 'app:check-filled',
          color: 'bs-g'
        }
      })
    }

    return h('div', {
      staticClass: 'bs-tabbed-layout__tab-count'
    }, [
      h(QIcon, {
        props: {
          name: isActive ? 'app:active' : isPastThisOption ? 'app:check-filled' : 'app:inactive',
          color: isActive || !isPastThisOption ? 'bs-grey-m' : 'bs-g',
          size: '1.375rem'
        }
      })
    ])
  }

  __renderTabs (h: CreateElement) {
    const node = []
    for (const option of this.tabs) {
      node.push(this.__renderTab(option, h))
    }

    return h('div', {
      staticClass: 'bs-tabbed-layout__tabs'
    }, [node])
  }

  __renderContent (h: CreateElement) {
    return h('div', {
      staticClass: 'bs-tabbed-layout__content'
    }, [
      this.__renderTabs(h),
      h('div', {
        staticClass: 'bs-tabbed-layout__inner'
      }, [
        this.stepTitle && h('h1', { staticClass: 'bs-h2' }, this.stepTitle),
        h(BsTabStepper, {
          ref: 'bsTabStepper',
          props: {
            value: this.value,
            tabs: this.tabs,
            ...this.$attrs
          },
          on: {
            input: (v: any) => this.$emit('input', v),
            done: (v: any) => this.$emit('done', v),
            'return': (v: any) => this.$emit('return')
          },
          scopedSlots: this.$scopedSlots
        }, [
          this.slot(this, 'default')
        ])
      ])
    ])
  }

  render (h: CreateElement) {
    return h('div', {
      staticClass: 'bs-tabbed-layout',
      class: {
        'bs-tabbed-layout--next-only': this.nextStepNavigationOnly,
        'bs-tabbed-layout--disabled': this.disabled
      }
    }, [
      this.__renderHeader(h),
      this.__renderContent(h)
    ])
  }

  created () {
    const hash = this.$route.hash
    if (hash !== void 0 && hash !== '') {
      this.$emit('input', parseInt(hash.replace('#', '')))
    }
  }
}
