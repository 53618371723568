// Keep this here. Needs to be on the first .ts page that's hit.
import 'reflect-metadata'
import { Component } from 'vue-property-decorator'
import { BaseApp } from 'booksprout-app'

require('promise.prototype.finally').shim()


@Component
export default class AppComponent extends BaseApp {
  get routerViewKey () {
    return this.$route.params.id || ''
  }
}
