import { CreateElement } from 'vue'
import { Component, Prop } from 'vue-property-decorator'

// Utils
import CommonMixin from '../mixins/common'

@Component
export default class BsChip extends CommonMixin {
  @Prop({ type: [String, Object] }) readonly color!: string | object
  @Prop({ type: String }) readonly textColor!: string

  get localClasses () {
    let result = ''

    if (this.textColor !== void 0) {
      result += ` text-${this.textColor}`
    } else {
      result += ' text-black'
    }

    if (this.color !== void 0) {
      if (typeof this.color === 'object') {
        const indexedColor: { [index: string]: any } = this.color
        const color = Object.keys(indexedColor).find(key => indexedColor[key] === true)
        result += ` bg-${color}`
      } else {
        result += ` bg-${this.color}`
      }
    }

    return result
  }

  render (h: CreateElement) {
    return h('span', {
      staticClass: 'bs-chip',
      class: this.localClasses,
      on: this.getListeners()
    }, this.slot(this, 'default'))
  }
}
