import { Component, Prop } from 'vue-property-decorator'
import { CreateElement } from 'vue'

import { QImg } from 'quasar'

@Component
export default class BsImg extends QImg {
  @Prop({ type: Boolean }) readonly native!: boolean
  @Prop({ type: Boolean }) readonly large!: boolean

  public get modifiedSrc () {
    if (!this.src) {
      return void 0
    }

    // return this.src
    let prefix
    let src = this.src + ''
    // Don't mess with full URLS i.e imported URLs with an image hosted on amazon
    if (src.startsWith('cdn') || src.startsWith('/cdn') || src.startsWith('\\cdn')) {
      // @ts-ignore // Ignore issue with PORT always being blah blah. It's just because we're using a const.
      if (['beta'].includes(process.env.BUILD_MODE + '')) {
        prefix = 'https://beta.booksprout.co'
      } else if (['sandbox'].includes(process.env.BUILD_MODE + '')) {
        prefix = 'https://sandbox.booksprout.co'
      } else if (['master'].includes(process.env.BUILD_MODE + '')) {
        prefix = 'https://booksprout.co'
      }

      if (prefix && !src.startsWith('/') && !src.startsWith('\\')) {
        prefix += '/'
      }

      // Books can have 2 thumbnail sizes so check to see if we want the large or normal sized one.
      if (this.large) {
        if (src.indexOf('uploads\\book\\thumbnails\\') > -1) {
          src = src.replace('uploads\\book\\thumbnails\\', 'uploads\\book\\thumbnails\\415\\')
        }

        if (src.indexOf('uploads/book/thumbnails/') > -1) {
          src = src.replace('uploads/book/thumbnails/', 'uploads/book/thumbnails/415/')
        }
      }
    }

    // normalize path for web
    src = src.replace(/\\/g, '/')

    return prefix ? `${prefix}${src}` : src
  }

  public render (h: CreateElement) {
    if (!this.native) {
      return h(QImg, {
        props: {
          placeholderSrc: this.placeholderSrc,
          src: this.modifiedSrc,
          width: this.width,
          height: this.height,
          sizes: this.sizes,
          srcset: this.srcset,
          imgClass: this.imgClass,
          imgStyle: this.imgStyle,
          spinnerColor: this.spinnerColor,
          spinnerSize: this.spinnerSize,
          ratio: this.ratio,
          alt: this.alt,
          contain: this.contain
        }
      })
    } else {
      const onImgErrorSmall = (event: any) => {
        event.target.src = this.placeholderSrc
        event.target.onerror = ''
      }

      return h('img', {
        attrs: {
          src: this.modifiedSrc,
          width: this.width,
          height: this.height,
          alt: this.alt,
        },
        on: {
          error: onImgErrorSmall
        }
      })
    }
  }
}
