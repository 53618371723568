import { Component, Prop } from 'vue-property-decorator'
import { CreateElement } from 'vue/types/vue'

// Mixins
import { CommonBaseMixin } from '../../mixins/base'
import { slot } from '../utils'
import { VNode } from 'vue'
import { BsBtn } from '../index'

@Component
export default class BsUpgrade extends CommonBaseMixin {
  @Prop({ type: String, required: true }) readonly role!: string
  @Prop({ type: String, required: true }) readonly permissionName!: string
  @Prop({ type: Object }) readonly params!: any
  /**
  ** Used for styling (renders component in horizontal mode)
  **/
  @Prop({ type: Boolean }) readonly inline!:string

  __renderTitle (h: CreateElement): VNode | undefined {
    const translationKey = `system.permissions.${this.permissionName}.title`

    if (!this.$te(translationKey)) {
      return void 0
    }

    const limit = this.params?.copyCountLimit === -1 ? this.$tc('system.labels.unlimited') : this.params?.copyCountLimit

    return h('strong', this.$t(translationKey, {
      maxParticipantCount: limit
    }).toString())
  }

  __renderContent (h: CreateElement, hasPermission: boolean): (VNode | undefined)[] {
    if (this.role === void 0) {
      return [void 0]
    }

    const roleFriendlyName = this.$tc(`system.labels.roles.author.${this.role}`)
    const explanationText = this.$t(`system.permissions.${this.permissionName}.explanation`, {
      planName: roleFriendlyName.toLowerCase(),
      maxRunningArcsCount: this.params?.maxRunningArcsCount
    })
    const settingsUrl = this.authenticatedUser.permissionQuery.isOnPaidPlan
      ? `/settings/billing/select-plan/${this.role}/month/upgrade`
      : '/settings/billing/select-plan'

    if (this.role !== void 0) {
      if (hasPermission) {
        return slot(this, 'default')
      } else {
        return [
          this.__renderTitle(h),
          h('span', explanationText.toString()),
          h(BsBtn, {
            props: {
              to: settingsUrl,
              label: this.$t('system.actions.upgrade')
            }
          })
        ]
      }
    }

    return [void 0]
  }

  render (h: CreateElement) {
    const hasPermission = this.hasTieredPermission(this.role)

    return h('div', {
      staticClass: hasPermission ? '' : 'bs-upgrade',
      class: !hasPermission && this.inline ? 'bs-upgrade__inline' : '',
      attrs: {
        'data-cy': !hasPermission && this.permissionName
      }
    }, this.__renderContent(h, hasPermission))
  }
}
