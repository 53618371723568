import { openURL } from 'quasar'
import { CommonLinkService, validStr } from 'booksprout'
import { IsReviewerApp } from '../../utils'

export class LinkService extends CommonLinkService {
  public homeUrl: string = ''

  constructor () {
    super()
    this.scopeToReviewer = IsReviewerApp()
  }

  configure (
    homeUrl: string
  ) {
    this.homeUrl = homeUrl
  }

  public goBack (fallbackUrl: string) {
    let useFallback = validStr(fallbackUrl)

    window.addEventListener("beforeunload", function(){
      useFallback = false
    })

    window.history.back()

    setTimeout(() => {
      if (useFallback) {
        window.location.href = fallbackUrl
      }
    }, 200)
  }

  openUrl (url: string | undefined) {
    if (!url) {
      return
    }

    openURL(url)
  }
}
