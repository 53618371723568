import { Store } from 'vuex'

import { StoreServiceInterface } from './store.service.interface'
import { BaseRootState } from '../../store/baseRootState'

export class StoreService<T extends BaseRootState = BaseRootState> implements StoreServiceInterface<T> {
  public store!: Store<T>

  public configure (store: Store<T>): void {
    this.store = store
  }
}
