const window = require('global/window')
import { ellipsis as commonEllipsis } from 'booksprout'

export const popupCenter = (args: { url: string, title: string, w: number, h: number }) => {
  const left = (window.outerWidth / 2) - (args.w / 2)
  const top = (window.outerHeight / 2) - (args.h / 2)
  const newWindow = window.open(args.url, args.title,
    `
      popup=true,
      width=${args.w},
      height=${args.h},
      top=${top},
      left=${left},
      title=${args.title},
      noopener=false
      `
  )

  newWindow?.focus()
  return newWindow
}

// checking pathname.startsWith() instead indexOf()
// to avoid getting true on urls like "/publisher/settings/reviewer"
export const IsReviewerApp = () => {
  let isReviewerApp = !!process.env.SERVER

  if (
    (window.location && window.location.pathname.startsWith('/reviewer')) ||
    (window.Capacitor && window.Capacitor.isNative)
  ) {
    isReviewerApp = true
  }

  return isReviewerApp
}

export const ellipsis = (text: string, length: number, ellipsisStr: string = '...', cb: any = void 0) => {
  return commonEllipsis(text, length, ellipsisStr, cb)
}
