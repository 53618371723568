import { CreateElement } from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { QField, QOptionGroup, QRadio } from 'quasar'

// Mixins
import { BaseBsComponentMixin } from '../mixins/common'

@Component
export default class BsRadio extends BaseBsComponentMixin {
  @Prop({ type: Boolean}) readonly stacked!: boolean
  @Prop({ type: Boolean}) readonly hideBottomSpace!: boolean

  // This combination forces the auto save on each click
  autoSaveMethod = ''
  stopSaveOnFirstSet = true
  forceTriggerOnChange = true

  render (h: CreateElement) {
    const props = {
      type: 'radio'
    }

    if (this.$attrs?.options) {
      return h(QField, {
        staticClass: 'bs-radio-container',
        class: this.getClasses(), // so we get auto save classes applied.
        props: this.getValidationProps({
          borderless: true,
          bottomSlots: true,
          noErrorIcon: true,
          dense: true,
          stackLabel: true,
          hideBottomSpace: this.hideBottomSpace
        }),
        scopedSlots: {
          error: () => this.__renderErrorSlot(h),
          ...this.__renderAutoSaveMessage(h)
        }
      }, [
        h(QOptionGroup, {
          staticClass: 'bs-radio',
          class: this.stacked ? 'bs-radio__stacked' : void 0,
          props: this.getRenderProps(props),
          on: this.getListeners(),
          attrs: this.getAttributes()
        }, this.slot(this, 'default'))
      ])
    } else {
      return h(QRadio, {
        staticClass: 'bs-radio',
        props: {
          ...this.getRenderProps(props)
        },
        on: this.getListeners(),
        attrs: this.getAttributes()
      }, this.slot(this, 'default'))
    }
  }
}
