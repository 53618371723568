import { CreateElement } from 'vue'
import { Component } from 'vue-property-decorator'
import { QCheckbox, QField } from 'quasar'

// Mixins
import { BaseBsComponentMixin } from '../mixins/common'

@Component
export default class BsCheckbox extends BaseBsComponentMixin {
  // This combination forces the auto save on each click
  public autoSaveMethod = ''
  public forceTriggerOnChange = true

  public isComponentIsValid (val?: any) {
    return val === true
  }

  public render (h: CreateElement): any {
    return h(QField, {
      ref: 'bsCheckbox',
      staticClass: 'bs-checkbox',
      class: this.getClasses({
        'bs-checkbox--dense': this.$attrs.dense !== void 0
      }), // so we get auto save classes applied.
      props: this.getValidationProps({
        borderless: true,
        bottomSlots: true,
        noErrorIcon: true,
        dense: true,
        stackLabel: true,
        hideBottomSpace: this.$attrs['hide-bottom-space'] !== void 0
      }),
      scopedSlots: {
        error: () => this.__renderErrorSlot(h),
        ...this.__renderAutoSaveMessage(h)
      }
    }, [
      h(QCheckbox, {
        staticClass: 'flex-start',
        props: this.getRenderProps({
          keepColor: true,
          color: this.$attrs.color || 'bs-g'
        }),
        on: this.getListeners(),
        scopedSlots: this.getScopedSlots(h),
        attrs: this.getAttributes()
      })
    ])
  }
}
