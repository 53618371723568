import * as Sentry from '@sentry/vue'
import { bsConstants } from 'booksprout'

// @ts-ignore
export default ({ router, Vue }) => {
  if (process.env.BUILD_MODE !== 'sandbox') {
    Sentry.init({
      Vue,
      dsn: 'https://2a7edcc1962045ebbdefd40ec71d30ba@o4504832385613824.ingest.sentry.io/4504835379101696',
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.captureConsoleIntegration({
          levels: ['error']
        })
      ],
      tracePropagationTargets: ['localhost', 'booksprout.co', /^\//],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.2,
      logErrors: true,
      environment: process.env.BUILD_MODE,
      ignoreErrors: [
        'ResizeObserver', // browser stuff
        'DataCloneError', // GA
        'Termly',
        /Non-Error promise rejection captured with keys: message/, // contains our handled bad request exception
        'Beacon', // Helpscout
        'NavigationDuplicated', // Vue router
        /timeout of 5000ms exceeded/, // axios timeouts
        'Loading chunk', // cached in users device
        'Loading CSS chunk' // cached in users device
      ],
      trackComponents: true,
      release: bsConstants.APP.VERSION,
      attachStacktrace: true,
      denyUrls: [
        // Google Adsense
        /pagead\/js/i,
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // browser extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /chrome-extension:/i,
        /safari-extension:/i,
        /safari-web-extension:/i,
        /moz-extension:/i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i,  // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        /app.termly.io/i,
        /beacon-v2.helpscout.net/i,
        /.maze.co/i,
        /.adblockultimate.net/i,
        /c.amazon-adsystem.com/i, // apstag extension by Amazon
        /anonymous/i, // masked urls? not sure how sentry gets this
        /webkit-masked-url/i, // iOS devices, ignoring as per https://github.com/getsentry/sentry-javascript/discussions/5875
        /w88p9x.com/i, // spam
        /google-analytics.com/i
      ],
      beforeSend (event) {
        const userAgent = event.request?.headers?.['User-Agent']
        const errors = event.exception?.values

        if (userAgent?.includes('BingPreview') || userAgent?.includes('facebook')) {
          return null
        }

        if (errors?.some((e) => e.type === 'NavigationDuplicated')) {
          return null
        }

        // user has been redirected to maintenance page, skip sending
        if (event.request?.url?.includes('maintenance')) {
          return null
        }

        // skip all anonymous errors (Chrome extensions, Google Analytics, etc.)
        if (errors?.some(e => e.stacktrace?.frames?.some(f => f.filename === '<anonymous>'))) {
          return null
        }

        // skip Termly errors
        if (
          event.message?.includes('Encountered %o when trying') ||
          event.message?.includes('Load config error: %O SyntaxError: Unexpected end of JSON input')
        ) {
          return null
        }

        // smartlook
        if (
          event.message?.includes('Event `Event` (type=error) captured as promise rejection') ||
          event.message?.includes('Event `Event` (type=unhandledrejection) captured as promise rejection')
        ) {
          return null
        }

        // skip all handled exceptions, adds too much unnecessary noise
        if (errors?.some((e) => e.mechanism?.handled)) {
          return null
        }

        if (errors?.some((e) => e.value?.includes('timeout of 5000ms exceeded'))) {
          return null
        }

        return event
      },
      beforeSendTransaction (event) {
        // if status is not there, means no errors happened, skip sending
        // further filtering will happen inside beforeSend and ignoreErrors
        if (!event.contexts?.trace?.status) {
          return null
        }

        return event
      }
    })
  }
}
