import { Module } from 'vuex'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'
import { WebsiteStatsState } from './state'
import { RootState } from '../../types'
import arcTeamState from './state'

export const stats: Module<WebsiteStatsState, RootState> = {
  state: arcTeamState,
  getters,
  mutations,
  actions
}
