import { ConfigInterface } from './config.interface'

export class ConfigService {
  public config!: ConfigInterface

  /**
   * Pass true to work out the config automatically or pass a config file to use.
   * @param config
   */
  public configure (config: ConfigInterface) {
    this.config = config as ConfigInterface
  }
}
