import { Component, Prop } from 'vue-property-decorator'
import { CommonBaseMixin } from './mixins/base'
import { QDialog } from 'quasar'

@Component
export default class MobileSearch extends CommonBaseMixin {
  @Prop({ type: String }) public readonly currentSearch!: string
  @Prop({ type: String }) public readonly searchKey!: string

  public search = ''

  public get dialog (): QDialog {
    return this.$refs.mobileDialog as QDialog
  }

  public show () {
    this.dialog.show()
  }

  public hide () {
    this.dialog.hide()
  }

  public onDialogHide () {
    this.$emit('hide')
  }

  public cancel () {
    this.hide()
  }

  public async confirm () {
    this.$emit('ok', this.search)
    this.hide()
  }

  public mounted () {
    this.search = this.currentSearch
  }
}
