// Utils
import { Component, Prop, Watch } from 'vue-property-decorator'
import { CreateElement } from 'vue/types/vue'
import { scroll } from 'quasar'
const { getScrollTarget, setScrollPosition } = scroll

// Mixins
import CommonMixin from '../mixins/common'
import { BsBtn, BsClickable } from '../index'
import { replaceUrlParam } from 'booksprout'

@Component
export default class BsPaginator extends CommonMixin {
  @Prop({ type: Number }) public readonly setCurrentPage!: number
  @Prop({ type: Number }) public readonly totalRows!: number
  @Prop({ type: Number }) public readonly rowsPerPage!: number
  @Prop({ type: Number, default: 8 }) public readonly showMaxPages!: number
  @Prop({ type: Boolean }) public readonly updateUrl!: boolean

  public currentPage = this.setCurrentPage || 1

  @Watch('setCurrentPage')
  onSetCurrentPageChanged (to: number) {
    this.currentPage = to
  }

  public setPage (pageNumber: number) {
    this.currentPage = pageNumber

    if (history.pushState && this.updateUrl) {
      history.pushState(null, '', replaceUrlParam(window.location.href, 'page', pageNumber.toString()))
    }

    this.$emit('changed', {
      pageNumber,
      skip: pageNumber > 1 ? pageNumber * this.rowsPerPage - this.rowsPerPage : 0,
      take: this.rowsPerPage
    })
  }

  public get totalPages () {
    return Math.ceil(this.totalRows / this.rowsPerPage)
  }

  reset () {
    // Not calling set page here as we don't want to emit a change event
    this.currentPage = 1
  }

  _scrollToTop () {
    const target = getScrollTarget(document.body)
    const offset = document.body.offsetTop
    const duration = 0
    setScrollPosition(target, offset, duration)
  }

  public __renderBtn (h: CreateElement, back: boolean, first = false) {
    const isDisabled = (back && this.currentPage === 1) || (!back && this.currentPage === this.totalPages)
    return h(BsBtn, {
      staticClass: 'bs-paginator__nav-btn q-pa-none flex items-center font-size-8 justify-center',
      class: {
        'q-mr-md': back,
        'q-ml-md': !back,
        'bs-paginator__nav-btn--disabled': isDisabled
      },
      props: {
        icon: back ? first ? 'app:double-arrow' : 'app:back' : 'app:forward',
        disable: isDisabled,
        secondary: true
      },
      attrs: {
        ['data-cy']: back ? first ? 'paginatorFirstPageBtn' : 'paginatorPreviousPageBtn' : 'paginatorNextPageBtn',
        ['aria-label']: back ? first ? this.$t('system.labels.firstPage') : this.$t('system.labels.previousPage') : this.$t('system.labels.nextPage')
      },
      on: {
        click: () => {
          this.setPage(back ? first ? 1 : this.currentPage - 1 : this.currentPage + 1)
          this._scrollToTop()
        }
      }
    }, this.slot(this, 'default'))
  }

  public __renderPageButtons (h: CreateElement) {
    const result = []
    // disregard the showMaxPages prop on mobile
    const maxPages = this.$q.screen.xs ? 5 : this.showMaxPages
    // Allow for 2 dots on mobile, 3 elsewhere
    const maxPageOffset = maxPages - (this.$q.screen.xs ? 2 : 3)

    let dotsAdded = 0
    // TODO: We might be able to optimise this a lot by not looping through totalRows. If we find we need to deal with larger datasets
    // we can deal with that then. We should be able to work out which numbers are at the start, how many dots are required, then the end number
    for (let i = 1; i <= this.totalPages; i++) {
      const isDot =
        this.totalPages > maxPages &&
        (i > this.currentPage + maxPages - maxPageOffset) &&
        i !== this.totalPages

      const canAddDots = dotsAdded < maxPages - maxPageOffset // Four current pages + last page

      const showPage =
        // Show the page we're on
        this.currentPage === i ||
        // Show the next X numbers from the current page up until dots start
        (this.currentPage <= i - 1 && this.currentPage >= i - (maxPages - 6)) ||
        // Always show the one behind
        this.currentPage === i + 1 ||
        // Always show the first X if we're on page 1
        (this.currentPage === 1 && i < maxPageOffset) ||
        // Always show the last page
        i === this.totalPages ||
        // Always show, if the following is true
        (
          i > this.totalPages - maxPages && // This rendered page number is in range of the end AND
          this.currentPage > this.totalPages - maxPages + 1 && // The currently selected page is in range of the end AND
          !isDot // it's not a dot
        )

      if (isDot && canAddDots) {
        dotsAdded++
        result.push(
          h('div', {
            staticClass: 'bs-paginator__page-btn q-px-sm text-bs-grey-m',
          }, '.')
        )
      }

      if (showPage || (!canAddDots && i === this.totalPages)) {
        result.push(
          h(BsClickable, {
            staticClass: 'bs-paginator__page-btn text-bs-grey-m',
            class: {
              'text-bold': this.currentPage === i
            },
            on: {
              click: () => {
                this.setPage(i)
                this._scrollToTop()
              }
            }
          }, [
            i.toString()
          ])
        )
      }
    }
    return result
  }

  public __renderPageBtnContainer (h: CreateElement) {
    return h('div', {
      staticClass: 'bs-paginator__page-btn-container flex'
    }, this.__renderPageButtons(h))
  }

  public render (h: CreateElement) {
    return h('div', {
      staticClass: 'bs-paginator flex items-center',
      class: this.getClasses(),
      on: this.getListeners()
    }, [
      this.__renderBtn(h, true, true),
      this.__renderBtn(h, true),
      this.__renderPageBtnContainer(h),
      this.__renderBtn(h, false)
    ])
  }
}
