export const AUTH_LOAD = 'AUTH_LOAD'
export const AUTH_REQUEST = 'AUTH_REQUEST'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_ERROR = 'AUTH_ERROR'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_REQUIRED = 'AUTH_REQUIRED'
export const CLEAR_AUTH_REQUIRED = 'CLEAR_AUTH_REQUIRED'
export const AUTH_LOGOUT_REQUEST = 'AUTH_LOGOUT_REQUEST'
export const AUTH_INVALID = 'AUTH_INVALID'
export const AUTH_EXPIRED = 'AUTH_EXPIRED'
export const SET_MIMIC_USER = 'SET_MIMIC_USER'
export const AUTH_CONSENT_FAILED = 'AUTH_CONSENT_FAILED'
export const AUTH_CONSENT_NEEDED = 'AUTH_CONSENT_NEEDED'
export const SET_USER = 'SET_USER'
export const GET_CSRF_TOKEN = 'GET_CSRF_TOKEN'
export const SET_CSRF_TOKEN = 'SET_CSRF_TOKEN'
