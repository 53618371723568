import VueRouter from 'vue-router'
import { UnreadCountDto, UserStatsDto } from 'booksprout'

export enum UINotificationType {
  Error = 0,
  Standard = 1
}

export interface UINotification {
  type: UINotificationType,
  message: string
}

export interface StoreRedirectData {
  url?: string
  name?: string
  data?: any
}

export interface LoginRedirectData {
  show: boolean
  forwardUrl: string
}

export interface ShowMessageDialogData {
  show: boolean
  error: boolean
  message: string
  optionalParams?: []
}

export interface AuthRequiredData {
  forwardUrl: string
  router?: VueRouter
}

export interface UIState {
  notifications: UINotification[]
  unreadMessageCount: number
  unreadNotificationCount: UnreadCountDto
  redirect: StoreRedirectData | undefined,
  triggerLoginNotification: boolean,
  loginForwardUrl: string
  showMessageDialog: ShowMessageDialogData
  userStats: UserStatsDto
  isReadyToRender: boolean
  lastError: string
}
