import { gql } from '@apollo/client/core'

import { BaseCrudService } from 'booksprout-app'
import { MessagePostServiceInterface } from './messagePost.service.interface'
import { CreateMessagePostDto, MessagePostDto, MessagePostGetArgs, UpdateMessagePostDto } from 'booksprout'

export class MessagePostService extends BaseCrudService<MessagePostDto> implements MessagePostServiceInterface<MessagePostDto> {
  public apiControllerPath = ''

  private readonly MessagePostFragments = {
    MessagePost: gql`
      fragment MessagePostFragment on MessagePostDto {
        id,
        createdDate,
        userId,
        state,
        message,
        sender {
          modelKey,
          id,
          name,
          image
        }
      }
    `
  }

  public get (params?: MessagePostGetArgs): Promise<MessagePostDto[]> {
    return this.apolloClientService.query({
      variables: {
        ...params
      },
      query: gql`
        query GetMessagePosts (
          $threadId: Int!
          $state: Int
          $forUserId: Int
        ) {
          messagePosts (
            threadId: $threadId,
            state: $state,
            forUserId: $forUserId
          ) {
            ...MessagePostFragment
          }
        }
        ${this.MessagePostFragments.MessagePost}
      `
    })
  }

  public getById (id?: number): Promise<MessagePostDto> {
    return this.apolloClientService.query({
      variables: {
        id
      },
      query: gql`
        query GetMessagePost (
          $id: Int!
        ) {
          messagePost (id: $id) {
            ...MessagePostFragment
          }
        }
        ${this.MessagePostFragments.MessagePost}
      `
    })
  }

  public create (model: CreateMessagePostDto): Promise<MessagePostDto> {
    return this.apolloClientService.mutate({
      variables: {
        ...model
      },
      mutation: gql`
        mutation CreateMessagePost (
          $message: String!
          $threadId: Int!
          $penNameId: Int
          $arcTeamId: Int
        ) {
          createMessagePost (
            threadId: $threadId,
            message: $message
            penNameId: $penNameId,
            arcTeamId: $arcTeamId
          ) {
            ...MessagePostFragment
          }
        }
        ${this.MessagePostFragments.MessagePost}
      `
    })
  }

  public update (model: UpdateMessagePostDto): Promise<MessagePostDto> {
    return this.apolloClientService.mutate({
      variables: {
        ...model
      },
      mutation: gql`
        mutation UpdateMessagePost (
          $id: Int!
          $state: Int!
        ) {
          updateMessagePost (
            id: $id,
            state: $state
          ) {
            ...MessagePostFragment
          }
        }
        ${this.MessagePostFragments.MessagePost}
      `
    })
  }

  public delete (id?: number): Promise<any> {
    return this.apolloClientService.mutate({
      variables: {
        id
      },
      mutation: gql`
        mutation DeleteMessagePost (
          $id: Int!
        ) {
          deleteMessagePost(id: $id) {
            id
          }
        }
      `
    })
  }

  public markAllAsRead (threadId: number): Promise<number> {
    return this.apolloClientService.mutate({
      variables: {
        id: threadId
      },
      mutation: gql`
        mutation MarkAllPostsAsRead (
          $id: Int!
        ) {
          markAllPostsAsRead(id: $id)
        }
      `
    })
  }

  public flag (postId: number): Promise<MessagePostDto> {
    return this.apolloClientService.mutate({
      variables: {
        id: postId
      },
      mutation: gql`
        mutation FlagPost (
          $id: Int!
        ) {
          flagPost(id: $id) {
            ...MessagePostFragment
          }
        }
        ${this.MessagePostFragments.MessagePost}
      `
    })
  }
}
