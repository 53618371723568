import { DATE_FORMAT as BASE_DATE_FORMAT} from 'booksprout-app/src/constants'

export const DATE_FORMAT = BASE_DATE_FORMAT

export const requireConfigFile = () => {
  let configBuild = 'dev'
  let configMode: string | undefined
  switch (process.env.ENVIRONMENT) {
    case 'build':
      configBuild = 'build'
      configMode = process.env.BUILD_MODE
      break
    case 'testing':
      configBuild = 'testing'
      break
  }

  return require(`../config.${configBuild}${configMode ? '.' + configMode : ''}.js`).default
}
