import { BaseModel } from '../../base.model'
import { Component, Prop } from 'vue-property-decorator'
import { CommonBaseModuleMixin } from './baseModule'
import { validInt } from 'booksprout'

// @ts-ignore
@Component
export abstract class CommonViewModuleMixin<TModel extends BaseModel> extends CommonBaseModuleMixin<TModel> {
  @Prop({ type: Object }) public readonly assignModel!: TModel

  // These are public so tests can access them.
  public abstract crudModel: TModel

  public get getByIdArgs (): any {
    return void 0
  }

  /**
   * A function which is called when an existing document is loaded from the DB
   * allowing us to assign props to internal vars etc.
   */
  public onLoadDoc (): void {
    return void 0
  }

  public loadDoc (docId?: number) {
    if (validInt(docId)) {
      this.crudService.getById(docId, this.getByIdArgs).then((doc: TModel | null) => {
        this.assignLoadedDoc(doc)
      }).catch(e => {
        this.showError(e.message)
      })
    }
  }

  public assignLoadedDoc (doc: TModel | null) {
    if (doc !== null) {
      this.crudModel = doc
      this.onLoadDoc()
    }
  }

  public getDocId () {
    return parseInt(this.$route.params.id)
  }

  public doOnCreate () {
    // If we're not trying assign an existing model, then load the one from the URL
    // Existing model would be sub component in main CrudModule i.e ReviewSites in Arcs/CreateEdit
    if (this.assignModel === void 0) {
      // Check to see if we have a doc id in the URL, if so, load the doc.
      const docId = this.getDocId()
      this.loadDoc(docId)
    } else { // Otherwise, just assign this model to the form for use.
      this.assignLoadedDoc(this.assignModel)
    }
  }

  reload () {
    this.loadDoc(this.assignModel?.id || this.getDocId())
  }


  /**
   * Override this for any form setup that needs doing i.e Loading lists for use in the form etc.
   * This is wrapped in mounted() around loading spinners so the process is nice.
   */
  public setupForm (): Promise<any> {
    return Promise.resolve()
  }

  async mounted () {
    this.$q.loading.show()
    try {
      await this.setupForm()
      this.$q.loading.hide()
    } catch (e) {
      this.showError((e as any).message)
      this.$q.loading.hide()
    }
  }

  public created () {
    this.doOnCreate()
  }
}
