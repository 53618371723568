import { SystemState } from './types'

export default (): SystemState => ({
  returnCrudModel: void 0,
  returnLocation: '',
  returnLabel: '',
  completeLabel: '',
  meta: {
    title: '',
    description: '',
    keywords: '',
    ogTitle: '',
    ogImage: '',
    ogUrl: ''
  }
})
