import { CreateElement } from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { QSelect, QItem, QItemSection, QItemLabel } from 'quasar'
// @ts-ignore
import ClickEvent = JQuery.ClickEvent
// Utils
import { slot } from '../utils'

// Mixins
import { BaseBsComponentMixin } from '../mixins/common'

@Component
export default class BsSelectBtn extends BaseBsComponentMixin {
  @Prop({ type: Boolean }) public readonly dense!: boolean

  protected autoSaveMethod = 'input'

  // Override this so we can allow for negative number options to be something like "Please select..."
  public isComponentIsValid (val?: any) {
    return val !== null && val !== '' && val !== void 0 && val > -1
  }

  public get backgroundColor () {
    if (this.hasErrors) {
      return 'imp-bs-r-l'
    }

    return this.$attrs['bg-color'] || 'white'
  }

  public __renderOptions (props: Record<string, any>, h: CreateElement) {
    // We don't want to show the default option as a selectable option as these are essentially buttons.
    if (props.opt.value === -1) return

    return h(QItem, {
      staticClass: 'bs-select-btn-option',
      props: {
        ...props.props,
        clickable: true
      },
      on: props.itemEvents
    }, [
      h(QItemSection, {
        on: {
          click: (e: ClickEvent) => {
            if (typeof props.opt.onClick === 'function') {
              props.opt.onClick(props.opt.value)
            }
            this.$emit('clickItem', props.opt.value)
            // Stop the default QSelect clicks
            e.stopPropagation()
            e.preventDefault()
          }
        }
      }, [
        h(QItemLabel, [
          props.opt.label
        ])
      ])
    ])
  }

  public __renderSelectedItem (props: Record<string, any>, h: CreateElement) {
    return h(QItem, {
      staticClass: 'bs-selected-option',
      props: {
        ...props.props,
        clickable: false, // Don't use this - we'll use this .sass style instead
        dense: true
      },
      on: props.itemEvents
    }, [
      h(QItemSection, {
        on: {
          click: (e: ClickEvent) => {
            if (typeof props.opt.onClick === 'function') {
              props.opt.onClick(props.opt.value)
            }
            this.$emit('clickBtn', props.opt.value)
            // Stop the default QSelect clicks
            e.stopPropagation()
            e.preventDefault()
          }
        }
      }, [
        h(QItemLabel, [
          props.opt.label
        ])
      ])
    ])
  }

  public render (h: CreateElement) {
    const props = {
      mapOptions: this.$attrs['map-options'] !== void 0 ? this.$attrs['map-options'] : true,
      emitValue: this.$attrs['emit-value'] !== void 0 ? this.$attrs['emit-value'] : true,
      dropdownIcon: 'app:arrow-down',
      popupContentClass: 'bs-select-btn-options',
      stackLabel: true,
      borderless: true,
      bottomSlots: false,
      noErrorIcon: true,
      filled: !this.hasErrors,
      outlined: this.hasErrors,
      bgColor: this.backgroundColor,
    }

    let scopedSlots: object = {
      ['selected-item']: (props: Record<string, any>) => this.__renderSelectedItem(props, h)
    }
    // Only if we're not trying to override from outside the component
    if (this.$scopedSlots.option === void 0) {
      scopedSlots = {
        ...scopedSlots,
        option: (props: Record<string, any>) => this.__renderOptions(props, h)
      }
    }

    return h(QSelect, {
      ref: 'bsSelectBtn',
      staticClass: 'bs-select-btn',
      class: this.getClasses({
        'bs-select-btn--dense': this.dense
      }),
      props: this.getRenderProps(props),
      on: this.getListeners({
        blur: () => this.validate(this.$attrs.value)
      }),
      attrs: this.getAttributes(),
      scopedSlots: this.getScopedSlots(h, scopedSlots)
    }, [
      slot(this, 'default')
    ])
  }
}
