// Utils
import { Component, Prop } from 'vue-property-decorator'
import { CreateElement } from 'vue/types/vue'

// Mixins
import CommonMixin from '../mixins/common'

@Component
export default class BsSnippet extends CommonMixin {
  @Prop({ type: Number }) public readonly limit!: number
  @Prop({ type: String }) public readonly text!: string
  @Prop({ type: Boolean }) public readonly html!: boolean

  public showing: boolean = false

  public __renderContent (h: CreateElement) {
    if (!this.text) {
      return h('span', '')
    }

    const text = this.text.substring(0, this.showing ? Number.MAX_SAFE_INTEGER : this.limit)
    const nodes = [h('span', {
      domProps: {
        innerHTML: this.html ? text : void 0
      }
    }, [
      this.html ? void 0 : text
    ])]

    if (this.text.length > this.limit) {
      nodes.push(h('span', {
        staticClass: 'text-secondary text-bold text-underline cursor-pointer on-right-xs',
        on: {
          click: () => {
            this.showing = !this.showing
          }
        }
      }, [this.showing === false ? '(...)' : '(^--)']))
    }

    return nodes
  }

  public render (h: CreateElement) {
    return h('div', {
      staticClass: 'bs-text-caption',
      class: this.getClasses(),
      on: this.getListeners()
    }, [
      this.__renderContent(h)
    ])
  }
}
