import { DirectiveFunction, DirectiveOptions } from 'vue'
import { AuthenticatedUser } from '../../modules/_base/auth/auth.service.interface'
import { Store } from 'vuex'
import { BaseRootState } from '../../store/baseRootState'

export const getDirective = (store: Store<BaseRootState>, roleType: number): DirectiveOptions | DirectiveFunction => {
  const directive: DirectiveOptions | DirectiveFunction = {
    // Run this directive when the element is inserted into the parent element in the DOM.
    // Failure to do this would mean the element would show until next render update.
    inserted: (el, binding, vnode) => {
      if (binding.value === '') return

      const
        authenticatedUser = store.getters.authenticatedUser as AuthenticatedUser,
        mimicUser = store.getters.mimicUser as AuthenticatedUser,
        userToCheck = mimicUser || authenticatedUser,
        // If we're checking a perm or a role
        userHasPermission = binding.arg !== void 0 && userToCheck.permissionQuery.can(binding.arg, binding.value, roleType)

      if (!userHasPermission) {
        // Doesn't have permission to access this item so remove the element from the dom
        if (vnode && vnode.elm && vnode.elm.parentElement) {
          vnode.elm.parentElement.removeChild(vnode.elm)
        }
      }
    }
  }

  return directive
}
