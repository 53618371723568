import { VueConstructor } from 'vue'

import BsBtn from './btn/BsBtn'
import BsToggle from './toggle/BsToggle'
import BsForm from './form/BsForm'
import BsTabStepper from './tabStepper/BsTabStepper'
import BsTabStepperTitle from './tabStepper/BsTabStepperTitle'
import BsInput from './input/BsInput'
import BsCheckbox from './checkbox/BsCheckbox'
import BsRadio from './radio/BsRadio'
import BsClickable from './clickable/BsClickable'
import BsTooltip from './tooltip/BsTooltip'
import BsChipBox from './chipBox/BsChipBox'
import BsDate from './date/BsDate'
import BsTabbedLayout from './tabbedLayout/BsTabbedLayout'
import BsFormField from './formField/BsFormField'
import BsUpgrade from './upgrade/BsUpgrade'
import BsSelect from './select/BsSelect'
import BsUpload from './upload/BsUpload'
import BsChip from './chip/BsChip'
import BsEditor from './editor/BsEditor'
import BsStatus from './status/BsStatus'
import BsModal from './modal/BsModal'
import BsSnippet from './snippet/BsSnippet'
import BsBtnDropdown from './btn/BsBtnDropdown'
import BsSiteBtn from './site-btn/BsSiteBtn'
import BsCheckToggle from './checkToggle/BsCheckToggle'
import BsRating from './rating/BsRating'
import BsShareBtn from './share-btn/BsShareBtn'
import BsPaginator from './paginator/BsPaginator'
import BsSelectBtn from './selectBtn/BsSelectBtn'
import BsImg from './img/BsImg'
import BsTag from './tag/BsTag'
import BsSpinner from './spinner/BsSpinner'
import BsFilterBtn from './btn/BsFilterBtn'

export {
  BsBtn,
  BsToggle,
  BsForm,
  BsInput,
  BsCheckbox,
  BsRadio,
  BsClickable,
  BsTooltip,
  BsChipBox,
  BsDate,
  BsTabStepper,
  BsTabStepperTitle,
  BsTabbedLayout,
  BsFormField,
  BsUpgrade,
  BsSelect,
  BsUpload,
  BsChip,
  BsEditor,
  BsStatus,
  BsModal,
  BsSnippet,
  BsBtnDropdown,
  BsSiteBtn,
  BsCheckToggle,
  BsRating,
  BsShareBtn,
  BsPaginator,
  BsSelectBtn,
  BsImg,
  BsTag,
  BsSpinner,
  BsFilterBtn
}

export default {
  BsBtn,
  BsToggle,
  BsForm,
  BsTabStepper,
  BsTabStepperTitle,
  BsInput,
  BsCheckbox,
  BsRadio,
  BsClickable,
  BsTooltip,
  BsChipBox,
  BsDate,
  BsTabbedLayout,
  BsFormField,
  BsUpgrade,
  BsSelect,
  BsUpload,
  BsChip,
  BsEditor,
  BsStatus,
  BsModal,
  BsSnippet,
  BsBtnDropdown,
  BsSiteBtn,
  BsCheckToggle,
  BsRating,
  BsShareBtn,
  BsPaginator,
  BsSelectBtn,
  BsImg,
  BsTag,
  BsSpinner,
  BsFilterBtn,

  install (Vue: VueConstructor) {
    Vue.component('BsBtn', BsBtn)
    Vue.component('BsToggle', BsToggle)
    Vue.component('BsForm', BsForm)
    Vue.component('BsTabStepper', BsTabStepper)
    Vue.component('BsTabStepperTitle', BsTabStepperTitle)
    Vue.component('BsInput', BsInput)
    Vue.component('BsCheckbox', BsCheckbox)
    Vue.component('BsRadio', BsRadio)
    Vue.component('BsClickable', BsClickable)
    Vue.component('BsTooltip', BsTooltip)
    Vue.component('BsChipBox', BsChipBox)
    Vue.component('BsDate', BsDate)
    Vue.component('BsTabbedLayout', BsTabbedLayout)
    Vue.component('BsFormField', BsFormField)
    Vue.component('BsUpgrade', BsUpgrade)
    Vue.component('BsSelect', BsSelect)
    Vue.component('BsUpload', BsUpload)
    Vue.component('BsChip', BsChip)
    Vue.component('BsEditor', BsEditor)
    Vue.component('BsStatus', BsStatus)
    Vue.component('BsModal', BsModal)
    Vue.component('BsSnippet', BsSnippet)
    Vue.component('BsBtnDropdown', BsBtnDropdown)
    Vue.component('BsSiteBtn', BsSiteBtn)
    Vue.component('BsCheckToggle', BsCheckToggle)
    Vue.component('BsRating', BsRating)
    Vue.component('BsShareBtn', BsShareBtn)
    Vue.component('BsPaginator', BsPaginator)
    Vue.component('BsSelectBtn', BsSelectBtn)
    Vue.component('BsImg', BsImg)
    Vue.component('BsTag', BsTag)
    Vue.component('BsSpinner', BsSpinner)
    Vue.component('BsFilterBtn', BsFilterBtn)
  }
}
