// Utils
import { Component, Prop } from 'vue-property-decorator'
import { CreateElement } from 'vue/types/vue'

// Mixins
import CommonMixin from '../mixins/common'

@Component
export default class BsClickable extends CommonMixin {
  @Prop({ type: String }) readonly to!: string
  @Prop({ type: Boolean }) readonly preventNavigation!: boolean

  public render (h: CreateElement) {
    return h(this.to ? 'router-link' : 'div', {
      staticClass: 'bs-clickable',
      class: this.getClasses({
        'bs-clickable__navigation-prevented': this.preventNavigation
      }),
      on: this.getListeners(),
      attrs: {
        to: this.to || void 0,
        role: this.to ? 'link' : 'button',
        tabindex: 0
      }
    }, this.slot(this, 'default'))
  }
}
