import { CreateElement } from 'vue'
import { Component } from 'vue-property-decorator'

// Mixins
import { event } from 'quasar'
import stopAndPrevent = event.stopAndPrevent
import ValidationMixin from '../mixins/validation'

@Component
export default class BsForm extends ValidationMixin {
  public validateForm (): Promise<boolean> {
    const valid = this.validateComponentTree(this.$children)
    return Promise.resolve(valid)
  }

  public resetComponentTree (components: any) {
    for (const component of components) {
      if (component.$children.length > 0) {
        this.resetComponentTree(component.$children)
      }

      if (component.reset === void 0) {
        continue // No reset function; skip.
      }

      component.reset()
    }
  }

  public reset () {
    this.resetComponentTree(this.$children)
  }

  public render (h: CreateElement) {
    return h('form', {
      staticClass: 'bs-form',
      class: this.getClasses(),
      props: this.getRenderProps(),
      on: this.getListeners({
        submit: (evt: any) => {
          evt !== void 0 && stopAndPrevent(evt)

          this.validateForm().then(valid => {
            if (valid === true) {
              if (this.$listeners.submit !== void 0) {
                this.$emit('submit', evt)
              }
              else if (evt !== void 0 && evt.target !== void 0 && typeof evt.target.submit === 'function') {
                evt.target.submit()
              }
            }
          })
        }
      })
    }, this.slot(this, 'default'))
  }
}
