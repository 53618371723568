import { gql } from '@apollo/client/core'

import { BaseCrudService } from 'booksprout-app'
import { NotificationServiceInterface } from './notification.service.interface'
import {
  CreateNotificationDto,
  NotificationGetArgs,
  NotificationUnsubArgs, UnreadCountDto,
  UpdateNotificationDto,
  UserNotificationDto,
  UserNotificationPagedResultDto
} from 'booksprout'

export class NotificationService extends BaseCrudService<UserNotificationDto> implements NotificationServiceInterface<UserNotificationDto> {
  public apiControllerPath = 'notification'

  private readonly NotificationFragments = {
    notification: gql`
      fragment NotificationFragment on UserNotificationDto {
        id,
        status,
        statusDate,
        state,
        nameOfInstigator,
        parsedMessage,
        parsedDescription,
        to,
        notification {
          id,
          code
        },
        arc {
          id,
          claims {
            id
          }
        },
        book {
          id
          title,
          bookCover
        },
        arcClaim {
          id,
          arc {
            id
          }
        }
        arcReviewSite {
          id,
          site,
          review {
            claimId
          }
        },
        arcReview {
          id,
          rating,
          claim {
            id,
            arc {
              id
            }
          }
        },
        arcTeam {
          id,
          name
        },
        arcTeamMember {
          id,
          arcTeam {
            id,
            name
          }
        }
      }
    `
  }

  public get (args: NotificationGetArgs): Promise<UserNotificationPagedResultDto> {
    return this.apolloClientService.query({
      variables: {
        ...args
      },
      query: gql`
        query GetNotifications (
          $notificationCodes: [String!],
          $unread: Boolean,
          $genericSearchTerm: String,
          $skip: Int,
          $take: Int
        ) {
          notifications (
            notificationCodes: $notificationCodes,
            unread: $unread,
            genericSearchTerm: $genericSearchTerm,
            skip: $skip,
            take: $take
          ) {
            totalRows,
            unreadNotificationCount,
            items {
              ...NotificationFragment
            }
          }
        }
        ${this.NotificationFragments.notification}
      `
    })
  }

  public getById (id?: number): Promise<UserNotificationDto> {
    return this.apolloClientService.query({
      variables: {
        id
      },
      query: gql`
        query GetNotification (
          $id: Int!
        ) {
          notification (id: $id) {
            ...NotificationFragment
          }
        }
        ${this.NotificationFragments.notification}
      `
    })
  }

  public markAsRead (notificationQueueItemId: number): Promise<number> {
    return this.apolloClientService.mutate({
      variables: {
        id: notificationQueueItemId
      },
      mutation: gql`
        mutation MarkNotificationQueueItemAsRead (
          $id: Int!
        ) {
          markNotificationAsRead(id: $id)
        }
      `
    })
  }

  public unsubscribe (args: NotificationUnsubArgs): Promise<number> {
    return this.apolloClientService.mutate({
      variables: {
        ...args
      },
      mutation: gql`
        mutation Unsubscribe (
          $unsubId: String!,
          $key: String!
        ) {
          unsubscribe (unsubId: $unsubId, key: $key)
        }
      `
    })
  }

  public create (model: CreateNotificationDto): Promise<UserNotificationDto> {
    throw new Error('Not implemented')
  }

  public update (model: UpdateNotificationDto): Promise<UserNotificationDto> {
    throw new Error('Not implemented')
  }

  public delete (id?: number): Promise<any> {
    throw new Error('Not implemented')
  }

  deleteNotificationQueueItem (notificationQueueItemId: number): Promise<any> {
    return this.apolloClientService.mutate({
      variables: {
        id: notificationQueueItemId
      },
      mutation: gql`
        mutation DeleteNotificationQueueItem (
          $id: Int!
        ) {
          deleteNotificationQueueItem (id: $id)
        }
      `
    })
  }

  getUnreadNotificationCount (args?: NotificationGetArgs): Promise<UnreadCountDto> {
    return this.apolloClientService.query({
      variables: {
        ...args
      },
      query: gql`
        query GetUnreadNotificationCount (
          $notificationCodes: [String!]
        ) {
          unreadNotificationCount (
            notificationCodes: $notificationCodes
          ) {
            author,
            reviewer
          }
        }
      `
    })
  }
}
