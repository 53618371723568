// Utils
import { Component, Prop } from 'vue-property-decorator'
import { CreateElement } from 'vue/types/vue'

// Mixins
import CommonMixin from '../mixins/common'
import { QIcon } from 'quasar'

@Component
export default class BsStatus extends CommonMixin {
  @Prop({ type: String }) public readonly borderColor!: string
  @Prop({ type: Boolean }) public readonly round!: boolean

  @Prop({ type: Boolean }) public readonly active!: boolean
  @Prop({ type: Boolean }) public readonly draft!: boolean
  @Prop({ type: Boolean }) public readonly finished!: boolean
  @Prop({ type: Boolean }) public readonly scheduled!: boolean

  public get status () {
    return this.active ? 'Active'
      : this.draft ? 'Draft'
        : this.finished ? 'Finished'
          : this.scheduled ? 'Scheduled'
            : this.round ? void 0
              : 'Unknown'
  }

  public get icon () {
    return this.active ? 'nonexistant'
      : this.draft ? 'nonexistant'
        : this.finished ? 'icon-check'
          : this.scheduled ? 'icon-clock'
            : this.round ? void 0
              : 'Unknown'
  }

  public render (h: CreateElement) {
    return h('div', {
      staticClass: 'bs-status',
      class: this.getClasses({
        'bs-status--round': this.round,
        'bs-status--active': this.active,
        'bs-status--draft': this.draft,
        'bs-status--finished': this.finished,
        'bs-status--scheduled': this.scheduled
      }),
      style: this.borderColor === void 0 ? void 0 : 'border-color: ' + this.borderColor,
      on: this.getListeners(),
    }, [
      h(QIcon, {
        props: {
          name: this.icon
        }
      }),
      h('span', [
        this.status,
        this.slot(this, 'default')
      ]),
    ])
  }
}
