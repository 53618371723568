import { inject } from 'inversify-props'
import axiosInstance, { AxiosInstance } from 'axios'

import { AxiosServiceInterface } from './axios.service.interface'
import { ConfigService } from '../config.service'
import { ApiErrorHandler } from '../apiErrorHandler'
import VueI18n from 'vue-i18n'
import { StoreServiceInterface } from '../store.service.interface'
import { BaseRootState } from '../../../store/baseRootState'
import { Loading } from 'quasar'
import { BsSpinner } from '../../../components/bs-component-bundle'
import { IsReviewerApp } from '../../../utils'

export class AxiosService extends ApiErrorHandler implements AxiosServiceInterface {
  private showGlobalLoader = true

  public constructor (
    @inject('StoreService') public readonly storeService: StoreServiceInterface<BaseRootState>,
    @inject('ConfigService') public readonly configService: ConfigService,
    @inject('TranslationService') translationService: VueI18n
  ) {
    super(translationService)
    this.setupAxiosInstance()
  }

  get axios () : AxiosInstance {
    // console.log('Axios, csrf:', this.storeService.store.getters.csrfToken)

    const axios = axiosInstance.create({
      baseURL: this.configService.config.axios.url,
      timeout: 60000,
      withCredentials: true
    })

    // Set this var loading won't trigger after the return call if its faster than 200ms
    let shouldShowLoading = this.showGlobalLoader
    axios.interceptors.request.use(config => {
      setTimeout(() => {
        if (shouldShowLoading) {
          Loading.setDefaults({
            // @ts-ignore
            spinner: BsSpinner
          })
          Loading.show()
        }
      }, 200)

      // @ts-ignore
      config.headers = {
        ...config.headers,
        'Authorization': 'bearer ' + this.storeService.store.getters.authToken,
        'CSRF-Token': this.storeService.store.getters.csrfToken,
        'Referring-App': IsReviewerApp() ? 'reviewer' : 'publisher'
      }

      return config
    }, error => {
      shouldShowLoading = false
      Loading.hide()
      return this.handleErrorResponse(error)
    })

    axios.interceptors.response.use(response => {
      shouldShowLoading = false
      Loading.hide()

      // This will only trigger for mobile as nginx will have prevented us getting this far in a browser.
      if (!process.env.SERVER && response.data.offline) {
        document.location.href = '/#/maintenance'
      }

      return response
    }, error => {
      shouldShowLoading = false
      Loading.hide()
      return this.handleErrorResponse(error)
    })

    return axios
  }

  public setupAxiosInstance() {
    // Deprecated
  }

  public globalLoader (enable: boolean): this {
    this.showGlobalLoader = enable
    return this
  }

  redirectIfOffline (router?: any): Promise<boolean> {
    return this.axios.post('auth/live', {}, {
      timeout: 5000
    }).then(async r => {
      if (r.data.offline) {
        if (router) {
          return router.push('maintenance')
        } else {
          return true
        }
      }

      return false
    })
  }

  private handleErrorResponse (r: any): Promise<any> {
    // This is an error handler so we'll always send a promise rejection at this point so the promise chain
    // works properly.
    return new Promise((__, reject) => {
      if (this.isAuthError(r)) {
        return reject(this.getErrorResponseFromCode('invalidLogin'))
      } else if (this.isBadRequest(r)) {
        if (typeof r.response.data.message === 'string') {
          return reject({
            message: r.response.data.message
          })
        } else {
          try {
            const error = r.response.data.message[0]

            if (error !== void 0 && error.constraints !== void 0) {
              return reject(this.getConstraintErrorResponse(error.constraints))
            } else if (error !== void 0) {
              return reject(this.getErrorResponse(error.message))
            } else {
              return reject(this.getErrorResponseFromCode(r.response.data.message.errorCode))
            }
          } catch {
            return reject(this.getErrorResponseFromCode('somethingWentWrong'))
          }
        }
      } else {
        return reject(r)
      }
    })
  }
}
