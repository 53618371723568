import { gql } from '@apollo/client/core'

import { AxiosService, BaseCrudService, ConfigService, inject, StoreService } from 'booksprout-app'
import { MessageThreadServiceInterface } from './messageThread.service.interface'
import {
  BaseThreadUserDto,
  bsConstants,
  CreateMessageThreadDto,
  MessageThreadDto,
  MessageThreadGetArgs, MessageThreadPagedResultDto,
  UpdateMessageThreadDto
} from 'booksprout'

export class MessageThreadService extends BaseCrudService<MessageThreadDto> implements MessageThreadServiceInterface<MessageThreadDto> {
  public apiControllerPath = ''

  public constructor (
    @inject('ConfigService') configService: ConfigService,
    @inject('StoreService') storeService: StoreService,
    @inject('AxiosService') axiosService: AxiosService
  ) {
    super(configService, storeService, axiosService)
  }

  private readonly messageThreadFragments = {
    MessageThread: gql`
      fragment MessageThreadFragment on MessageThreadDto {
        id,
        reviewerUserId,
        reviewerUser {
          id,
          name,
          image,
          existingThreadCount
        },
        authorUser {
          id,
          name,
          image,
          existingThreadCount,
          canAcceptMessages,
          canSendMessages
        }
        arc {
          id,
          book {
            id,
            bookCover,
            title,
            seriesName,
            seriesNumber,
            authorPenName {
              id,
              userId,
              name,
              image
            }
          },
          arcTeam {
            id,
            name,
            image
          }
        },
        createdDate,
        userId,
        state,
        lastMessageDate,
        lastMessageText,
        messageCount,
        unreadMessageCount,
        blocked
      }
    `
  }

  public get (params?: MessageThreadGetArgs): Promise<MessageThreadPagedResultDto> {
    return this.apolloClientService.query({
      variables: {
        ...params
      },
      query: gql`
        query GetMessageThreads (
          $state: Int
          $needle: String
          $reviewUserId: Int,
          $sortBy: String,
          $descending: Boolean,
          $skip: Int,
          $take: Int
        ) {
          messageThreads (
            state: $state,
            needle: $needle,
            reviewUserId: $reviewUserId,
            sortBy: $sortBy,
            descending: $descending
            skip: $skip,
            take: $take
          ) {
            totalRows,
            items {
              ...MessageThreadFragment
            }
          }
        }
        ${this.messageThreadFragments.MessageThread}
      `
    })
  }

  public getById (id?: number): Promise<MessageThreadDto> {
    return this.getByIdForUser(id)
  }

  public getByIdForUser (id?: number, forUserId?: number): Promise<MessageThreadDto> {
    return this.apolloClientService.query({
      variables: {
        id,
        forUserId
      },
      query: gql`
        query GetMessageThread (
          $id: Int!
          $forUserId: Int
        ) {
          messageThread (id: $id, forUserId: $forUserId) {
            ...MessageThreadFragment,
            arc {
              id,
              userId,
              claims {
                review {
                  id,
                  rating,
                  sites {
                    site,
                    status,
                    link
                  }
                }
              }
            }
          }
        }
        ${this.messageThreadFragments.MessageThread}
      `
    })
  }

  public create (model: CreateMessageThreadDto): Promise<MessageThreadDto> {
    return this.apolloClientService.mutate({
      variables: {
        ...model,
        recipientUserId: model.reviewUserId
      },
      mutation: gql`
        mutation CreateMessageThread (
          $arcId: Int!
          $recipientUserId: Int!
        ) {
          createMessageThread (
            arcId: $arcId,
            reviewUserId: $recipientUserId
          ) {
            ...MessageThreadFragment
          }
        }
        ${this.messageThreadFragments.MessageThread}
      `
    })
  }

  public update (model: UpdateMessageThreadDto): Promise<MessageThreadDto> {
    return this.apolloClientService.mutate({
      variables: {
        ...model
      },
      mutation: gql`
        mutation UpdateMessageThread (
          $id: Int!
          $state: Int!
        ) {
          updateMessageThread (
            id: $id,
            state: $state
          ) {
            ...MessageThreadFragment
          }
        }
        ${this.messageThreadFragments.MessageThread}
      `
    })
  }

  public delete (id?: number): Promise<any> {
    return this.apolloClientService.mutate({
      variables: {
        id
      },
      mutation: gql`
        mutation DeleteMessageThread (
          $id: Int!
        ) {
          deleteMessageThread(id: $id) {
            id
          }
        }
      `
    })
  }

  public markAsRead (threadId: number): Promise<MessageThreadDto> {
    return this.update({
      id: threadId,
      state: bsConstants.MESSAGES.STATE.READ
    })
  }

  public markAsUnread (threadId: number): Promise<MessageThreadDto> {
    return this.update({
      id: threadId,
      state: bsConstants.MESSAGES.STATE.NEW
    })
  }

  public getUnreadThreadCount (): Promise<number> {
    return this.apolloClientService.query({
      query: gql`
        query GetUnreadThreadCount {
          unreadThreadCount
        }
      `
    })
  }

  public blockUser (userId: number, reportUser: boolean): Promise<undefined> {
    return this.apolloClientService.mutate({
      variables: {
        userId,
        reportUser
      },
      mutation: gql`
        mutation BlockUser (
          $userId: Int!
          $reportUser: Boolean!
        ) {
          blockUser(userId: $userId, reportUser: $reportUser)
        }
      `
    })
  }

  public unblockUser (userId: number): Promise<undefined> {
    return this.apolloClientService.mutate({
      variables: {
        userId
      },
      mutation: gql`
        mutation UnblockUser (
          $userId: Int!
        ) {
          unblockUser(userId: $userId)
        }
      `
    })
  }

  public getOtherThreadParticipant (thread: MessageThreadDto, currentUserId: number): BaseThreadUserDto {
    return thread.reviewerUserId === currentUserId
      ? thread.authorUser
      : { image: '', name: '', ...thread.reviewerUser }
  }

  public getHelp (threadId: number): Promise<undefined> {
    return this.apolloClientService.mutate({
      variables: {
        threadId
      },
      mutation: gql`
        mutation GetThreadHelp (
          $threadId: Int!
        ) {
          getThreadHelp (threadId: $threadId) {
            id
          }
        }
      `
    })
  }
}
