import { Component } from 'vue-property-decorator'
import { CreateElement } from 'vue'

import { QField } from 'quasar'

import ValidationMixin from '../mixins/validation'

// @ts-ignore - abstract class
@Component
export default abstract class BsField extends ValidationMixin {
  protected abstract __getFieldClass (): any
  protected abstract __getControl (h: CreateElement): any

  public render (h: CreateElement) {
    const props = {
      borderless: true,
      // @ts-ignore - value should be defined on derived class TODO: Can we abstract?
      value: this.value,
      stackLabel: true,
      bottomSlots: true,
      hint: this.autoSaveMessage,
      hideBottomSpace: this.$attrs['hide-bottom-space'] !== void 0
    }

    return h(QField, {
      staticClass: 'bs-field ' + this.__getFieldClass(),
      class: this.getClasses(),
      props: this.getRenderProps(props),
      on: this.getListeners()
    }, [
      this.__getControl(h)
    ])
  }
}
