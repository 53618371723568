import { container } from 'inversify-props'

/**
 * NOTE: Needs to be in a default export for tests to pass.
 * @param type
 */
export default function (type: any): any {
  return function (target: any, targetKey: string, index?: number): any {
    Reflect.deleteProperty(target, targetKey)
    Reflect.defineProperty(target, targetKey, {
      get () {
        return container.get(type)
      },
      set (value) {
        return value
      }
    })
  }
}
