import { UIState, UINotification } from './types'
import { UserStatsDto } from 'booksprout'

const resetNotificationState = (): UINotification[] => {
  return []
}

export default (): UIState => ({
  notifications: resetNotificationState(),
  unreadMessageCount: 0,
  unreadNotificationCount: {
    author: 0,
    reviewer: 0
  },
  redirect: void 0,
  triggerLoginNotification: false,
  loginForwardUrl: '',
  showMessageDialog: {
    show: false,
    error: false,
    message: '',
    optionalParams: []
  },
  userStats: new UserStatsDto(),
  isReadyToRender: false,
  lastError: ''
})

export { resetNotificationState }
