import { Component } from 'vue-property-decorator'
import { CommonBaseListModuleMixin, BaseModel } from 'booksprout-app'
import InnerLoading from 'src/components/InnerLoading.vue'
import NoResults from 'booksprout-app/src/components/NoResults.vue'
import MobileSearch from 'booksprout-app/src/components/MobileSearch.vue'

// @ts-ignore
@Component({
  components: {
    InnerLoading,
    NoResults
  }
})
export abstract class BaseListModuleMixin<TModel extends BaseModel> extends CommonBaseListModuleMixin<TModel> {
  public searchValue = ''
  public mobileSearchKey = ''
  public selectedFilter = 0

  public mobileSearch () {
    this.$q.dialog({
      component: MobileSearch,
      parent: this,
      currentSearch: this.searchValue,
      searchKey: this.mobileSearchKey
    }).onOk((searchValue: string) => {
      this.searchValue = searchValue
      this.selectedFilter = 0
      this.load()
    })
  }

  public clearFilters () {
    this.searchValue = ''
    this.load()
  }
}
