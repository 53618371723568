import { Component } from 'vue-property-decorator'
import { CommonViewModuleMixin, BaseModel } from 'booksprout-app'
import NoResults from 'booksprout-app/src/components/NoResults.vue'

// @ts-ignore
@Component({
  components: {
    NoResults
  }
})
export abstract class ViewModuleMixin<TModel extends BaseModel> extends CommonViewModuleMixin<TModel> {
  get getByIdArgs () {
    return {
      genericSearch: true
    }
  }
}
