import { Component, Prop } from 'vue-property-decorator'
import { BaseMixin } from 'src/components/mixins/base'

@Component
export default class InnerLoading extends BaseMixin {
  @Prop({ type: Boolean }) public readonly showing!: boolean
  @Prop({ type: Number, default: 250 }) public readonly delayShowTimeout!: number

  public enoughTimeHasPassed = false

  public get isShowing () {
    return this.showing && this.enoughTimeHasPassed
  }

  public mounted () {
    setTimeout(() => {
      this.enoughTimeHasPassed = true
    }, this.delayShowTimeout)
  }
}
