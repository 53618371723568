import { gql } from '@apollo/client/core'

import { AxiosService, BaseCrudService, ConfigService, inject, StoreService } from 'booksprout-app'
import { CategoryServiceInterface } from './category.service.interface'
import { CategoryDto, CreateCategoryDto, UpdateCategoryDto } from 'booksprout'

export class CategoryService extends BaseCrudService<CategoryDto> implements CategoryServiceInterface<CategoryDto> {
  public apiControllerPath = 'category'

  public constructor (
    @inject('ConfigService') configService: ConfigService,
    @inject('StoreService') storeService: StoreService,
    @inject('AxiosService') axiosService: AxiosService
  ) {
    super(configService, storeService, axiosService)
  }

  private readonly CategoryFragments = {
    category: gql`
      fragment CategoryFragment on CategoryDto {
        id,
        name
      }
    `
  }

  public get (): Promise<CategoryDto[]> {
    return this.apolloClientService.query({
      query: gql`
        query GetCategories {
          categories {
            ...CategoryFragment
          }
        }
        ${this.CategoryFragments.category}
      `
    })
  }

  public getById (id?: number): Promise<CategoryDto> {
    return this.apolloClientService.query({
      variables: {
        id
      },
      query: gql`
        query GetCategory (
          $id: Int!
        ) {
          category (id: $id) {
            ...CategoryFragment
          }
        }
        ${this.CategoryFragments.category}
      `
    })
  }

  public create (model: CreateCategoryDto): Promise<CategoryDto> {
    return this.apolloClientService.mutate({
      variables: {
        name: model.name
      },
      mutation: gql`
        mutation CreateCategory (
          $name: String!
        ) {
          createCategory (name: $name) {
            ...CategoryFragment
          }
        }
        ${this.CategoryFragments.category}
      `
    })
  }

  public update (model: UpdateCategoryDto): Promise<CategoryDto> {
    console.log('Updating category: ', model)
    return this.apolloClientService.mutate({
      variables: {
        ...model
      },
      mutation: gql`
        mutation UpdateCategory (
          $id: Int!,
          $name: String
        ) {
          updateCategory (id: $id, name: $name) {
            ...CategoryFragment
          }
        }
        ${this.CategoryFragments.category}
      `
    })
  }

  public delete (id?: number): Promise<any> {
    return this.apolloClientService.mutate({
      variables: {
        id
      },
      mutation: gql`
        mutation DeleteCategory (
          $id: Int!
        ) {
          deleteCategory(id: $id) {
            id
          }
        }
      `
    })
  }
}
